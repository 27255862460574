import {Link, Text} from '@prescriberpoint/ui';
import {FC, useMemo} from 'react';
import {useToggle} from 'usehooks-ts';
import {MAX_LINES_CLAMPED} from '@/constants/dosages';
import {useCurrentSlug} from '@/hooks';
import {useLabel} from '@/queries';

const Dosages: FC = () => {
  const [toggledDosages, toggleDosages] = useToggle(false);
  const {setId} = useCurrentSlug();
  const {data: product} = useLabel(setId);

  const dosages = useMemo(() => {
    if (product?.prescribableNames && product?.prescribableNames?.length) {
      return product.prescribableNames?.sort(function (a, b) {
        const numA = parseFloat((a.match(/\d+(\.\d+)?/) ?? [])[0] || '');
        const numB = parseFloat((b.match(/\d+(\.\d+)?/) ?? [])[0] || '');

        if (numA < numB) {
          return -1;
        } else if (numA > numB) {
          return 1;
        } else {
          return a.localeCompare(b);
        }
      });
    }
    return (
      product?.names
        ?.filter((name) => name.tty === 'SBD')
        .map((name) => name.name) ?? []
    );
  }, [product?.names, product?.prescribableNames]);

  const expandDosages = (e: any) => {
    e.preventDefault();
    toggleDosages();
  };

  const renderViewMoreButton = useMemo(
    () => dosages?.length > MAX_LINES_CLAMPED,
    [dosages],
  );

  const remaining = useMemo(
    () => dosages.slice(MAX_LINES_CLAMPED)?.length,
    [dosages],
  );

  return (
    <div className="flex flex-col">
      <div className="flex flex-col items-center text-sm">
        {dosages
          .filter((dosage, idx) =>
            !toggledDosages ? idx < MAX_LINES_CLAMPED : true,
          )
          .map((dosage) => (
            <Text key={dosage} className="block">
              {dosage}
            </Text>
          ))}
      </div>

      {renderViewMoreButton ? (
        <Link
          onClick={expandDosages}
          linkSize="sm"
          label={toggledDosages ? 'View less' : `View ${remaining} more`}
          iconRightName="ChevronRight"
          iconRightClassName={toggledDosages ? '-rotate-90' : 'rotate-90'}
          showIconRight
        />
      ) : null}
    </div>
  );
};

export default Dosages;
