import {useMutation} from '@tanstack/react-query';
import {create} from '@/services/api';

interface ICreateInviteUserRequest {
  email: string;
  kind: 'case' | 'task' | 'IsInvitedBy';
  sourceId: string;
  fromId: string;
  toId: string;
  relationship: 'IsInvitedTo' | 'IsInvitedBy';
  credentials?: string;
  firstName?: string;
  lastName?: string;
  writePrescription?: boolean;
  redirectUrl?: string;
  link?: boolean;
}

export async function createInviteUser(params: ICreateInviteUserRequest) {
  return await create(
    'Invitation',
    {
      params,
    },
    {},
    'profile',
    true,
  );
}

export function useCreateInviteUser(options = {}) {
  return useMutation({mutationFn: createInviteUser, ...options});
}
