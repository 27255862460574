import {Logo} from '@prescriberpoint/ui';
import {type FC} from 'react';

const AuthNavigation: FC = () => (
  <div className="relative flex gap-2.5 bg-white px-6 py-3 shadow-sm md:z-40 md:h-16 md:justify-start md:px-16 xl:px-28">
    <div className="flex h-8 items-center">
      <a href={'/'}>
        <Logo />
      </a>
    </div>
  </div>
);

export default AuthNavigation;
