import {Link} from '@prescriberpoint/ui';
import {useAuthPath} from '@/hooks';

export const NotificationContent = () => {
  const {signupPath} = useAuthPath();

  return (
    <div className="flex items-center justify-between">
      <span className="text-base text-white">Saved to bookmarks</span>
      <Link rel="nofollow" href={signupPath} label="View All" />
    </div>
  );
};
