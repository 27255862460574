import {
  Navbar,
  NavbarV2,
  useIsMobileOrTablet,
  useIsSmallDesktop,
} from '@prescriberpoint/ui';
import {useRouter} from 'next/router';
import {FC, useState, useEffect, useMemo, useRef, Dispatch} from 'react';
import {useToggle, useIsClient, useOnClickOutside} from 'usehooks-ts';
import FrictionlessModalForm from './FrictionlessModalForm';
import DrugInteractionsButton from './UserActionMenu/DrugInteractionsButton';
import {AuthNavigation, SearchBar} from '@/components';
import {
  CASE_MANAGEMENT,
  ONLY_HCPS_DISCLAIMER,
  SAMPLES_APP,
  SHOW_AI_CHATBOT_LINK,
  MULTIPLE_SAMPLES,
  NEW_SIDEBAR,
} from '@/constants/flags';
import {AI_CHATBOT_LINK} from '@/constants/global';
import {useFlag, useMobileContentContext} from '@/context';
import {useIsPDP, useUser} from '@/hooks';
import {defaultUser} from '@/hooks/useUser';
import useUserData from '@/hooks/useUserData';
import {useCurrentPerson} from '@/queries';
import {useHCPUser} from '@/queries/useHCPUser';

interface CustomNavbarProps {
  isSidebarOpen?: boolean;
  setSidebarOpen?: Dispatch<boolean>;
  nhOn?: boolean;
}

const CustomNavbar: FC<CustomNavbarProps> = ({
  isSidebarOpen,
  setSidebarOpen,
  nhOn = false,
}) => {
  const isSmallDesktop = useIsSmallDesktop();
  const isMobileOrTablet = useIsMobileOrTablet();
  const isClient = useIsClient();
  const isPDP = useIsPDP();
  const {userData} = useUserData();
  const user = useUser();
  const [showUser, setShowUser] = useState(false);
  const showDisclaimerFlag = useFlag(ONLY_HCPS_DISCLAIMER);
  const showDisclaimer = userData.showDisclaimer && showDisclaimerFlag;
  const {isMenuOpen, toggleMenuOpen} = useMobileContentContext();
  const [isModalFrictionlessOpen, toggleModalFrictionlessOpen] = useToggle();
  const showCaseManagement = useFlag(CASE_MANAGEMENT);
  const showAILink = useFlag(SHOW_AI_CHATBOT_LINK);
  const showSamples = useFlag(SAMPLES_APP);
  const multipleSamplesEnabled = useFlag(MULTIPLE_SAMPLES);
  const newPivotMenuDesign = useFlag(NEW_SIDEBAR);
  const router = useRouter();
  const {data: person} = useCurrentPerson();
  const {data: hcpUser} = useHCPUser();

  const navbarRef = useRef(null);
  useOnClickOutside(navbarRef, () => {
    if (isSidebarOpen && setSidebarOpen) {
      setSidebarOpen(false);
    }
  });

  const isHcp = useMemo(() => !!hcpUser && hcpUser !== true, [hcpUser]);
  const isRep = useMemo(() => !!person && person.role === 'Rep', [person]);

  const customAccountSettingItems =
    multipleSamplesEnabled && (isHcp || isRep)
      ? [
          {
            onClick: () => {
              router.push(`/samples?redirectTo=/profile`);
            },
            id: 'profile',
            label: 'Profile',
          },
        ]
      : [];

  const samplesLink = `/samples${
    multipleSamplesEnabled ? '?redirectTo=/dashboard' : '/provider/shop'
  }`;

  useEffect(() => {
    setShowUser(true);
  }, []);

  if (nhOn) {
    return <AuthNavigation />;
  }

  return (
    <>
      {isModalFrictionlessOpen ? (
        <FrictionlessModalForm
          isOpen={isModalFrictionlessOpen}
          onClose={toggleModalFrictionlessOpen}
        />
      ) : null}
      {newPivotMenuDesign ? (
        <div
          className='border border-x-0 border-t-0 border-solid border-neutral-light lg:border-0'
          ref={navbarRef}>
          <NavbarV2
            isMenuOpen={isMenuOpen && !isModalFrictionlessOpen}
            isMobileOrTablet={isClient && (isSmallDesktop || isMobileOrTablet)}
            SearchBarComponent={SearchBar}
            toggleMenuOpen={toggleMenuOpen}
            user={showUser ? user : defaultUser}
            onClickSidebar={() =>
              setSidebarOpen && setSidebarOpen(!isSidebarOpen)
            }
            showAILink={showAILink}
            showSamples={showSamples}
            samplesLink={samplesLink}
            isSamplesUser={isHcp || isRep}
            isRep={isRep}
          />
        </div>
      ) : (
        <Navbar
          DrugInteractionsButton={DrugInteractionsButton}
          isMenuOpen={isMenuOpen && !isModalFrictionlessOpen}
          isMobileOrTablet={isClient && isMobileOrTablet}
          isPDP={isPDP}
          numberOfItemsInCart={0}
          SearchBarComponent={SearchBar}
          showDisclaimer={showDisclaimer}
          toggleMenuOpen={toggleMenuOpen}
          user={showUser ? user : defaultUser}
          showCaseManagement={showCaseManagement}
          showAILink={showAILink}
          showSamples={showSamples}
          aiChatbotLink={AI_CHATBOT_LINK}
          showCart={false}
          isFrictionless={false}
          isModalFrictionlessOpen={isModalFrictionlessOpen}
          toggleModalFrictionlessOpen={toggleModalFrictionlessOpen}
          samplesLink={samplesLink}
          isSamplesUser={isHcp || isRep}
          isRep={isRep}
          customAccountSettingItems={customAccountSettingItems}
        />
      )}
    </>
  );
};

export default CustomNavbar;
