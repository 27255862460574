import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {useFlag} from './FlagsContext';
import * as FLAGS from '@/constants/flags';
import {useCurrentSlug, useEnhanced} from '@/hooks';
import {EnhancedContentLinkDto, PharmacyAddressDto} from '@/models';
import {BasicPharmacyDto} from '@/models/pharmacyDtoV2';

export interface DistributionData {
  name: string;
  logo: string;
  email: string;
  phone: string;
  address: PharmacyAddressDto;
  patient_services: string[];
  pharma_services: string[];
  conditions: string[];
  contracted_plans: string[];
  accreditation: string[];
}

interface IPharmacyContext {
  showPH: boolean;
  excDistributionData: DistributionData[];
  limitedDistributionData: DistributionData[];
  pharmacyResources?: EnhancedContentLinkDto[];
  setPharmacies: React.Dispatch<React.SetStateAction<BasicPharmacyDto[]>>;
}

const defaultState: IPharmacyContext = {
  showPH: false,
  excDistributionData: [],
  limitedDistributionData: [],
  setPharmacies: () => {},
};

export const PharmacyContext = createContext(defaultState);

export const usePharmacyContext = () => useContext(PharmacyContext);

export const PharmacyContextProvider: FC = (props) => {
  const {setId} = useCurrentSlug();
  const [pharmacies, setPharmacies] = useState<BasicPharmacyDto[]>([]);
  const {enhancedContent, isEnhanced} = useEnhanced(setId);

  const pharmacyResources = useMemo(() => {
    const resources = enhancedContent
      ? enhancedContent?.links.filter(
          (resource) =>
            resource.tags.find(
              (tag) => tag.id === 'sectionPreferredPharmacy',
            ) && !resource.tags.find((tag) => tag.id === 'contentNotAvailable'),
        )
      : [];
    return resources;
  }, [enhancedContent]);

  const generateDistributionData = useCallback(
    (distributionType: string) =>
      pharmacies
        ?.filter((e) => e.distribution === distributionType)
        .map((pharma: BasicPharmacyDto) => ({
          name: pharma.company_information.name,
          logo: '', // TODO: it's not coming
          email: pharma.contact_information.email,
          phone: pharma.contact_information.phone,
          address: {
            street: pharma.company_information.fulladdress,
            street2: '',
            city: pharma.company_information.city,
            state: pharma.company_information.state,
            zip: pharma.company_information.state,
          },
          patient_services: pharma.patient_services,
          pharma_services: pharma.pharmaceutical_services,
          conditions: pharma.conditions_served,
          contracted_plans: pharma.plan_contracts,
          accreditation: [], // TODO it's not coming yet, Greg has to add it
        })),
    [pharmacies],
  );

  const excDistributionData = useMemo(
    () => generateDistributionData('exclusive'),
    [generateDistributionData],
  );

  const limitedDistributionData = useMemo(
    () => generateDistributionData('limited'),
    [generateDistributionData],
  );

  const showPH =
    useFlag(FLAGS.PHARMACY_SECTION) &&
    isEnhanced &&
    (excDistributionData?.length > 0 ||
      limitedDistributionData?.length > 0 ||
      pharmacyResources?.length > 0);

  const values = {
    showPH,
    excDistributionData,
    limitedDistributionData,
    pharmacyResources,
    setPharmacies,
  };
  return <PharmacyContext.Provider value={values} {...props} />;
};
