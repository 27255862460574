import {isValid, parse} from 'date-fns';

const EMAIL_REGEXP =
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;
const WHITE_SPACES_REGEXP = /^\s*$/;
const PHONE_REGEXP =
  /^((\([2-9]{1}[0-9]{2}\))|[2-9]{1}[0-9]{2})[\s\-.]?[\0-9]{3}[\s\-.]?[0-9]{4}$/;
const NUMBER_REGEXP = /^\d+$/;
const URL_REGEXP =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
const BRAND_SLUG_REGEXP = /^[a-z0-9]+(?:-[a-z0-9]+)*-[a-z0-9]{7}$/i;

export function validateEmail(email: string) {
  return EMAIL_REGEXP.test(String(email).toLowerCase());
}

export function validateEmailDomain(email: string, domain: string) {
  return email.split('@')[1] === domain;
}

export function isEmpty(text: string) {
  return text?.length === 0;
}

export function isWhiteSpaceOnly(value: string) {
  return WHITE_SPACES_REGEXP.test(value);
}

export function validateTextPresence(text: string) {
  return !isEmpty(text) && !isWhiteSpaceOnly(text);
}

export function validateLengthBetween(text: string, min: number, max: number) {
  return min <= text?.length && text?.length <= max;
}

export function validatePhoneNumber(value: string) {
  return PHONE_REGEXP.test(value);
}

export function isNumber(value: string) {
  return NUMBER_REGEXP.test(value);
}

export function validateUrl(url: string) {
  return URL_REGEXP.test(url);
}

// code adapted from https://jsfiddle.net/alexdresko/cLNB6
export function validateNpi(npi: string) {
  let tmp;
  let sum;
  let i;
  let j;
  i = npi?.length;
  if (i == 15 && npi.indexOf('80840', 0) == 0) sum = 0;
  else if (i == 10) sum = 24;
  else return false;
  j = 0;
  while (i != 0) {
    tmp = npi.charCodeAt(i - 1) - '0'.charCodeAt(0);
    if (j++ % 2 != 0) {
      if ((tmp <<= 1) > 9) {
        tmp -= 10;
        tmp++;
      }
    }
    sum += tmp;
    i--;
  }
  if (sum % 10 == 0) return true;
  else return false;
}

const MIN_PASSWORD_LENGTH = 8;
const MAX_PASSWORD_LENGTH = 100;

export const validatePasswordLength = (password: string) =>
  validateLengthBetween(password, MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH);

export const validateUppercase = (password: string) => /[A-Z]/.test(password);

export const validateLowercase = (password: string) => /[a-z]/.test(password);

export const validateSpecialCharacter = (password: string) =>
  /[!"#$%&'()*+,-./\:;?@\[\]^_`{|}~\ ]/.test(password);

export function validatePassword(password: string) {
  return (
    validatePasswordLength(password) &&
    validateUppercase(password) &&
    validateLowercase(password) &&
    validateSpecialCharacter(password)
  );
}

export function validateDate(value: string) {
  const date = parse(value, 'MM/dd/yyyy', new Date());
  return value?.length === 10 && isValid(date);
}

export function validateBrandSlug(slug: string) {
  const regex = new RegExp(BRAND_SLUG_REGEXP);
  return regex.test(slug);
}

export function validateDrugComparisonSlug(drug1: string, drug2: string) {
  return validateBrandSlug(drug1) && validateBrandSlug(drug2);
}
