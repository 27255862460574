import {useState, useEffect} from 'react';

export const useWebkitOverScrollFix = (useIt: boolean) => {
  const [isIos, setIsIos] = useState(false);
  const [htmlQuery, setHtmlQuery] = useState<HTMLElement | null>(null);
  const [mainQuery, setMainQuery] = useState<Element | null>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsIos(/iPad|iPhone|iPod/.test(navigator.userAgent));
    }

    return () => {
      htmlQuery?.setAttribute('style', '');
      document?.body?.setAttribute('style', '');
      mainQuery?.setAttribute('style', '');
    };
  }, [htmlQuery, mainQuery]);

  useEffect(() => {
    if (isIos || useIt) {
      const html = document?.querySelector('html');
      const main =
        document?.querySelector('#layout-content') ??
        document?.querySelector('main');

      html && setHtmlQuery(html);
      main && setMainQuery(main);

      html?.setAttribute('style', 'overflow-y: hidden; height: 100%;');
      document?.body?.setAttribute(
        'style',
        'overflow-y: hidden; height: 100%;',
      );
      main?.setAttribute('style', 'opacity: 0;');
    }
  }, [isIos, useIt, htmlQuery, mainQuery]);
};
