import {Icon, Text} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import Link from 'next/link';
import {FC, useRef, useState} from 'react';
import AnimateHeight, {type Height} from 'react-animate-height';
import {useOnClickOutside} from 'usehooks-ts';

export interface ICompareDrugOption {
  slug: string;
  label: string;
  href?: string;
}

export interface ICompareDrugDropdownProps {
  category: string;
  options: ICompareDrugOption[];
  selectedLabel: string;
  className?: ClassValue;
}

const CompareDrugDropdown: FC<ICompareDrugDropdownProps> = ({
  category,
  options,
  selectedLabel,
  className,
}) => {
  const [height, setHeight] = useState<Height>(0);
  const containerRef = useRef(null);

  useOnClickOutside(containerRef, () => setHeight(0));

  const handleOpen = () => {
    setHeight(height === 0 ? 'auto' : 0);
  };

  return (
    <div ref={containerRef} className={clsx('relative w-52', className)}>
      <button
        onClick={handleOpen}
        className="flex w-full items-center justify-between rounded-sm border border-solid border-neutral-tertiary bg-white py-3.5 pl-2 pr-5 text-sm font-normal capitalize text-neutral-secondary-alt">
        {selectedLabel}
        <Icon
          name="ChevronDownSquareCorners"
          className="flex h-3 w-3 items-center text-neutral-darker"
        />
      </button>
      <AnimateHeight
        className="absolute z-20 w-full rounded-sm shadow-md"
        height={height}
        duration={500}>
        <div className="mt-1 w-full rounded-sm bg-white">
          <div className="p-2">
            <Text weight="bold" casing="title">
              {category}
            </Text>
          </div>
          {options.map((option) => (
            <Link
              key={option.slug}
              href={option.href ?? ''}
              className="block cursor-pointer px-4 py-2 text-sm font-normal capitalize text-neutral-primary no-underline">
              {option.label}
            </Link>
          ))}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default CompareDrugDropdown;
