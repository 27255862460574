import {WrappedSearchBar} from '@prescriberpoint/ui';
import {useRouter} from 'next/router';
import {FC} from 'react';
import {
  ALGOLIA_APP_ID,
  ALGOLIA_INDEX,
  ALGOLIA_SEARCH_API_KEY,
  SPLIT_USER_COOKIE,
} from '@/constants/global';
interface SearchBarProps {
  initialReason?: string;
  size: 'lg' | 'sm';
  asLoupe?: boolean;
}

const SearchBar: FC<SearchBarProps> = ({size = 'sm', asLoupe}) => {
  const router = useRouter();

  const onSelectSearchedItem = (path: string) => {
    router.push(path);
  };

  return (
    <WrappedSearchBar
      size={size}
      asLoupe={asLoupe}
      algoliaAppId={ALGOLIA_APP_ID}
      algoliaIndex={ALGOLIA_INDEX}
      algoliaApiKey={ALGOLIA_SEARCH_API_KEY}
      splitUserCookie={SPLIT_USER_COOKIE}
      onSelectSearchedItem={onSelectSearchedItem}
      anchor=""
      subsection=""
    />
  );
};

export default SearchBar;
