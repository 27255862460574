import {useMemo} from 'react';
import {useCurrentSlug} from './useCurrentSlug';
import {useEnhanced} from './useEnhanced';
import {useFlag} from '@/context';

export function useShowEnhancedSection(
  section_id: string,
  flag_name: string,
  checkAvailableResources: boolean = true,
) {
  const {setId} = useCurrentSlug();
  const {isEnhanced} = useEnhanced(setId);
  const {resources} = useEnhanced(setId, section_id);

  const availableResources = useMemo(
    () =>
      checkAvailableResources
        ? resources.filter(
            (resource) =>
              !resource.tags.find(
                (tag) =>
                  tag.id === 'contentNotAvailable' ||
                  tag.id === 'sectionOtherResources',
              ),
          )
        : resources,
    [resources, checkAvailableResources],
  );

  return useFlag(flag_name) && isEnhanced && (checkAvailableResources ? availableResources?.length > 0 : true);
}
