import {useCookies} from 'react-cookie';

const removeCookies = () => {
  var cookies = document.cookie.split('; ');
  //https:stackoverflow.com/a/27374365
  https: document.cookie.split(';').forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });

  //https:stackoverflow.com/a/33366171
  for (var c = 0; c < cookies?.length; c++) {
    var d = window.location.hostname.split('.');
    while (d?.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
        '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
        d.join('.') +
        ' ;path=';
      var p = location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (p?.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      d.shift();
    }
  }
};

const useRemoveAllCookies = () => {
  const [cookies, _setCookie, removeCookie] = useCookies();

  const removeAllCookies = () => {
    Object.keys(cookies).forEach((key) => removeCookie(key));
    removeCookies();
  };

  return {removeAllCookies};
};

export {useRemoveAllCookies};
