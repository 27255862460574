import {getHighlightedText, Text} from '@prescriberpoint/ui';
import {FC, useMemo} from 'react';
import {useInstantSearch} from 'react-instantsearch';
import DrugResultItem from '../SelectBrandsSearch/components/DrugResultItem';
import {DrugSearchResult} from '@/interfaces';
import {capitalize} from '@/utils/string';

interface ResultsProps {
  onDrugSelect: (drug: DrugSearchResult) => void;
}

const Results: FC<ResultsProps> = ({onDrugSelect}) => {
  const {results: searchResults} = useInstantSearch();
  const showSearchResults = useMemo(
    () => !!searchResults?.query,
    [searchResults?.query],
  );

  const filteredSearchResults = useMemo(
    () =>
      searchResults?.hits
        ?.filter(
          (hit) =>
            hit.labeler &&
            new RegExp(`(${searchResults?.query})+`, 'gi').test(
              hit.label_title,
            ),
        ) //select the drug brands that match and have a manufacturer,
        .slice(0, 10),

    [searchResults],
  );

  return (
    <div className='flex-1 overflow-y-auto overflow-x-hidden bg-white'>
      {showSearchResults ? (
        <div className='flex w-full flex-col py-4'>
          {filteredSearchResults?.map((result) => (
            <DrugResultItem
              key={result.objectID}
              onClick={() => onDrugSelect(result)}
              manufacturer={result.labeler}
              drugName={getHighlightedText(
                capitalize(result.label_title),
                searchResults?.query,
                'font-bold',
              )}
            />
          ))}
        </div>
      ) : (
        <div className='mt-8 flex w-full flex-col border border-dashed border-neutral-tertiary p-2'>
          <div className='flex flex-col items-center justify-center px-4 py-2'>
            <Text as='body-sm' weight='bold' variant='tertiary'>
              Select brands to continue
            </Text>
            <Text as='body-xs' weight='bold' variant='tertiary'>
              You can only choose one brand.
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};

const CustomResults = Results;

export default CustomResults;
