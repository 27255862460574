import {useQuery} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {PriorAuthBrandsDto} from '@/models';
import {read} from '@/services/api';

export const PA_BRAND_LIST_DOCUMENTS_QUERY_KEY = 'priorAuthBrandsListDocuments';

const ITEMS_PER_PAGE = 15;

export interface IPAParams {
  page: number;
  sortOrder?: 'asc' | 'desc';
}

export async function getPriorAuthBrandsDocuments({
  page,
  sortOrder = 'asc',
}: IPAParams) {
  let data = null;

  try {
    data = await read<PriorAuthBrandsDto>(
      'documents',
      {
        parentName: 'brand',
        params: {
          start: (page - 1) * ITEMS_PER_PAGE,
          take: ITEMS_PER_PAGE,
          sortOrder: sortOrder,
        },
      },
      {},
      'profile',
    );
  } catch (e) {
    console.error(e);
  }
  return data;
}

export function usePriorAuthBrandsDocuments(paParams: IPAParams) {
  return useQuery<PriorAuthBrandsDto | null>({
    queryKey: [PA_BRAND_LIST_DOCUMENTS_QUERY_KEY],
    queryFn: () => getPriorAuthBrandsDocuments(paParams),
    refetchOnMount: false,
  });
}

export function getPriorAuthBrandsDocumentsPrefetchQuery(
  paParams: IPAParams,
): IPrefetchedQuery {
  return {
    queryKey: [PA_BRAND_LIST_DOCUMENTS_QUERY_KEY],
    getFn: async () => await getPriorAuthBrandsDocuments(paParams),
  };
}
