import {DISTRIBUTION_PHARMACY_SECTION_IDS} from '@/constants/sectionIDs';
import {IPageSection, IPharmacySection} from '@/interfaces';

export const PHARMACY_SECTION: IPageSection = {
  title: '{brandName} Preferred Pharmacy',
  description: {},
  sections: [],
};

const COMMON_PHARMACY_SECTIONS = [
  {
    name: 'Health plan contracts',
    enabled: true,
    id: DISTRIBUTION_PHARMACY_SECTION_IDS.healthPlans,
  },
  {
    name: 'Pharma services',
    enabled: true,
    id: DISTRIBUTION_PHARMACY_SECTION_IDS.pharmaServices,
  },
  {
    name: 'Patient services',
    enabled: true,
    id: DISTRIBUTION_PHARMACY_SECTION_IDS.patientServices,
  },
  {
    name: 'Conditions',
    enabled: true,
    id: DISTRIBUTION_PHARMACY_SECTION_IDS.conditions,
  },
  {
    name: 'Insurance support',
    enabled: true,
    id: DISTRIBUTION_PHARMACY_SECTION_IDS.insuranceSupport,
  },
];

const FORM_PRICE_SECTIONS = [
  {
    name: 'Forms',
    enabled: false,
    id: '',
  },
  {
    name: 'Price list',
    enabled: false,
    id: '',
  },
];

export const DISTRIBUTION_PHARMACY_SECTIONS: IPharmacySection[] = [
  {
    name: 'Limited distribution',
    enabled: false,
    id: '',
  },
  ...COMMON_PHARMACY_SECTIONS,
  {
    name: 'Exclusive distribution',
    enabled: false,
    id: '',
  },
  ...FORM_PRICE_SECTIONS,
];

export const LIMITED_DISTRIBUTION_PHARMACY_SECTIONS: IPharmacySection[] = [
  ...COMMON_PHARMACY_SECTIONS,
  {
    name: 'Exclusive distribution',
    enabled: false,
    id: '',
  },
  ...FORM_PRICE_SECTIONS,
];

export const EXCLUSIVE_DISTRIBUTION_PHARMACY_SECTIONS: IPharmacySection[] = [
  ...COMMON_PHARMACY_SECTIONS,
  ...FORM_PRICE_SECTIONS,
];

export const DISTRIBUTION_PHARMACY_SECTION_INFO = {
  limited_distribution: {
    title: 'Limited distribution drugs',
    question:
      'Does {drugName} have a limited distribution partnership with {pharmacyName}?',
    answer:
      'Yes, {pharmacyName} has a limited distribution relationship with. They also support the following drugs:',
  },
  limited_for_exclusive_distribution: {
    title: 'Limited distribution drugs',
    question: 'What limited distribution drugs does {pharmacyName} carry? ',
    answer:
      '{pharmacyName} has a limited distribution relationship with the following drugs: ',
  },
  exclusive_distribution: {
    title: 'Exclusive Distribution Drugs',
    question:
      'Does {drugName} have an exclusive distribution with {pharmacyName}?',
    answer: 'Yes, {pharmacyName} has exclusive distribution for {drugName}',
  },
  health_plans: {
    title: 'Health plan contracts',
    question: 'Which health plans does {pharmacyName} have contracts with?',
    answer: '{pharmacyName} works closely with the following health plans:',
  },
  pharma_services: {
    title: 'Pharma services',
    question:
      'Does {pharmacyName} work closely with {drugName} and {manufacturerName}?',
    answer:
      'Yes, {pharmacyName} does work with {drugName} and offers some of the services below: ',
  },
  patient_services: {
    title: 'Patient services',
    question: 'Does {pharmacyName} offer services for {drugName} patients? ',
    answer:
      'Indeed, {pharmacyName} offers the following services for {drugName} patients:',
  },
  conditions: {
    title: 'Conditions',
    question: 'What conditions does {pharmacyName} support?',
    answer: '{pharmacyName} supports the following conditions:',
  },
  insurance_support: {
    title: 'Insurance support',
    question: 'How does {pharmacyName} help with insurance?',
    answer: '{pharmacyName} can help with insurance in the following ways:',
  },
};
