import {useCurrentDrug} from './useCurrentDrug';
import {useEnhanced} from './useEnhanced';
import {useIsOtc} from './useIsOtc';
import {useLabel} from '@/queries';

export const useDlvDrugInfo = () => {
  const {setId, drugName, labelerName} = useCurrentDrug();
  const {data: label} = useLabel(setId);
  const {isEnhanced, enhancedContent} = useEnhanced(setId);
  const isOTC = useIsOtc();

  return {
    setId,
    drugName,
    pharmaCompany: labelerName,
    isEnhanced: isOTC ? 'otc' : isEnhanced ? 'enhanced' : 'non-enhanced',
    drugType: label?.metadata?.generic === 'true' ? 'generic' : 'brand',
    drugSpecialties: isEnhanced
      ? enhancedContent?.therapeuticAreas.toString()!
      : 'n/a',
  };
};
