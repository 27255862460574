import {useMutation} from '@tanstack/react-query';
import {create} from '@/services/api';

async function makeShareRequest({
  brand,
  resource,
  link,
  type,
  target,
  senderName,
}: {
  brand: string;
  resource: string;
  link: string;
  type: string;
  target: string;
  senderName: string;
}) {
  return await create(
    'share/indication',
    {
      parentName: 'relay',
      params: {brand, resource, link, type, target, senderName},
    },
    {},
    'profile',
  );
}

export function useCreateShareRequest() {
  return useMutation({mutationFn: makeShareRequest});
}
