import {IComboBoxOption} from '@prescriberpoint/ui';
import {useQuery} from '@tanstack/react-query';
import {EMPLOYER_CHANNEL_NAME} from '@/constants/employerCoverage';
import {IPlan} from '@/models/mmit';
import {read} from '@/services/api';

export interface IEmployerListOption extends IComboBoxOption {
  planId: number;
  mcoId: number;
  mcoName: string;
  formularyId: number;
  channelId: number;
  channelName: string;
  planTypeId: number;
  planTypeName: string;
}

export async function getEmployers(
  query: string,
): Promise<IEmployerListOption[]> {
  let data: IEmployerListOption[] = [];
  let top: number | undefined;

  if (query.length === 0) {
    top = 10;
  } else if (query.length < 4) {
    top = 30;
  }

  try {
    const employersList = await read<IPlan[]>(
      'employersList',
      {
        parentName: 'coverage',
        params: {
          q: query,
          top,
        },
      },
      {},
      'nextApi',
    );

    data = employersList?.map((employer) => ({
      id: employer.PlanId,
      value: employer.Name,
      planId: employer.PlanId,
      mcoId: employer.MCOId,
      mcoName: employer.MCO,
      formularyId: employer.FormularyId,
      channelId: employer.ChannelId,
      channelName: EMPLOYER_CHANNEL_NAME,
      planTypeId: employer.PlanTypeId,
      planTypeName: EMPLOYER_CHANNEL_NAME,
    }));
  } catch (error) {
    console.error(error);
  }
  return data;
}

export function useEmployers(query: string, options = {}) {
  return useQuery<IEmployerListOption[]>({
    queryKey: ['employers-list', query],
    queryFn: async () => await getEmployers(query),
    enabled: true,
    ...options,
  });
}
