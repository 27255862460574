import {useMemo} from 'react';
import {useEnhanced} from './useEnhanced';
import {useLabel} from '@/queries';
import {getProductFAQData} from '@/utils/product';

export const useFaqData = (setId: string) => {
  const {data: product} = useLabel(setId);
  const {enhancedContent} = useEnhanced(setId);

  // Variables for google rich snippets
  const {dosages, howAdministrated, indications, madeOf, moa, name} =
    getProductFAQData(product ?? null);

  const faqMainEntityData = useMemo(() => {
    if (enhancedContent?.faqs && enhancedContent.faqs?.length > 0) {
      const mapped = enhancedContent.faqs.map((faq) => ({
        '@type': 'Question',
        name: faq.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq.acceptedAnswer,
        },
      }));
      return JSON.stringify(mapped);
    } else {
      return `[
              {
                "@type": "Question",
                "name": "What is the dosage of ${name}?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${dosages}"
                }
              },
              {
                "@type": "Question",
                "name": "What does ${name} treat?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${indications}"
                }
              },
              {
                "@type": "Question",
                "name": "What is ${name} made of?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${madeOf}"
                }
              },
              {
                "@type": "Question",
                "name": "How is ${name} administered?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${howAdministrated}"
                }
              },
              {
                "@type": "Question",
                "name": "What is ${name} mechanism of action?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${moa}"
                }
              }
            ]`;
    }
  }, [
    enhancedContent,
    dosages,
    howAdministrated,
    indications,
    madeOf,
    moa,
    name,
  ]);

  return {faqMainEntityData, faqName: name};
};
