import {Modal, Text, Button} from '@prescriberpoint/ui';
import {FC, useEffect, useState} from 'react';
import {useLocalStorage} from 'usehooks-ts';
import AgreeTermsAndCondtitionsAndPrivacyPolicy from '../Auth/AddEmail/AgreeTermsAndCondtitionsAndPrivacyPolicy';
import AuthFooter, {FooterCTA} from '../Auth/AuthFooter';
import CTAForm from '../CTAForm';
import RegistrationModalHeader from './RegistrationModalHeader';
import {useUserAgentContext} from '@/context/UserAgentContext';
import {useError} from '@/hooks';
import {useAuthPath} from '@/hooks/useAuthPath';
import {useAddEmail} from '@/mutations/useAddEmail';
import {APIError} from '@/services/request';
import {validateEmail, validateTextPresence} from '@/utils/validations';

export interface IRegistrationModalProps {
  resourceImg?: string;
  redirectUrl?: string;
  customEvent?: (email: string) => void;
  onClose?: () => void;
  isGenericVersion?: boolean;
  benefit?: string;
  suggestion?: string;
}

const RegistrationModal: FC<IRegistrationModalProps> = ({
  resourceImg,
  redirectUrl,
  customEvent,
  onClose = () => {},
  isGenericVersion,
  benefit = 'PrescriberPoint offers free resources and support for 35,000+ therapies',
  suggestion = 'Save this brand and other FDA-approved drug labels to view later',
}) => {
  const [invalidEmailError, setInvalidEmailError] = useState(false);
  const {loginPath} = useAuthPath();
  const [isOpen, setIsOpen] = useState(true);
  const [submitCompleted, setSubmitCompleted] = useState(false);
  const {mutateAsync: register, error} = useAddEmail();
  const [_, setPaywall] = useLocalStorage('user_submitted_paywall', false);
  const {errorMsg} = useError(error as APIError | undefined);

  const {isMobileOrTablet} = useUserAgentContext();

  const footerCTAs: FooterCTA[] = [
    {
      label: 'Already have an account?',
      href: loginPath + `/?redirect=${window.location.pathname}`,
      button: 'Log in',
    },
  ];

  useEffect(() => {
    if (error) {
      // @ts-ignore
      if (error.code === 409) {
        setInvalidEmailError(true);
      } else {
        setInvalidEmailError(false);
      }
    }
  }, [error, isMobileOrTablet]);

  useEffect(() => {
    if (!isOpen) {
      onClose && onClose();
    }
  }, [isOpen, onClose]);

  const handleSubmit = async (data: any) => {
    try {
      await register(data);
      customEvent && customEvent(data.email);
      setPaywall(true);
      setSubmitCompleted(true);
      if (redirectUrl) {
        window.open(redirectUrl, '_blank');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      className="max-h-[98%] h-max md:w-[500px] md:max-w-[500px]"
      showCancel={false}
      showConfirm={false}
      isOpen={isOpen}
      closeOnClickOutside={false}
      closeModal={() => {
        setIsOpen(false);
      }}
      showCloseButton={false}>
      {!submitCompleted ? (
        <div className="flex flex-col space-y-8">
          <RegistrationModalHeader
            setIsOpen={setIsOpen}
            resourceImg={resourceImg}
            isGenericVersion={isGenericVersion}
            benefit={benefit}
            suggestion={suggestion}
          />
          <div className="flex flex-col space-y-6 md:space-y-8">
            <div className="flex flex-col space-y-4 pb-6 md:space-y-8 md:pb-8">
              <CTAForm
                form={{
                  submitText: isGenericVersion
                    ? 'Create An Account'
                    : 'Get Unlimited Access',
                  isSubmittingText: 'Submitting...',
                  inputs: [
                    {
                      id: 'email',
                      type: 'email',
                      label: 'Email',
                      required: true,
                      placeholder: 'Your work email',
                      errorMessage: 'Please add a valid work email.',
                      validateFn: (v: string) =>
                        validateEmail(v) && validateTextPresence(v),
                    },
                  ],
                }}
                onSubmit={handleSubmit}
                submitError={!!error}
                submitErrorMsg={
                  invalidEmailError
                    ? 'Please add a valid work email.'
                    : errorMsg
                }
                submitButtonSize="lg"
              />
              <div>
                <AuthFooter as="body-md" callToActions={footerCTAs} />
                <AgreeTermsAndCondtitionsAndPrivacyPolicy />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col space-y-8">
          <RegistrationModalHeader
            setIsOpen={setIsOpen}
            resourceImg={resourceImg}
            isGenericVersion={isGenericVersion}
            benefit={benefit}
            suggestion={suggestion}
          />
          <div className="flex flex-col space-y-4 pb-6 md:space-y-8 md:pb-8">
            <Text as="body-md">
              Thank you, please check your email for your account verification
              link.
            </Text>
            <Button
              size="lg"
              id="close_register_modal"
              stretched
              onClick={onClose}>
              Continue
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default RegistrationModal;
