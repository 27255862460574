import {useMutation} from '@tanstack/react-query';
import {LoginDto} from './useLogin';
import {USE_EMAIL_FOR_REP_REGISTER_OTP_VALIDATION} from '@/constants/flags';
import {useFlag} from '@/context';
import {create} from '@/services/api';

export interface IRepCompleteSignup {
  token: string;
  firstName: string;
  lastName: string;
  role: string;
  primaryWorkZipCode: string;
  password: string;
  brands: string[];
}

async function repCompleteSignup(params: IRepCompleteSignup, version: 1 | 2) {
  return await create<LoginDto>(
    'RepSignup',
    {
      parentName: 'Auth',
      params,
    },
    {},
    'profile',
    false,
    version,
  );
}

export function useRepCompleteSignup() {
  const useEmailForOtpValidation = useFlag(
    USE_EMAIL_FOR_REP_REGISTER_OTP_VALIDATION,
  );

  return useMutation({
    mutationFn: (params: IRepCompleteSignup) =>
      repCompleteSignup(params, useEmailForOtpValidation ? 2 : 1),
  });
}
