import {ModalNotification} from '@prescriberpoint/ui';
import {FC} from 'react';

interface IFrictionlessSuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  email?: string;
}

const FrictionlessSuccessModal: FC<IFrictionlessSuccessModalProps> = ({
  isOpen = false,
  onClose,
  email,
}) => {
  const handleCloseModal = () => {
    onClose();
  };

  return (
    <ModalNotification
      actionMessage="Check your Email to Login"
      actionContext={`We've sent a you magic link to ${
        email ?? 'your email.'
      } For security this link expires in 30 minutes`}
      type="success"
      showCta={false}
      isOpen={isOpen}
      closeModal={handleCloseModal}
    />
  );
};
export default FrictionlessSuccessModal;
