import {Icon, Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC} from 'react';

interface IEmailChipProps {
  children: string;
  onDelete?: () => void;
  error?: boolean;
}

const EmailChip: FC<IEmailChipProps> = ({children, onDelete, error}) => (
  <div
    className={clsx(
      'flex min-h-[1.5rem] items-center justify-between space-x-3 rounded-sm border border-solid px-2 py-0.5 text-sm font-medium',
      error
        ? 'border-error !text-error'
        : 'border-neutral-tertiary-alt text-neutral-dark',
    )}>
    <Text
      className={clsx('break-all', error ? 'text-error' : 'text-neutral-dark')}
      weight="medium">
      {children}
    </Text>
    <button onClick={onDelete}>
      <Icon name="Cancel" className="flex h-[11px] w-[11px] items-center" />
    </button>
  </div>
);

export default EmailChip;
