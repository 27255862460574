import {Modal, Icon, Text} from '@prescriberpoint/ui';
import {FC, useEffect, useMemo, useState} from 'react';
import Content from './Content';
import {DDI_MULTI_DRUGS} from '@/constants/flags';
import {useFlag} from '@/context';
import {useDrugInteractionsContext} from '@/context/DrugInteractionsContext';
import {SearchListProvider} from '@/context/SearchListContext';

interface DDIModalProps {
  setId?: string;
  drugName?: string;
  genericName?: string;
}
const DDIModal: FC<DDIModalProps> = ({
  setId = '',
  drugName = '',
  genericName = '',
}) => {
  const {isOpen, setIsOpen} = useDrugInteractionsContext();

  const ddiMultiDrugsEnabled = useFlag(DDI_MULTI_DRUGS);

  const [slug, setSlug] = useState('');

  useEffect(() => {
    const path = window.location.pathname;
    const splitted = path.split('/');
    if (path.includes('/therapies/')) {
      setSlug(splitted[2]);
    }
  }, []);

  const selectedResults = useMemo(() => {
    if (!setId) {
      return [];
    }
    return [
      {
        set_id: setId,
        label_title: drugName,
        generic_name: genericName,
        slug: slug as string,
        labeler: '',
      },
    ];
  }, [setId, drugName, genericName, slug]);

  return (
    <Modal
      showCancel={false}
      showConfirm={false}
      position="side"
      showCloseButton
      isOpen={isOpen}
      closeOnClickOutside={false}
      title={
        ddiMultiDrugsEnabled ? (
          <Text as="headline-sm" weight="bold" tag="h2">
            Check Drug Interactions
          </Text>
        ) : (
          <div className="flex items-center">
            <Icon name="Interactions" className="mr-3 h-6 w-6" />
            <Text as="headline-sm" weight="bold" tag="h2">
              Check Drug Interactions
            </Text>
          </div>
        )
      }
      closeModal={() => setIsOpen(false)}>
      <SearchListProvider selectedResults={selectedResults}>
        <Content />
      </SearchListProvider>
    </Modal>
  );
};

export default DDIModal;
