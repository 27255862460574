import {useQuery, QueryClient} from '@tanstack/react-query';
import {PriorAuthBrandsDto} from '@/models';
import {read} from '@/services/api';

const PA_BRAND_LIST_QUERY_KEY = 'priorAuthBrandsList';

export async function getPriorAuthBrands(): Promise<PriorAuthBrandsDto | null> {
  let data = null;
  try {
    data = await read<PriorAuthBrandsDto>('Brand', {}, {}, 'profile');
  } catch (e) {
    console.error(e);
  }
  return data;
}

export function usePriorAuthBrands(options = {}) {
  return useQuery<PriorAuthBrandsDto | null>({
    queryKey: [PA_BRAND_LIST_QUERY_KEY],
    queryFn: () => getPriorAuthBrands(),
    refetchOnMount: false,
    ...options,
  });
}

export async function fetchPriorAuthBrands(queryClient: QueryClient) {
  return await queryClient.fetchQuery({
    queryKey: [PA_BRAND_LIST_QUERY_KEY],
    queryFn: () => getPriorAuthBrands(),
  });
}
