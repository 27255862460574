import {useMutation} from '@tanstack/react-query';
import {update} from '@/services/api';

interface IUpdateUser {
  userId: string;
  data: any;
}

async function updateUser({userId, data}: IUpdateUser) {
  return await update(
    'UpdateUser',
    {
      parentName: 'Auth',
      resourceId: userId,
      params: data,
    },
    {method: 'PUT'},
    'profile',
    true,
  );
}

export function useUpdateUserRequest() {
  return useMutation({mutationFn: updateUser});
}
