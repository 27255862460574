import {useRouter} from 'next/router';
import {createContext, FC, useContext, useEffect, useState} from 'react';

interface IBlackBoxWarningContext {
  idxOpened: number;
  setIdxOpened: (idx: number) => void;
}

const defaultState: IBlackBoxWarningContext = {
  idxOpened: -1,
  setIdxOpened: () => {},
};

const BlackBoxWarningContext = createContext(defaultState);

export const useBlackBoxWarningContext = () =>
  useContext(BlackBoxWarningContext);

export const BlackBoxWarningProvider: FC = (props) => {
  const [idxOpened, setIdxOpened] = useState(-1);
  const router = useRouter();

  useEffect(() => {
    setIdxOpened(-1);
  }, [router]);

  const values = {
    idxOpened,
    setIdxOpened,
  };

  return <BlackBoxWarningContext.Provider value={values} {...props} />;
};
