import {useMutation} from '@tanstack/react-query';
import {PROTOCOL_HOSTNAME} from '@/constants/global';
import {create} from '@/services/api';

interface ICreateInviteReminderRequest {
  email: string;
  redirect: string;
}

export async function createInviteReminder({
  email,
  redirect,
}: ICreateInviteReminderRequest) {
  return await create(
    'Share',
    {
      parentName: 'Collaboration',
      params: {
        to: [email],
        redirect: `${PROTOCOL_HOSTNAME}/login?redirect=${redirect}`,
      },
    },
    {},
    'profile',
    true,
  );
}

export function useCreateInviteReminder(options = {}) {
  return useMutation({mutationFn: createInviteReminder, ...options});
}
