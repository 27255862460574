import {useQuery} from '@tanstack/react-query';
import { RecentDocumentDto } from '@/models';
import {read} from '@/services/api';

interface ICarrierRequest {
  total: number;
  start: number;
  next: number;
  take: number;
  sortField: string;
  results: RecentDocumentDto[];
}

const fetchPAFormByCarrier = async (setId: string, carrierId?: string | number) => {
  let data: ICarrierRequest | null = null;

  try {
    data = await read<any>(
      'RecentByBrandAndCarrier',
      {
        parentName: 'Document',
        params: {
          setId,
          carrierId,
        }
      },
      {},
      'profile',
      false,
      1,
    );

    return data?.results
  } catch (error) {
    console.error(error);
  }
};

export function usePaFormsByCarrier(setId: string, carrierId?: number | string, options = {}) {
  return useQuery({
    queryKey: ['paFormByCarrier', setId, carrierId],
    queryFn: () => fetchPAFormByCarrier(setId, carrierId),
    ...options,
  });
}