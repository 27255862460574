import {useMutation} from '@tanstack/react-query';
import {create} from '@/services/api';

export interface ICreateTaskRequest {
  name: string;
  dueDate?: string;
  status: string;
  resourceTitle?: null | string;
  resourceHref?: null | string;
  resourceType?: null | string;
  details?: string;
}

export interface ICreateTask extends ICreateTaskRequest {
  setId?: string;
  caseId?: string;
  createdBy: string;
  assignedTo: string;
  patientName: string;
  patientDOB: string;
}

export interface ICreateTaskResponse extends ICreateTaskRequest {
  id: string;
}

export async function createTask(
  params: ICreateTaskRequest,
): Promise<ICreateTaskResponse> {
  return await create(
    'Task',
    {
      params,
    },
    {},
    'graph',
    true,
  );
}

export function useCreateTask(options = {}) {
  return useMutation({mutationFn: createTask, ...options});
}
