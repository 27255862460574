import {IComboBoxOption} from '@prescriberpoint/ui';
import {useQuery} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {McoListDto} from '@/models';
import {read} from '@/services/api';

export async function getMCOList(): Promise<IComboBoxOption[] | null> {
  let data: IComboBoxOption[] | null = null;

  try {
    const mcoList = await read<McoListDto[]>(
      'coverage/mcoList',
      {},
      {},
      'nextApi',
    );
    data = mcoList?.map((mco) => ({id: mco.MCOId, value: mco.MCOName}));
  } catch (e) {
    console.error(e);
  }
  return data;
}

export function useMCOList(options = {}) {
  return useQuery<IComboBoxOption[] | null>({
    queryKey: ['mco-list'],
    queryFn: getMCOList,
    refetchOnMount: false,
    initialData: [],
    ...options,
  });
}

export function getMCOListPrefetchQuery(options = {}): IPrefetchedQuery {
  return {
    queryKey: ['mco-list'],
    getFn: async () => await getMCOList(),
    ...options,
  };
}
