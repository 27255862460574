import {useQuery} from '@tanstack/react-query';
import {PracticeUserDto} from '@/models';
import {read} from '@/services/api';

export async function getPracticeUsers(
  email: string,
): Promise<PracticeUserDto[] | null> {
  let data = null;

  if (!email) return data;

  try {
    data = await read<PracticeUserDto[]>(
      'Person',
      {
        params: {
          domain: email,
        },
      },
      {},
      'profile',
      true,
    );
  } catch (e) {
    console.error(e);
  }
  return data;
}

export function usePracticeUsers(email: string, options = {}) {
  return useQuery<PracticeUserDto[] | null>({
    queryKey: ['practice-users', email],
    queryFn: () => getPracticeUsers(email),
    ...options,
  });
}
