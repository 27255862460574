import {IStep} from '@/components';
import {validateEmail, validateTextPresence} from '@/utils/validations';

export const ASSIGN_RESOURCE_FORM: IStep[] = [
  {
    number: 1,
    label: 'Assign To',
    inputs: [
      {
        id: 'coworker_name',
        type: 'text',
        label: 'Assign To',
        required: true,
        placeholder: "Coworker's first and last name",
        defaultValue: '',
        errorMessage: "Please enter coworker's full name",
        inputSize: 'lg',
        validateFn: (v: string) => validateTextPresence(v),
      },
      {
        id: 'coworker_email',
        type: 'email',
        label: 'Email',
        required: true,
        placeholder: 'Their practice email',
        defaultValue: '',
        errorMessage: 'Please enter a valid email',
        inputSize: 'lg',
        validateFn: (v: string) => validateTextPresence(v) && validateEmail(v),
      },
    ],
  },
  {
    number: 2,
    label: 'From',
    inputs: [
      {
        id: 'from_name',
        type: 'text',
        label: 'From',
        required: true,
        placeholder: 'Your first and last name',
        defaultValue: '',
        errorMessage: 'Please enter your name',
        inputSize: 'lg',
        validateFn: (v: string) => validateTextPresence(v),
      },
      {
        id: 'from_email',
        type: 'email',
        label: 'Email',
        required: true,
        placeholder: 'Your practice email',
        defaultValue: '',
        errorMessage: 'Please enter a valid email',
        inputSize: 'lg',
        validateFn: (v: string) => validateTextPresence(v) && validateEmail(v),
      },
    ],
  },
];
