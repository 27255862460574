import {Icon, IconButton, Text} from '@prescriberpoint/ui';
import {useSearchListContext} from '@/context/SearchListContext';
import {capitalize} from '@/utils/string';

const SelectedSearchResults = () => {
  const {selectedResults, showSelectedResults, removeFromSelectedResults} =
    useSearchListContext();

  if (!showSelectedResults) {
    return null;
  }

  if (selectedResults.length === 0) {
    return (
      <div className="mt-14 text-center">
        <Text size="md" weight="bold">
          Add two or more drugs to compare potential drug interactions.
        </Text>
      </div>
    );
  }

  return (
    <div className="mt-4 flex flex-col gap-y-3">
      {selectedResults?.map((result) => (
        <div
          key={result.set_id}
          className="flex items-center pl-6 pr-4 py-3 bg-neutral-lighter rounded-xl">
          <div className="flex-1">
            <Text tag="div" size="sm" variant="secondary" weight="bold">
              {result?.generic_name?.toLocaleLowerCase()}
            </Text>
            <span className="text-base font-semibold text-neutral-primary">
              {capitalize(result.label_title)}
            </span>
          </div>
          <IconButton
            id="remove-drug"
            size="sm"
            onClick={() => removeFromSelectedResults(result.set_id)}
            icon={
              <Icon className="h-5 w-5 text-error" name="SubstractFilled" />
            }
          />
        </div>
      ))}
    </div>
  );
};

export default SelectedSearchResults;
