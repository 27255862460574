import {useEffect, useState} from 'react';
import {UNKNOWN_ERROR} from '@/constants/global';
import {APIError} from '@/services/request';

const useError = (error?: APIError) => {
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (error) {
      if (error.data.code === UNKNOWN_ERROR) {
        setErrorMsg(
          'Something happened. Please contact support@prescriberpoint.com.',
        );
      } else {
        setErrorMsg(error.message);
      }
    }
  }, [error]);

  return {errorMsg};
};

export {useError};
