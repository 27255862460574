/* eslint-disable import/named */
import {Icon, Text} from '@prescriberpoint/ui';
import {FC, useCallback, useMemo} from 'react';
import {Link} from 'react-scroll';
import {useBlackBoxWarningContext} from '@/context/BlackBoxWarningContext';
import {useCurrentSlug} from '@/hooks';
import {useLabel} from '@/queries';

interface BlackBoxWarningLinkProps {}
const BlackBoxWarningLink: FC<BlackBoxWarningLinkProps> = () => {
  const {setIdxOpened} = useBlackBoxWarningContext();
  const {setId} = useCurrentSlug();
  const {data: product} = useLabel(setId);

  const hasWarning = useMemo(() => product?.label?.boxedWarning, [product]);

  const handleLinkClick = useCallback(() => {
    setIdxOpened(0);
  }, [setIdxOpened]);

  return hasWarning ? (
    <Link
      to="boxed_warning"
      className="cursor-pointer"
      offset={-48}
      smooth="easeInOutQuart"
      duration={1000}
      onClick={handleLinkClick}>
      <div className="flex items-center space-x-1">
        <Icon
          name="WarningShield"
          className="flex-items-center w-4 text-error"
        />
        <Text variant="primary" as="body-sm" weight="semibold">
          BOXED WARNING
        </Text>
      </div>
    </Link>
  ) : (
    <Text as="body-sm" weight="semibold" className="text-neutral-darker/56">
      NO BOXED WARNING
    </Text>
  );
};

export default BlackBoxWarningLink;
