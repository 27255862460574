import {Text, customEvent} from '@prescriberpoint/ui';
import {FC} from 'react';
import {CEVENT_BUTTON_CLICKED} from '@/constants';
import * as FLAGS from '@/constants/flags';
import {useFlag} from '@/context';
import {useCurrentSlug, useEnhanced} from '@/hooks';

const PrescriberAI: FC = () => {
  const {setId} = useCurrentSlug();
  const {isEnhanced, disableAi} = useEnhanced(setId);
  const showAIPPT = useFlag(FLAGS.PPT_AI_COMPONENT) && !disableAi;
  const hideChatbotForNoEnhanced = useFlag(FLAGS.HIDE_CHATBOT_FOR_NO_ENHANCED);

  const showButtonAI = showAIPPT && (isEnhanced || !hideChatbotForNoEnhanced);

  if (!showButtonAI) {
    return;
  }

  const onHandleClick = () => {
    customEvent(CEVENT_BUTTON_CLICKED, {id: 'aiWC_button'});
  };

  return (
    <div className="static animate-fadeIn">
      <div className="fixed left-0 bottom-0 w-full bg-neutral-lighter z-20 p-4">
        <button
          id="aiWC_button"
          onClick={onHandleClick}
          className="m-0 inline-flex cursor-pointer items-center justify-center rounded-sm text-sm font-semibold px-7 py-2.5 border text-neutral-primary hover:bg-neutral-lighter focus:bg-neutral-light active:bg-neutral-light border-solid variant mt-2 h-12 w-full gap-1 border-theme-dark bg-theme-lighter-alt">
          <div className="relative h-7 w-11">
            <img
              alt="Prescriber AI"
              className="h-full w-full"
              src="https://cdn.prescriberpoint.com/assets/images/prescriber-ai.png"
            />
          </div>
          <div>
            <Text as="body-md" weight="bold">
              Prescriber
            </Text>
            <Text as="body-md" weight="semibold">
              AI
            </Text>
          </div>
        </button>
      </div>
    </div>
  );
};

export default PrescriberAI;
