import {toSnakeCase} from '@prescriberpoint/ui';
import {PAYWALL_IMGS} from '@/data/paywall';
import {EnhancedContentLinkDto, RecentDocumentDto} from '@/models';

export function isMostRecentPaResource(obj: any): obj is RecentDocumentDto {
  return obj.updatedDate !== undefined;
}

export function isEnhancedContentLink(obj: any): obj is EnhancedContentLinkDto {
  return obj.tags !== undefined;
}

export function isEnhancedContentResources(
  obj: any[],
): obj is EnhancedContentLinkDto[] {
  return obj[0]?.tags !== undefined;
}

export function isMostRecentResources(obj: any[]): obj is RecentDocumentDto[] {
  return obj[0]?.updatedDate !== undefined;
}

export const getResourceImg = (resourceTitle: string, drugName: string) => {
  if (drugName !== 'Xeljanz') {
    return;
  } else {
    return PAYWALL_IMGS[toSnakeCase(drugName)][toSnakeCase(resourceTitle)];
  }
};
