import {Loader} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, useState} from 'react';
import FormError, {FormErrorProps} from './FormError';

interface FormViewerProps {
  fileUrl: string;
  id?: string;
  formError: FormErrorProps;
}

const FormViewer: FC<FormViewerProps> = ({fileUrl, id, formError}) => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleError = () => {
    setIsLoading(false);
    setError(true);
  };

  const handleLoadComplete = () => {
    setError(false);
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  };

  if (error) {
    return <FormError {...formError} />;
  }

  return (
    <div className="relative h-[350px] w-full md:h-[750px]" id={id}>
      {isLoading ? (
        <div className="flex w-full h-full items-center justify-center">
          <Loader />
        </div>
      ) : null}
      <iframe
        id={id}
        className={clsx('w-full h-full', {hidden: isLoading})}
        src={fileUrl}
        onLoad={handleLoadComplete}
        onError={handleError}
        title="PDF Viewer"
      />
    </div>
  );
};

export default FormViewer;
