import {format, parseISO} from 'date-fns';

export const MONTH_IN_MS = 1000 * 60 * 60 * 24 * 30;
export const CURRENT_DATE = new Date().getTime();

export const formatToYearMonth = (dateString: string) => {
  const date = parseISO(dateString);

  return format(date, 'yyyy MMM');
};

export const formatToMonthYear = (dateString: string) => {
  const date = parseISO(dateString);

  return format(date, 'MMM, yyyy');
};

// 2023-05-05T20:02:00.281Z => May 05, 2023
const formatISOtoString = (date: string) =>
  format(new Date(date), 'MMM dd, yyyy');

export {formatISOtoString};

export const getMostRecentDate = (defaultValue: string, dates: string[]) => {
  if (dates?.length === 0) return defaultValue;
  const mostRecentDate = new Date(
    Math.max(...dates.map((date) => new Date(date).getTime())),
  );
  return formatISOtoString(mostRecentDate.toISOString());
};

export function getLastVerifiedDate(docDate: string) {
  let newDate = new Date(docDate).getTime();
  while (newDate < CURRENT_DATE - MONTH_IN_MS) newDate += MONTH_IN_MS;
  return new Date(newDate).toISOString();
}

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
