import {useLocalStorage} from 'usehooks-ts';
import {useCurrentDrug} from './useCurrentDrug';
import useUser from './useUser';
import {RESOURCE_PAYWALL_ON_XELJANZ} from '@/constants/flags';
import {useFlag} from '@/context';

export const useFirePaywall = () => {
  const {drugName} = useCurrentDrug();
  const {logged} = useUser();
  const [paywallSubmitted] = useLocalStorage('user_submitted_paywall', false);

  const showResourcePaywallOnXejanz = useFlag(RESOURCE_PAYWALL_ON_XELJANZ);
  if (!showResourcePaywallOnXejanz) return false;

  return drugName === 'Xeljanz' && !logged && paywallSubmitted !== true;
};
