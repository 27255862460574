import {DropdownOption} from '@prescriberpoint/ui';
import {useQuery} from '@tanstack/react-query';
import {read} from '@/services/api';

export interface ICoverageFilters extends DropdownOption {
  plans: DropdownOption[];
}

export interface ICoverageFiltersResponse {
  mcoId: string;
  filters: ICoverageFilters[];
}

export async function getCoverageFilters(
  mcoId: string,
): Promise<ICoverageFiltersResponse | null> {
  let data = null;

  try {
    data = await read<ICoverageFiltersResponse>(
      'filters',
      {
        parentName: 'coverage',
        parentId: mcoId,
      },
      {},
      'nextApi',
    );
  } catch (e) {
    console.error(e);
  }

  return data;
}

export function useCoverageFilters(mcoId: string, options = {}) {
  return useQuery<ICoverageFiltersResponse | null>({
    queryKey: ['coverageFilters', mcoId],
    queryFn: async () => await getCoverageFilters(mcoId),
    ...options,
  });
}
