import {useMemo} from 'react';
import {SPECIALTY_PHARMACIES_DISTRIBUTION_DESCRIPTIONS} from '@/data/specialyPharmaciesByBrandPage';
import {useCurrentDrug, useGetDataFromIp} from '@/hooks';
import {usePharmacyV2} from '@/queries';
import {capitalize, replaceVariablesInStr} from '@/utils';

const usePharmacySEO = (pharmacyId: string) => {
  const {drugName} = useCurrentDrug();
  const {data: pharmacy} = usePharmacyV2(pharmacyId);
  const {city, state, zip} = useGetDataFromIp();

  const {title, description} = useMemo(() => {
    const title = `${drugName} | ${pharmacy?.name} | ${
      pharmacy?.distribution === 'unknown'
        ? 'Near Me'
        : `${capitalize(pharmacy?.distribution!)} Distribution`
    }`;
    const addLocation = !!city && !!state && !!zip;
    const description = `${addLocation ? `${city}, ${state} ${zip} - ` : ''}${
      pharmacy?.distribution === 'unknown'
        ? replaceVariablesInStr(
            SPECIALTY_PHARMACIES_DISTRIBUTION_DESCRIPTIONS['unknown'],
            {specialtyPharmacyName: pharmacy?.name, brandName: drugName},
          )
        : replaceVariablesInStr(
            SPECIALTY_PHARMACIES_DISTRIBUTION_DESCRIPTIONS[
              pharmacy?.distribution as 'limited' | 'exclusive'
            ],
            {specialtyPharmacyName: pharmacy?.name!, brandName: drugName},
          )
    }`;
    return {title, description};
  }, [drugName, pharmacy?.name, pharmacy?.distribution, city, state, zip]);

  return {title, description};
};

export {usePharmacySEO};
