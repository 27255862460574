import {IPageSection} from '@/interfaces';

export const PRIOR_AUTHORIZATION_SECTION: IPageSection = {
  title: '{brandName} Prior Authorization Resources',
  description: {
    standard:
      "More and more therapies require a prior authorization from a patient's insurance company to be covered.",
    enhanced:
      "We've curated the most actionable {brandName} prior authorization resources and linked them below.",
  },
  question: 'How prior authorizations work?',
  answer: [
    {
      title: '',
      items: [
        'Prior authorization (PA), which permits {brandName} coverage when specific requirements are satisfied, promotes safe, economical drug use. By analyzing labeling that has been approved by the U.S. Food and Drug Administration (FDA), scientific literature, and internationally accepted norms, a team of doctors and pharmacists creates and approves the clinical programs and criteria for drugs that are acceptable for prior authorization.',
        'All prior authorization medications require doctors to fill out and submit a request form. If the member satisfies the requirements, benefits will be granted. If the requirements are not satisfied, the member may still decide to get the drug but will be charged the full price. Regardless of whether a benefit has been established, the patient and their doctor will ultimately decide which medications should be administered.',
        'Do you or your employees take an excessive amount of time to process prior authorizations for {brandName}? We provide you with the resources to ease and enhance the prior authorization procedure for your practice or department.',
      ],
    },
  ],
  sections: [
    {
      title: 'Most recent {drugName} prior authorization forms',
      sectionId: 'sectionRecentPriorAuthorizationForms',
      standard: true,
      enhanced: true,
      description: {},
    },
    {
      title: 'Most recent state uniform prior authorization forms',
      sectionId: 'sectionRecentStateUniformPriorAuthorizationForms',
      standard: true,
      enhanced: true,
      description: {},
    },
    {
      title: '',
      sectionId: 'sectionPriorAuthorizationForms',
      standard: true,
      enhanced: true,
      description: {
        standard: (
          <div>
            <span>
              Brands publish templates to ease the prior authorization process,
              which include:
            </span>
            <ul>
              <li>Letter of Medical Necessity</li>
              <li>Coverage Authorization Request</li>
              <li>Coverage Authorization Appeals</li>
            </ul>
          </div>
        ),
        enhanced:
          'If available, these templates are provided by the brand to help you navigate insurance, especially with newer drugs.',
      },
      fixedResources: [
        'Letter of Medical Necessity',
        'Coverage Authorization request',
        'Coverage Authorization appeals',
      ],
      question: 'Why a letter of medical necessity may be needed?',
      content: (
        <div>
          A Letter of Medical Necessity is a letter written by a healthcare
          provider on behalf of a patient that explains why a particular medical
          treatment or device is medically necessary. The letter is submitted to
          the patient&apos;s insurance company to support a request for
          reimbursement for the costs associated with the recommended treatment
          or device.
          <br />
          <br />
          A well-written Letter of Medical Necessity can improve the chances
          that a patient&apos;s insurance company will approve coverage for the
          recommended treatment or device.
          <br />
          <br />
          A well-written Letter of Medical Necessity can improve the chances
          that a patient&apos;s insurance company will approve coverage for the
          recommended treatment or device.
          <br />
          <br />
          A Letter of Medical Necessity should be written by a healthcare
          provider who is familiar with the patient&apos;s medical history and
          current health condition. The letter should include:
          <br />
          <br />
          <ul>
            <li>
              The patient&apos;s diagnosis and why the recommended treatment or
              device is medically necessary
            </li>
            <li>
              A discussion of any conservative treatments that have been tried
              and why they have been unsuccessful
            </li>
            <li>
              An estimate of the expected cost of the recommended treatment or
              device
            </li>
            <li>
              The expected length of time the patient will need to use the
              recommended treatment or device
            </li>
            <li>
              The anticipated outcome if the patient does not receive the
              recommended treatment or device
            </li>
          </ul>
          <br />
          The Letter of Medical Necessity should be submitted to the
          patient&apos;s insurance company along with any supporting
          documentation, such as medical records or reports.
        </div>
      ),
    },
    {
      sectionId: 'sectionBenefitsInvestigation',
      title: 'Benefits investigation',
      standard: true,
      enhanced: true,
      description: {
        standard:
          "Benefits investigations are conducted to determine whether a therapy is covered under a patient's insurance, if a prior authorization is required, and which specialty pharmacies are preferred.",
        enhanced:
          "Benefits investigations are conducted to determine whether a therapy is covered under a patient's insurance, if a prior authorization is required, and which specialty pharmacies are preferred.",
      },
    },
    {
      title: 'Reimbursement help (FRM)',
      sectionId: 'sectionFieldReimbursementManager',
      standard: true,
      enhanced: true,
      description: {
        standard:
          "Some brands offer a field reimbursement manager who will work with your clinical staff and preferred pharmacy to help make sure patients don't fall through the cracks.",
        enhanced:
          "Some brands offer a field reimbursement manager who will work with your clinical staff and preferred pharmacy to help make sure patients don't fall through the cracks.",
      },
    },
  ],
};
