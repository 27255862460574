import {Button} from '@prescriberpoint/ui';
import dynamic from 'next/dynamic';
import {NextRouter, withRouter} from 'next/router';
import {Component, ErrorInfo, ReactNode} from 'react';
import {COOKIE_CONSENT_ERROR} from './Footer/CookieConsent';

const ErrorPage = dynamic(() => import('./ErrorPage'));

const MainLayoutWrapper = dynamic(() => import('@/layouts/MainLayout'));

const CookieConsent = dynamic(() => import('./Footer/CookieConsent'));

interface Props {
  children: ReactNode;
  router: NextRouter;
}
interface State {
  hasError: boolean;
  errorMessage: string;
  isModalActive: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {hasError: false, errorMessage: '', isModalActive: false};
  }
  static getDerivedStateFromError(error: Error) {
    //@ts-ignore
    if (typeof window !== 'undefined' && window.newrelic?.noticeError) {
      //@ts-ignore
      window.newrelic?.noticeError(error, {
        message: error?.message,
        errorSource: 'ErrorBoundary/getDerivedStateFromError',
      });
    }
    return {hasError: true, errorMessage: error?.message || ''};
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // log error to error reporting service
    console.log({error, errorInfo});
    //@ts-ignore
    if (typeof window !== 'undefined' && window.newrelic?.noticeError) {
      //@ts-ignore
      window.newrelic?.noticeError(error, {
        errorInfo,
        errorSource: 'ErrorBoundary',
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      prevProps.router.asPath !== this.props.router.asPath &&
      this.state.hasError
    ) {
      this.setState({
        hasError: false,
      });
    }
  }

  toggleModal() {
    this.setState({
      ...this.state,
      isModalActive: !this.state.isModalActive,
    });
  }

  ctaComponent = () => {
    if (this.state.errorMessage === COOKIE_CONSENT_ERROR)
      return (
        <>
          <Button
            onClick={() => this.setState({isModalActive: true})}
            size="lg"
            id="enable_cookies">
            Enable Cookies
          </Button>
          <CookieConsent
            isModalActive={this.state.isModalActive}
            toggleModal={() => this.toggleModal()}
            onSave={() => this.setState({...this.state, hasError: false})}
          />
        </>
      );
    else
      return (
        <Button
          onClick={() => this.setState({hasError: false})}
          size="lg"
          id="try_again">
          Try again?
        </Button>
      );
  };

  render() {
    if (this.state.hasError) {
      return (
        <MainLayoutWrapper>
          <ErrorPage
            errorCode={null}
            errorMessage={this.state.errorMessage || 'Oops, there is an error!'}
            ctaComponent={this.ctaComponent()}
          />
        </MainLayoutWrapper>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
