import {useQuery} from '@tanstack/react-query';
import {PracticeLocationDto} from '@/models';
import {read} from '@/services/api';

export async function getPracticeLocation(
  locationId: string,
): Promise<PracticeLocationDto | null> {
  let data = null;

  if (!locationId) return data;

  try {
    data = await read<PracticeLocationDto>(
      'Location',
      {resourceId: locationId},
      {},
      'profile',
      true,
    );
  } catch (e) {
    console.error(e);
  }

  return data;
}

export function usePracticeLocation(locationId: string, options = {}) {
  return useQuery<PracticeLocationDto | null>({
    queryKey: ['practice-id', locationId],
    queryFn: () => getPracticeLocation(locationId),
    ...options,
  });
}
