import {
  FC,
  useState,
  createContext,
  useContext,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import {DrugSearchResult, IResult} from '@/interfaces';

const SELECTED_RESULTS_LIMIT = 999; //Limit the BRANDS that users could add to the list. Currently "there's no limit"

interface SearchBrandsProps {
  selectedResults?: IResult[];
}

interface ISearchBrandsContext {
  isSearchDisabled: boolean;
  selectedValue: DrugSearchResult | undefined;
  selectedResults: IResult[];
  setSelectedResults: (results: IResult[]) => void;
  openSearch: boolean;
  addToSelectedResults: (result: IResult[]) => void;
  removeFromSelectedResults: (set_id: string) => void;
  setSelectedValue: (searchedItem: DrugSearchResult) => void;
  setOpenSearch: (value: boolean) => void;
  saveBrandsSelected: () => void;
  selectedLabeler: string;
  setSelectedLabeler: (value: string) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const defaultState = {
  isSearchDisabled: false,
  selectedValue: undefined,
  selectedResults: [],
  openSearch: false,
  addToSelectedResults: () => {},
  removeFromSelectedResults: () => {},
  setSelectedValue: () => {},
  setOpenSearch: () => {},
  saveBrandsSelected: () => {},
  selectedLabeler: '',
  setSelectedLabeler: () => {},
  setSelectedResults: () => {},
};

export const SearchBrandsContext =
  createContext<ISearchBrandsContext>(defaultState);

export const useSearchBrandsContext = () => {
  const context = useContext(SearchBrandsContext);
  if (!context) {
    throw new Error(
      'useSearchBrandsContext must be used within a SearchBrandsProvider',
    );
  }

  return context;
};

export const SearchBrandsProvider: FC<SearchBrandsProps> = ({
  selectedResults: defaultSelectedResults = [],
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState<DrugSearchResult>();
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedResults, setSelectedResults] = useState<IResult[]>(
    defaultSelectedResults,
  );
  const [selectedLabeler, setSelectedLabeler] = useState('');
  const [resultsToAdd, setResultsToAdd] = useState<DrugSearchResult[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const removeFromSelectedResults = useCallback(
    (set_id: string) => {
      setSelectedResults((prev) => prev.filter((r) => r.set_id !== set_id));
    },
    [setSelectedResults],
  );

  const addToSelectedResults = useCallback(
    (result: IResult[]) => {
      setSelectedResults((prev) => [...prev, ...result]);
    },
    [setSelectedResults],
  );

  const isSearchDisabled = useMemo(
    () => selectedResults?.length >= SELECTED_RESULTS_LIMIT,
    [selectedResults?.length],
  );

  const saveBrandsSelected = useCallback(() => {
    setOpenSearch(false);
  }, []);

  const values = useMemo(
    () => ({
      isSearchDisabled,
      selectedValue,
      selectedResults,
      addToSelectedResults,
      removeFromSelectedResults,
      setSelectedValue,
      openSearch,
      setOpenSearch,
      saveBrandsSelected,
      resultsToAdd,
      setResultsToAdd,
      selectedLabeler,
      setSelectedLabeler,
      inputRef,
      setSelectedResults,
    }),
    [
      isSearchDisabled,
      selectedValue,
      selectedResults,
      addToSelectedResults,
      removeFromSelectedResults,
      setSelectedValue,
      openSearch,
      setOpenSearch,
      saveBrandsSelected,
      resultsToAdd,
      setResultsToAdd,
      selectedLabeler,
      setSelectedLabeler,
      inputRef,
      setSelectedResults,
    ],
  );

  return <SearchBrandsContext.Provider value={values} {...props} />;
};
