import {useMemo} from 'react';
import {DISTRIBUTION_PHARMACY_SECTION_IDS} from '@/constants/sectionIDs';
import {
  DISTRIBUTION_PHARMACY_SECTIONS,
  DISTRIBUTION_PHARMACY_SECTION_INFO,
  EXCLUSIVE_DISTRIBUTION_PHARMACY_SECTIONS,
  LIMITED_DISTRIBUTION_PHARMACY_SECTIONS,
} from '@/data';
import {useCurrentDrug} from '@/hooks';
import {IPharmacySection} from '@/interfaces';
import {useLabel, usePharmacyV2} from '@/queries';

const usePharmacySections = (currentLocation: string) => {
  const {setId} = useCurrentDrug();
  const {data: drug} = useLabel(setId);
  const {data: pharmacy} = usePharmacyV2(currentLocation);

  const sections = useMemo(() => {
    const baseSections = [
      {
        ...DISTRIBUTION_PHARMACY_SECTION_INFO.health_plans,
        sectionId: DISTRIBUTION_PHARMACY_SECTION_IDS.healthPlans,
        services: pharmacy?.contracted_plans,
      },
      {
        ...DISTRIBUTION_PHARMACY_SECTION_INFO.pharma_services,
        sectionId: DISTRIBUTION_PHARMACY_SECTION_IDS.pharmaServices,
        services: pharmacy?.pharma_services,
      },
      {
        ...DISTRIBUTION_PHARMACY_SECTION_INFO.patient_services,
        sectionId: DISTRIBUTION_PHARMACY_SECTION_IDS.patientServices,
        services: pharmacy?.patient_services,
      },
      {
        ...DISTRIBUTION_PHARMACY_SECTION_INFO.conditions,
        sectionId: DISTRIBUTION_PHARMACY_SECTION_IDS.conditions,
        services: drug?.indications
          ? drug.indications.map(({name}) => name)
          : [],
      },
      {
        ...DISTRIBUTION_PHARMACY_SECTION_INFO.insurance_support,
        sectionId: DISTRIBUTION_PHARMACY_SECTION_IDS.insuranceSupport,
        services: pharmacy?.payer_services,
        joinChar: ';',
      },
    ];
    return baseSections;
  }, [
    drug?.indications,
    pharmacy?.contracted_plans,
    pharmacy?.patient_services,
    pharmacy?.payer_services,
    pharmacy?.pharma_services,
  ]);

  const commonQuestionsSection = useMemo(() => {
    const enabledSections: IPharmacySection[] = [];
    const notEnabledSections: IPharmacySection[] = [];
    let arr: IPharmacySection[] = [];
    if (pharmacy?.distribution === 'unknown')
      arr = DISTRIBUTION_PHARMACY_SECTIONS;
    else if (pharmacy?.distribution === 'limited') {
      arr = LIMITED_DISTRIBUTION_PHARMACY_SECTIONS;
      const limitedSection = {
        name: 'Limited distribution',
        enabled:
          pharmacy?.limited_brands && pharmacy?.limited_brands?.length > 0,
        id: DISTRIBUTION_PHARMACY_SECTION_IDS.limitedDistribution,
      };
      if (limitedSection.enabled) enabledSections.push(limitedSection);
      else notEnabledSections.push(limitedSection);
    } else if (pharmacy?.distribution === 'exclusive') {
      arr = EXCLUSIVE_DISTRIBUTION_PHARMACY_SECTIONS;
      const exclusiveSection = {
        name: 'Exclusive distribution',
        enabled:
          pharmacy?.exclusive_brands && pharmacy?.exclusive_brands?.length > 0,
        id: DISTRIBUTION_PHARMACY_SECTION_IDS.exclusiveDistribution,
      };

      if (exclusiveSection.enabled) enabledSections.push(exclusiveSection);
      else notEnabledSections.push(exclusiveSection);

      const limitedSection = {
        name: 'Limited distribution',
        enabled:
          pharmacy?.limited_brands && pharmacy?.limited_brands?.length > 0,
        id: DISTRIBUTION_PHARMACY_SECTION_IDS.limitedDistribution,
      };

      if (limitedSection.enabled) enabledSections.push(limitedSection);
      else notEnabledSections.push(limitedSection);
    }

    arr.forEach(({enabled, ...section}) => {
      if (!enabled) notEnabledSections.push({enabled, ...section});
      else {
        const hasContent = sections.find(
          ({sectionId, services}) =>
            sectionId === section.id &&
            services?.length &&
            services?.length > 0,
        );
        if (hasContent) enabledSections.push({...section, enabled: true});
        else notEnabledSections.push({...section, enabled: false});
      }
    });
    return [...enabledSections, ...notEnabledSections];
  }, [
    pharmacy?.distribution,
    pharmacy?.limited_brands,
    pharmacy?.exclusive_brands,
    sections,
  ]);

  return {commonQuestionsSection, sections};
};

export {usePharmacySections};
