import {Icon, IconButton} from '@prescriberpoint/ui';
import {DDI_TOOL} from '@/constants/flags';
import {useFlag} from '@/context';
import {useDrugInteractionsContext} from '@/context/DrugInteractionsContext';

export default function DrugInteractionsButton() {
  const showDDI = useFlag(DDI_TOOL);
  const {setIsOpen} = useDrugInteractionsContext();

  return showDDI ? (
    <IconButton
      onClick={() => setIsOpen(true)}
      id="drug_interactions"
      icon={<Icon name="Interactions" className="h-6 w-6" />}
    />
  ) : null;
}
