import {joinWords} from '.';
import {ProductDto} from '@/models';

export function getDosages(product: ProductDto, drugName: string) {
  if (!product?.dosages) {
    return '';
  }
  const dosages = product.dosages?.map(
    (dosage) => `${dosage.dose} ${dosage.form}`,
  );
  return `${drugName} is available in ${
    product?.dosages?.length
  } dosages, including ${joinWords(dosages, 'and')}`;
}

export function getHowAdministrated(product: ProductDto, drugName: string) {
  const administrationOptions = new Set<string>();
  product?.dosages?.forEach((dosage) =>
    administrationOptions.add(dosage.route),
  );
  return `${drugName} is administered as a ${joinWords(
    Array.from(administrationOptions),
  )}`;
}

function getIndications(product: ProductDto, drugName: string) {
  if (!product?.indications) {
    return '';
  }
  const indications = product?.indications?.map(
    (indication) => indication.name,
  );
  return `${drugName} treats ${joinWords(indications, 'and')}`;
}

function getMadeOf(product: ProductDto, drugName: string) {
  if (!product?.classes || !product?.names) {
    return '';
  }
  const productClassName = product?.classes?.find(
    (classData) => classData.type === 'EPC',
  )?.name;
  if (!productClassName) return '';

  const filterTTY = (tty: string) =>
    product.names?.filter((nameData) => nameData.tty === tty);
  let filteredNames = filterTTY('MIN');
  if (filteredNames?.length === 0) {
    filteredNames = filteredNames.concat(filterTTY('IN'));
    if (filteredNames?.length === 0) {
      const classes = product.classes.filter(
        (classData) => classData.type === 'EPC',
      );
      const names = classes.map((classData) => classData.name);
      return `${drugName} contains ${joinWords(names)}`;
    }
  }
  const madeOfNames = filteredNames.map((nameData) => nameData.name);

  return `${drugName} contains ${joinWords(madeOfNames, 'and')} which is a ${
    product?.classes?.find((classData) => classData.type === 'EPC')?.name
  }`;
}

function getMOA(product: ProductDto, drugName: string) {
  if (!product?.classes) {
    return '';
  }
  const moa = product.classes
    ?.filter((classData) => classData.type === 'MOA')
    .map((classData) => classData.name);
  if (moa?.length === 0) return '';
  return `${drugName} mechanism of action is ${joinWords(moa)}`;
}

export const getProductName = (product: ProductDto | null) =>
  product?.names?.find((name) => name.tty === 'BN')?.name ?? '';

export function getProductFAQData(product: ProductDto | null | undefined) {
  if (product === null || product === undefined)
    return {
      dosages: '',
      howAdministrated: '',
      indications: '',
      madeOf: '',
      moa: '',
      name: '',
    };

  const name = getProductName(product);
  const dosages = getDosages(product, name);
  const howAdministrated = getHowAdministrated(product, name);
  const indications = getIndications(product, name);
  const madeOf = getMadeOf(product, name);
  const moa = getMOA(product, name);

  return {dosages, howAdministrated, indications, madeOf, moa, name};
}
