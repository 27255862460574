import {Icon, Text} from '@prescriberpoint/ui';
import {FC, useCallback, useMemo} from 'react';
import {Link} from 'react-scroll';
import {useBlackBoxWarningContext} from '@/context/BlackBoxWarningContext';
import {useCurrentSlug} from '@/hooks';
import {useLabel} from '@/queries';

const BoxedWarning: FC = () => {
  const {setIdxOpened} = useBlackBoxWarningContext();
  const {setId} = useCurrentSlug();
  const {data: product} = useLabel(setId);

  const hasWarning = useMemo(() => product?.label?.boxedWarning, [product]);

  const handleLinkClick = useCallback(() => {
    setIdxOpened(0);
  }, [setIdxOpened]);

  return hasWarning ? (
    <Link
      to="boxed_warning"
      className="cursor-pointer"
      offset={-48}
      smooth="easeInOutQuart"
      duration={1000}
      onClick={handleLinkClick}>
      <div className="flex items-center space-x-2 py-2 px-4 bg-error-fill rounded-[64px]">
        <Icon name="WarningShield" className="w-4 text-error flex" />
        <Text
          as="body-xs"
          weight="bold"
          className="block text-error whitespace-nowrap">
          BOXED WARNING
        </Text>
      </div>
    </Link>
  ) : (
    <div className="flex items-center space-x-2 py-2 px-4 rounded-[64px] border border-solid border-neutral-quaternary-alt">
      <Text
        as="body-xs"
        variant="secondary"
        weight="bold"
        className="block whitespace-nowrap">
        NO BOXED WARNING
      </Text>
    </div>
  );
};

export default BoxedWarning;
