// eslint-disable-next-line import/named
import {useCookies} from 'react-cookie';
// eslint-disable-next-line import/named
import {CookieSetOptions} from 'universal-cookie';
import {COOKIE_CONSENT_COOKIE} from '@/constants/global';

export const useHandleSetCookies = () => {
  const [cookies, setCookie] = useCookies();
  const hasConsent = cookies[COOKIE_CONSENT_COOKIE] !== 'false';

  const handleSetCookie = (
    name: string,
    value: any,
    options?: CookieSetOptions | undefined,
    callback?: (hasConsent: boolean) => void,
  ) => {
    if (hasConsent) {
      setCookie(name, value, options);
    }
    if (callback) {
      callback(hasConsent);
    }
  };

  return {handleSetCookie};
};
