import {useQuery} from '@tanstack/react-query';
import {IpDTO} from '@/models/ipDto';

export async function getIPData(): Promise<IpDTO> {
  const res = await fetch('https://geolocation-db.com/json/');

  const text = await res.text();
  const data = JSON.parse(text);

  return data;
}

export function useIpData() {
  return useQuery<IpDTO>({queryKey: ['ipData'], queryFn: () => getIPData()});
}
