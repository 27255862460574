import {useQuery} from '@tanstack/react-query';
import {LoginDto} from '../mutations/useLogin';
import {type RegistrationProcessType} from '@/mutations';
import {read} from '@/services/api';

export interface IConfirmEmail {
  token: string;
  process: RegistrationProcessType;
}

async function confirmEmail(params: IConfirmEmail) {
  return await read<LoginDto>(
    'Validate',
    {
      parentName: 'Auth',
      resourceId: params.token,
      params: {
        process: params.process,
      },
    },
    {},
    'profile',
  );
}

export function useConfirmEmail(params: IConfirmEmail, options = {}) {
  return useQuery({
    queryKey: ['confirmEmail', params],
    queryFn: async () => await confirmEmail(params),
    ...options,
  });
}
