import {Text, TextCasing} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {FC} from 'react';
import Icon, {type LucideIconNameType} from '../Icon';

export interface ICrossSellCtaProps {
  children: string;
  textCasing?: TextCasing;
  className?: ClassValue;
  iconName?: LucideIconNameType;
  onClick?: () => void;
}

const CrossSellCta: FC<ICrossSellCtaProps> = ({
  children,
  textCasing = 'title',
  className,
  iconName = 'file-chart-pie',
  onClick,
}) => (
  <button
    onClick={onClick}
    className={clsx(
      'flex w-full items-center gap-2 rounded-xl bg-white px-3 py-4 lg:max-w-[172px] lg:flex-col lg:items-start',
      className,
    )}>
    <div className='flex size-8 items-center justify-center rounded-full bg-green-100 text-neutral-dark'>
      <Icon name={iconName} size={16} />
    </div>
    <div className='flex items-center text-left'>
      <Text as='body-sm' weight='bold' casing={textCasing}>
        {children}
      </Text>
    </div>
  </button>
);

export default CrossSellCta;
