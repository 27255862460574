import {SECTION_IDS, FULL_SECTION_IDS} from './sectionIDs';

interface ISectionIds {
  [key: string]: string;
}

// These are used as query param e.g https://prescriberpoint.com/therapies/taltz-ac96658?help=get-auth-assistance
const CTA_SERVICES: ISectionIds = {
  requestSamples: 'request-samples',
  getAuthAssistance: 'get-auth-assistance',
  getFinancialAssistance: 'get-financial-assistance',
  requestPatientEducation: 'request-patient-education',
  getManufacturerSupport: 'get-manufacturer-support',
  coverageRestrictions: 'coverage-restrictions',
  prescribability: 'prescribability',
};

// For matching query parameters to PDP sections
const CTA_SECTIONS_IDS: ISectionIds = {
  [CTA_SERVICES.requestSamples]: SECTION_IDS.requestSamples,
  [CTA_SERVICES.getAuthAssistance]: SECTION_IDS.priorAuthorization,
  [CTA_SERVICES.getFinancialAssistance]: SECTION_IDS.financialAssistance,
  [CTA_SERVICES.requestPatientEducation]: SECTION_IDS.patientEducation,
  [CTA_SERVICES.getManufacturerSupport]: SECTION_IDS.drugLabel,
};

const CTA_BUTTON_IDS: ISectionIds = {
  [CTA_SERVICES.requestSamples]: 'dosage_and_admin_cta',
  [CTA_SERVICES.getAuthAssistance]: 'prior_authorization_cta',
  [CTA_SERVICES.getFinancialAssistance]: 'financial_assistance_cta',
  [CTA_SERVICES.requestPatientEducation]: 'rep_contact_cta',
  [CTA_SERVICES.getManufacturerSupport]: 'drug_label_cta',
  [CTA_SERVICES.coverageRestrictions]: 'coverage_restrictions_cta',
  [CTA_SERVICES.prescribability]: 'prescribability_cta',
};

const COVERAGE_RESTRICTIONS_ID =
  CTA_BUTTON_IDS[CTA_SERVICES.coverageRestrictions];

const COVERAGE_RESTRICTIONS_TOOL_ID = 'coverage_restrictions_tool_cta';
const CONTACT_MANUFACTURER_ID =
  CTA_BUTTON_IDS[CTA_SERVICES.getManufacturerSupport];
const SAMPLES_AVAILABLE_ID = 'samples_available_cta';
const PRIOR_AUTORIZATION_FORMS_ID = 'prior_authorization_forms_cta';
const GET_FINANCIAL_ASSISTANCE_ID = 'get_financial_assistance_cta';
const GET_PATIENT_EDUCATION_RESOURCES_ID =
  'get_patient_education_resources_cta';
const PRESCRIBERPOINT_MEDICAL = 'prescriberpoint_medical';

const CTA_SECTIONS = [
  {
    sectionId: FULL_SECTION_IDS.dosingAndAdmiministration,
    id: COVERAGE_RESTRICTIONS_ID,
    title: 'See Coverage Restrictions',
  },
  {
    sectionId: FULL_SECTION_IDS.financialAssistance,
    id: GET_FINANCIAL_ASSISTANCE_ID,
    title: 'Get Affordability Enrollment Forms',
  },
  {
    sectionId: FULL_SECTION_IDS.patientEducation,
    id: GET_PATIENT_EDUCATION_RESOURCES_ID,
    title: 'Get Patient Education Material',
  },
  {
    sectionId: FULL_SECTION_IDS.priorAuthorization,
    id: PRIOR_AUTORIZATION_FORMS_ID,
    title: 'Get Prior Authorization Forms',
  },
];

const CTA_SECTIONS_V2 = [
  {
    sectionId: FULL_SECTION_IDS.dosingAndAdmiministration,
    id: COVERAGE_RESTRICTIONS_ID,
    title: 'See Coverage Restrictions',
    iconName: 'Coverage',
  },
  {
    sectionId: FULL_SECTION_IDS.drugLabel,
    id: CTA_BUTTON_IDS[CTA_SERVICES.getManufacturerSupport],
    title: 'Contact Manufacturer',
    iconName: 'ContactSupport',
  },
  {
    sectionId: FULL_SECTION_IDS.priorAuthorization,
    id: CTA_BUTTON_IDS[CTA_SERVICES.getAuthAssistance],
    title: 'Get Prior Authorization Support',
    iconName: 'ClinicalFe',
  },
  {
    sectionId: FULL_SECTION_IDS.financialAssistance,
    id: CTA_BUTTON_IDS[CTA_SERVICES.getFinancialAssistance],
    title: 'Get Affordability Enrollment Forms',
    iconName: 'IExamMultipleChoise',
  },
  {
    sectionId: 'contact_sections',
    id: CTA_BUTTON_IDS[CTA_SERVICES.requestPatientEducation],
    title: 'Request Patient Education Material',
    iconName: 'HealthLiteracy',
  },
];

const CTA_SECTIONS_V3 = [
  {
    sectionId: FULL_SECTION_IDS.dosingAndAdmiministration,
    id: COVERAGE_RESTRICTIONS_ID,
    title: 'See Coverage Restrictions',
    iconName: '',
  },
  {
    sectionId: FULL_SECTION_IDS.financialAssistance,
    id: GET_FINANCIAL_ASSISTANCE_ID,
    title: 'Get Affordability Enrollment Forms',
    iconName: 'IExamMultipleChoise',
  },
  {
    sectionId: FULL_SECTION_IDS.patientEducation,
    id: GET_PATIENT_EDUCATION_RESOURCES_ID,
    title: 'Get Patient Education Material',
    iconName: 'HealthLiteracy',
  },
  {
    sectionId: FULL_SECTION_IDS.priorAuthorization,
    id: PRIOR_AUTORIZATION_FORMS_ID,
    title: 'Get Prior Authorization Forms',
    iconName: 'ClinicalFe',
  },
  {
    sectionId: PRESCRIBERPOINT_MEDICAL,
    id: PRESCRIBERPOINT_MEDICAL,
    title: 'PrescriberPoint Medical',
    iconName: 'IExamMultipleChoise',
  },
];

export {
  CTA_SERVICES,
  CTA_SECTIONS_IDS,
  CTA_BUTTON_IDS,
  CTA_SECTIONS,
  CTA_SECTIONS_V2,
  CTA_SECTIONS_V3,
  COVERAGE_RESTRICTIONS_ID,
  COVERAGE_RESTRICTIONS_TOOL_ID,
  CONTACT_MANUFACTURER_ID,
  SAMPLES_AVAILABLE_ID,
  PRIOR_AUTORIZATION_FORMS_ID,
  GET_FINANCIAL_ASSISTANCE_ID,
  GET_PATIENT_EDUCATION_RESOURCES_ID,
  PRESCRIBERPOINT_MEDICAL,
};
