import {useMutation} from '@tanstack/react-query';
import {RepUpdateDto} from '@/models';
import {create} from '@/services/api';

export interface IRepCreateCustomer {
  name: string;
  repId?: string;
}

const API_VERSION = 2;

async function createRepCustomer(params: IRepCreateCustomer) {
  return await create<RepUpdateDto>(
    'Customer',
    {
      params,
    },
    {},
    'samples',
    true,
    API_VERSION,
  );
}

export function useCreateRepCustomer() {
  return useMutation({mutationFn: createRepCustomer});
}
