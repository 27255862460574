import {ModalNotification} from '@prescriberpoint/ui';
import {FC} from 'react';

interface IFrictionlessErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const FrictionlessErrorModal: FC<IFrictionlessErrorModalProps> = ({
  isOpen = false,
  onClose,
}) => {
  const handleCloseModal = () => {
    onClose();
  };

  return (
    <ModalNotification
      isOpen={isOpen}
      actionMessage="Something went wrong"
      actionContext="Sorry! we are having technical problems, try again in a few minutes"
      type="destructive"
      showCta={false}
      closeModal={handleCloseModal}
    />
  );
};
export default FrictionlessErrorModal;
