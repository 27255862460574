import {LabelTitles} from '@/models';

export const LABEL_TITLES: {[key in keyof LabelTitles]: string} = {
  boxedWarning: 'Boxed Warning',
  recentMajorChanges: 'Recent Major Changes',
  indicationsAndUsage: 'Indications & Usage',
  dosageAndAdministration: 'Dosage & Administration',
  dosageFormsAndStrengths: 'Dosage Forms & Strengths',
  useInSpecificPopulations: 'Pregnancy & Lactation',
  contraindications: 'Contraindications',
  warningsAndPrecautions: 'Warnings & Precautions',
  adverseReactions: 'Adverse Reactions',
  drugInteractions: 'Drug Interactions',
  clinicalTrialsExperience: 'Clinical Trials Experience',
  immunogenicity: 'Immunogenicity',
  postmarketingExperience: 'Postmarketing Experience',
  pregnancy: 'pregnancy',
  lactation: 'Lactation',
  pediatricUse: 'Pediatric Use',
  geriatricUse: 'Geriatric Use',
  description: 'Description',
  clinicalPharmacology: 'Pharmacology',
  mechanismOfAction: 'Mechanism of Action',
  pharmacodynamics: 'Pharmacodynamics',
  pharmacokinetics: 'Pharmacokinetics',
  nonclinicalToxicology: 'Nonclinical Toxicology',
  carcinogenesisAndMutagenesisAndImpairmentOfFertility:
    'Carcinogenesis And Mutagenesis And Impairment Of Fertility',
  clinicalStudies: 'Clinical Studies',
  howSupplied: 'How Supplied/Storage & Handling',
  storageAndHandling: 'Storage And Handling',
  instructionsForUse: 'Instructions for Use',
  informationForPatients: 'Information For Patients',
  splMedguide: 'Spl Medguide',
  packageLabelPrincipalDisplayPanel: 'Package Label Principal Display Panel',
  effectiveOn: 'Effective On',
  warningsAndCautions: 'Warnings And Cautions',
  firstProductMarketingStartedOn: 'First Product Marketing Started On',
  lastProductMarketingEndedOn: 'Last Product Marketing Ended On',
  microbiology: 'Microbiology',
  warnings: 'Warnings',
  precautions: 'Precautions',
  generalPrecautions: 'General Precautions',
  nonteratogenicEffects: 'Non Teratogenic Effects',
  nursingMothers: 'Nursing Mothers',
  overdosage: 'Overdosage',
  laboratoryTests: 'Laboratory Tests',
  drugAbuseAndDependence: 'Drug Abuse and Dependence',
  otcActiveIngredient: 'Active Ingredient',
  otcPurpose: 'Purpose',
  otcDoNotUse: 'Do Not Use',
  otcAskDoctor: 'Ask a Doctor if',
  otcAskDoctorPharmacist: 'Ask a Doctor or Pharmacist if',
  otcWhenUsing: 'When Using',
  otcStopUse: 'Stop Use',
  otcPregnancyOrBreastFeeding: 'Pregnancy or Breastfeeding',
  otcKeepOutOfReachOfChildren: 'Keep Out of Reach Of Children',
  otcQuestions: 'Questions',
};
