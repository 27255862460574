import {useMemo} from 'react';
import useUser from './useUser';
import {usePracticeUserOptions} from '@/queries';

export function usePracticeUsers() {
  const {email} = useUser();

  const {data: usersOptionsData, isLoading: isLoadingOptions} =
    usePracticeUserOptions(email);

  const usersOptions = useMemo(
    () => usersOptionsData ?? [],
    [usersOptionsData],
  );

  const practiceUsers = useMemo(
    () =>
      usersOptions?.map((e) => ({
        inviteAccepted: e.inviteAccepted,
        key: e.key,
        text: e.fullName,
        email: e.email,
      })) ?? [],
    [usersOptions],
  );

  return {usersOptions, practiceUsers, isLoadingOptions};
}
