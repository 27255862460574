import clsx from 'clsx';
import React, {FC} from 'react';

interface OpacityOverlayProps {
  showOverlay: boolean;
}

const OpacityOverlay: FC<OpacityOverlayProps> = ({showOverlay}) => (
  <div
    className={clsx(
      'absolute inset-0 z-40 bg-white transition-opacity duration-500',
      showOverlay ? 'opacity-50' : 'opacity-0',
    )}
  />
);

export default OpacityOverlay;
