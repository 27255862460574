import clsx from 'clsx';
import {FC, useState, useCallback, useRef, useEffect, useMemo} from 'react';
import {useCookies} from 'react-cookie';
import AssignResourceModalForm from '../AssignResourceModalForm';
import Share from '../Share';
import ResourceButton, {
  ResourceButtonType,
  NewLinkType,
  PUBMED_RESOURCE_TYPES,
} from './ResourceButton';
import {
  CEVENT_ASSIGN_AND_SHARE,
  CEVENT_BRAND_RSC_CLICK,
  CEVENT_SEND_AND_JOIN,
  DlvAssignAndShare,
  DlvRscClick,
} from '@/constants';
import {AFFORDABILITY_ON_PDP} from '@/constants/flags';
import {PROTOCOL_HOSTNAME, SPLIT_USER_COOKIE} from '@/constants/global';
import {useFlag} from '@/context';
import {ASSIGN_RESOURCE_FORM} from '@/data/assignAndShareForm';
import {useCurrentDrug, useFirePaywall, useRedirectUrl} from '@/hooks';
import {EnhancedContentLinkDto, RecentDocumentDto} from '@/models';
import {useAddEmail, useAssignAndShareResource} from '@/mutations';
import {toSnakeCase, isPptDomain} from '@/utils';
import {
  appendToArrayVariable,
  customEvent,
  getEmailDomain,
  proccessSectionId,
} from '@/utils/gtm';
import {
  getResourceImg,
  isEnhancedContentLink,
  isMostRecentPaResource,
} from '@/utils/resources';

interface ResourceLinkProps {
  resource: EnhancedContentLinkDto | RecentDocumentDto;
  className?: string;
  type?: ResourceButtonType;
  isMostRecent?: boolean;
}

export const getLinkParentSectionFromResource = (
  resource: EnhancedContentLinkDto,
): string => {
  const resourceSectionTag = resource?.tags?.find(
    (tag) => tag?.name === 'ParentSection',
  );

  return resourceSectionTag?.id ?? '';
};

export const getLinkSectionFromResource = (
  resource: EnhancedContentLinkDto,
): string => {
  const resourceSectionTag = resource.tags?.find(
    (tag) => tag.name === 'Section',
  );

  return resourceSectionTag?.id ?? '';
};

export const getLinkTypeFromResource = (
  resource: EnhancedContentLinkDto,
): NewLinkType => {
  const resourceTypeTag = resource.tags?.find((tag) =>
    PUBMED_RESOURCE_TYPES.includes(tag.name),
  );

  return resourceTypeTag?.id as NewLinkType;
};

export const getIconFromResourceType: (
  resourceType: NewLinkType,
) => ResourceButtonType = (resourceType) => {
  const types: {[key in NewLinkType]: ResourceButtonType} = {
    resourceTypePdf: 'download',
    resourceTypeOther: 'redirect',
    resourceTypeVideo: 'video',
    resourceTypeBrandPage: 'redirect',
    resourceTypeImage: 'redirect',
    resourceTypeMedicationGuide: 'redirect',
    resourceTypePrescribingInformation: 'redirect',
    resourceTypeSampleRequestForm: 'redirect',
    resourceTypeCopayCard: 'redirect',
    resourceTypeLetterOfMedicalNeccesity: 'redirect',
    resourceTypeApp: 'redirect',
    resourceTypemedicalNecessityLetter: 'redirect',
    resourceTypeNotProvidedByBrand: 'unknown',
    resourceTypeOnlyForm: 'redirect',
    'Journal Article': 'copy',
    'Classical Article': 'copy',
    Review: 'copy',
    'English Abstract': 'copy',
    'Meta-Analysis': 'copy',
  };
  return types[resourceType];
};

const ResourceLink: FC<ResourceLinkProps> = ({
  resource,
  type = 'redirect',
  className,
  isMostRecent,
}) => {
  const [assignAndShareOpen, setAssignAndShareOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [shareSection, setShareSection] = useState<'visit' | 'share'>('visit');
  const [renderUnderButton, setRenderUnderButton] = useState(false);
  const shouldRenderShare = resource?.title && resource?.href;
  const buttonRef = useRef<HTMLDivElement>(null);
  const {drugName, setId} = useCurrentDrug();
  const buttonId = `${toSnakeCase(resource?.title)}_${type}_button`;
  const {mutateAsync: addEmail, reset} = useAddEmail();
  const {mutateAsync: assignAndShareResource} = useAssignAndShareResource();
  const {redirectUrl} = useRedirectUrl(resource?.href, {
    utm_source: 'PrescriberPoint',
    utm_medium: `${toSnakeCase(drugName)}_referral`,
  });
  const [cookies] = useCookies(['hubspotutk', SPLIT_USER_COOKIE]);
  const firePaywall = useFirePaywall();
  const affordabilityOnPdp = useFlag(AFFORDABILITY_ON_PDP);

  useEffect(() => {
    if (assignAndShareOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [assignAndShareOpen]);

  const defineSharePosition = useCallback(() => {
    const {bottom} = buttonRef?.current?.getBoundingClientRect()!;
    const shareHeight = 320;
    const distanceToViewportBottom = window.innerHeight - bottom;

    if (distanceToViewportBottom > shareHeight) {
      setRenderUnderButton(true);
    } else {
      setRenderUnderButton(false);
    }
  }, []);

  const handleOpenShare = useCallback(() => {
    appendToArrayVariable('links', resource.href);
    if (!!shouldRenderShare) {
      defineSharePosition();
      setIsShareOpen(true);
    }
  }, [shouldRenderShare, defineSharePosition, resource]);

  const linkParentSection = useMemo(
    () =>
      isMostRecent
        ? 'parentSectionPriorAuthorization'
        : isEnhancedContentLink(resource)
          ? getLinkParentSectionFromResource(resource)
          : '',
    [resource, isMostRecent],
  );

  const linkSection = useMemo(
    () =>
      isMostRecent
        ? 'sectionRecentPriorAuthorizationForms'
        : isEnhancedContentLink(resource)
          ? getLinkSectionFromResource(resource)
          : '',
    [resource, isMostRecent],
  );

  const linkType = useMemo(
    () =>
      isEnhancedContentLink(resource)
        ? (getLinkTypeFromResource(resource) as NewLinkType)
        : undefined,
    [resource],
  );

  const fireCustomEvent = useCallback(() => {
    customEvent<DlvRscClick>(CEVENT_BRAND_RSC_CLICK, {
      drugName,
      resourceName: resource.title,
      resourceParentSection: proccessSectionId(linkParentSection),
      resourceSection: proccessSectionId(linkSection),
      resourceImage: getResourceImg(resource.title, drugName),
      redirectUrl: resource.href,
      firePaywall: firePaywall,
      userKey: cookies[SPLIT_USER_COOKIE],
      experimentName: AFFORDABILITY_ON_PDP,
      treatment: affordabilityOnPdp,
    });
  }, [
    drugName,
    resource,
    linkParentSection,
    linkSection,
    cookies,
    firePaywall,
    affordabilityOnPdp,
  ]);

  const handleActionClick = useCallback(() => {
    fireCustomEvent();
    if (!firePaywall) {
      // if the resource is hosted by us, don't show interstitial modal
      if (isPptDomain(resource.href)) {
        window.open(resource.href, '_blank');
      } else {
        setShareSection('visit');
        handleOpenShare();
      }
    }
  }, [handleOpenShare, fireCustomEvent, firePaywall, resource.href]);

  const handleShareClick = useCallback(() => {
    fireCustomEvent();
    if (!firePaywall) {
      setShareSection('share');
      handleOpenShare();
    }
  }, [handleOpenShare, fireCustomEvent, firePaywall]);

  const handleCloseShare = useCallback(() => {
    setIsShareOpen(false);
  }, []);

  const handleSubmitAssignAndShare = useCallback(
    async (shareData: any) => {
      const fromFirstName = shareData.from_name.split(' ')[0];
      const fromLastName = shareData.from_name.split(' ').slice(1).join(' ');
      const toFirstName = shareData.coworker_name.split(' ')[0];
      const toLastName = shareData.coworker_name.split(' ').slice(1).join(' ');
      const emailDomain = getEmailDomain(shareData.from_email);

      const submitData = {
        resourceName: resource.title,
        setId,
        drugName: drugName,
        fromFirstName,
        fromLastName,
        fromEmail: shareData.from_email,
        toFirstName,
        toLastName,
        toEmail: shareData.coworker_email,
        redirectUrl,
      };

      const cEventData = {
        resourceName: resource.title,
        setId,
        drugName,
        emailDomain,
        redirectUrl: redirectUrl.replace(
          `${PROTOCOL_HOSTNAME}/redirect?url=`,
          '',
        ),
        resourceSection: proccessSectionId(linkSection ?? ''),
        resourceParentSection: proccessSectionId(linkParentSection ?? ''),
      };

      // fire DL event when submit button is clicked
      customEvent<DlvAssignAndShare>(CEVENT_ASSIGN_AND_SHARE, {
        ...cEventData,
      });

      // share resource
      try {
        await assignAndShareResource({
          ...submitData,
          hubspotCookie: cookies.hubspotutk,
        });
      } catch (error) {
        console.error(error);
      }

      // create account
      try {
        await addEmail({email: shareData.from_email});
        // fire event when submit button is clicked
        customEvent<DlvAssignAndShare>(CEVENT_SEND_AND_JOIN, {
          ...cEventData,
        });
        reset();
      } catch (error) {
        console.error(error);
      }
    },
    [
      resource,
      linkSection,
      linkParentSection,
      setId,
      drugName,
      redirectUrl,
      cookies.hubspotutk,
      assignAndShareResource,
      addEmail,
      reset,
    ],
  );

  return resource?.href ? (
    <div
      className={clsx(
        'relative border-b border-l-0 border-r-0 border-t-0 border-solid border-neutral-lighter bg-white first:rounded-t-lg last:rounded-b-lg last:border-b-0 hover:bg-neutral-lighter active:bg-neutral-light',
        className,
      )}>
      <ResourceButton
        ref={buttonRef}
        link={resource}
        drugName={drugName}
        disabled={!resource?.href}
        buttonType={
          isMostRecent
            ? 'recent'
            : linkType
              ? getIconFromResourceType(linkType)
              : linkType
        }
        handleActionClick={handleActionClick}
        handleShareClick={handleShareClick}
        id={buttonId}
        linkType={linkType}
        isQr={type === 'qr'}
        date={isMostRecentPaResource(resource) ? resource.updatedDate : ''}
      />
      <Share
        id={`${toSnakeCase(resource.title)}_share`}
        drugName={drugName}
        isShareOpen={isShareOpen}
        handleClose={handleCloseShare}
        renderUnderButton={renderUnderButton}
        shareLink={resource.href}
        resource={resource.title}
        resourceParentSection={linkParentSection}
        resourceSection={linkSection}
        section={shareSection}
      />
      {assignAndShareOpen ? (
        <AssignResourceModalForm
          form={ASSIGN_RESOURCE_FORM}
          title={resource.title}
          titleLabel='Assign to coworker'
          isOpen={assignAndShareOpen}
          onCloseClick={() => setAssignAndShareOpen(false)}
          onSubmit={handleSubmitAssignAndShare}
        />
      ) : null}
    </div>
  ) : null;
};

export default ResourceLink;
