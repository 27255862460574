import {useRouter} from 'next/router';
import {useDrugNameBySetId} from './useDrugNameBySetId';
import {ISpecificPopulationDto, PageDto} from '@/models';
import {usePage, useEnhancedContent} from '@/queries';
import {getSetIdFromPage} from '@/utils/slug';

export interface IComparedDrug {
  setId: string;
  slug: string;
  description?: string;
  drugName: string;
  genericName: string;
  drugClassName: string;
  labelerName: string;
  administration?: string;
  dosing?: string;
  latinShorthand?: string;
  outOfPocketCostWithCopayCard?: string;
  anualCap?: string;
  assitanceExpiration?: string;
  generics?: string;
  adverseReactions?: string;
  moas?: string;
  specificPopulations?: string;
}

function formatSpecificPopulations(
  specificPopulations?: ISpecificPopulationDto[],
) {
  if (!specificPopulations) {
    return '';
  }

  return specificPopulations
    .map(({question, acceptedAnswer}) =>
      question && acceptedAnswer
        ? `<b class="text-neutral-primary">${question}</b><br><br>${acceptedAnswer}<br><br>`
        : '',
    )
    .join('');
}
export function useIndicationDrugs(): {
  drug1: IComparedDrug;
  drug2: IComparedDrug;
} {
  const router = useRouter();
  const {'drug1-vs-drug2': drugs} = router.query;
  let slug1 = '';
  let slug2 = '';

  [slug1, slug2] = drugs!.toString().split('-vs-');

  // get page data
  const {data: page1} = usePage(slug1 as string, {enabled: !!slug1});
  const {data: page2} = usePage(slug2 as string, {enabled: !!slug2});

  // set ids
  const setId1 = getSetIdFromPage(page1 as PageDto);
  const setId2 = getSetIdFromPage(page2 as PageDto);

  // drug names
  const drugName1 = useDrugNameBySetId(setId1);
  const drugName2 = useDrugNameBySetId(setId2);

  // enhanced content
  const {data: enhancedContent1} = useEnhancedContent(setId1);
  const {data: enhancedContent2} = useEnhancedContent(setId2);

  const drugContent1 = enhancedContent1?.enhancedContent;
  const drugContent2 = enhancedContent2?.enhancedContent;

  // Costs and dosages
  const costs1 = drugContent1?.cost;
  const costs2 = drugContent2?.cost;
  const dosages1 = drugContent1?.dosages ? drugContent1.dosages[0] : null;
  const dosages2 = drugContent2?.dosages ? drugContent2.dosages[0] : null;

  const drug1 = {
    setId: setId1,
    slug: slug1,
    description: drugContent1?.brandDescription,
    drugName: drugName1.drugName,
    genericName: drugName1.genericName,
    drugClassName: drugName1.drugClassName,
    labelerName: drugName1.labelerName,
    administration: dosages1?.administration,
    dosing: dosages1?.dosing,
    latinShorthand: dosages1?.latinShorthand,
    outOfPocketCostWithCopayCard: costs1?.coPayOutOfPocket,
    anualCap: costs1?.annualCap,
    assitanceExpiration: costs1?.assistanceExpiration,
    generics: costs1?.genericsAvailable
      ? 'Lower-cost generic available'
      : 'No lower-cost generic available',
    adverseReactions: drugContent1?.adverseReactions,
    moas: drugContent1?.moas,
    specificPopulations: formatSpecificPopulations(
      drugContent1?.specificPopulations,
    ),
  };

  const drug2 = {
    setId: setId2,
    slug: slug2,
    description: drugContent2?.brandDescription,
    drugName: drugName2.drugName,
    genericName: drugName2.genericName,
    drugClassName: drugName2.drugClassName,
    labelerName: drugName2.labelerName,
    administration: dosages2?.administration,
    dosing: dosages2?.dosing,
    latinShorthand: dosages2?.latinShorthand,
    outOfPocketCostWithCopayCard: costs2?.coPayOutOfPocket,
    anualCap: costs2?.annualCap,
    assitanceExpiration: costs2?.assistanceExpiration,
    generics: costs2?.genericsAvailable
      ? 'Lower-cost generic available'
      : 'No lower-cost generic available',
    adverseReactions: drugContent2?.adverseReactions,
    moas: drugContent2?.moas,
    specificPopulations: formatSpecificPopulations(
      drugContent2?.specificPopulations,
    ),
  };

  return {drug1, drug2};
}
