import {useSearchParams} from 'next/navigation';

export function useNhHideQueryParam() {
  const searchParams = useSearchParams();
  const nhParam = searchParams?.get('nh');

  const shouldHide = nhParam === '1';

  return {shouldHide};
}
