import {useMutation} from '@tanstack/react-query';
import {create} from '@/services/api';

export interface ICreateCaseEdgesRequest {
  caseId: string;
  assignedTo: string;
  createdBy: string;
  brandId: string;
  locationId: string;
}

const EDGES: {
  resourceName: string;
  paramName: keyof ICreateCaseEdgesRequest;
}[] = [
  {
    resourceName: 'IsManagedBy',
    paramName: 'assignedTo',
  },
  {
    resourceName: 'IsOpenedBy',
    paramName: 'createdBy',
  },
  {
    resourceName: 'IsRelatedTo',
    paramName: 'brandId',
  },
  {
    resourceName: 'IsServicedAt',
    paramName: 'locationId',
  },
];

export async function createCaseEdges(
  params: ICreateCaseEdgesRequest,
): Promise<void> {
  const promises: Promise<unknown>[] = [];

  EDGES.forEach((e) => {
    if (params[e.paramName]) {
      promises.push(
        create(
          e.resourceName,
          {
            params: {
              fromId: params.caseId,
              toId: params[e.paramName],
            },
          },
          {},
          'graph',
          true,
        ),
      );
    }
  });

  await Promise.all(promises);
  return;
}

export function useCreateCaseEdges(options = {}) {
  return useMutation({mutationFn: createCaseEdges, ...options});
}
