import {FC} from 'react';
import {Highlight as OriginalHighlight} from 'react-instantsearch';

interface HighlightProps {
  attribute: string;
  hit: any;
}

const Highlight: FC<HighlightProps> = ({attribute, hit}) => (
  <OriginalHighlight attribute={attribute} hit={hit} />
);

export const CustomHighlight = Highlight;
