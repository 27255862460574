import {Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC} from 'react';
import MultiDrugSearchbarListInput from './components/MultiDrugSearchbarListInput';
import Results from './components/Results';
import SearchbarListInput from './components/SearchbarListInput';
import SelectedSearchResults from './components/SelectedSearchResults';
import {DDI_MULTI_DRUGS} from '@/constants/flags';
import {useFlag} from '@/context';
import {useDrugInteractionsContext} from '@/context/DrugInteractionsContext';
import {useUserAgentContext} from '@/context/UserAgentContext';
import {useWebkitOverScrollFix} from '@/hooks/useWebkitOverScrollFix';
import {WrapperInstantSearch} from '@/services/WrapperInstantSearch';

interface SearchbarListProps {}
const SearchbarList: FC<SearchbarListProps> = () => {
  const {isMobile, isTablet} = useUserAgentContext();
  const {setIsOpen} = useDrugInteractionsContext();

  const ddiMultiDrugsEnabled = useFlag(DDI_MULTI_DRUGS);

  useWebkitOverScrollFix(isMobile || isTablet);

  const handleClose = () => {
    window.scrollTo({top: 0});
    setIsOpen(false);
  };

  return (
    <WrapperInstantSearch hitsPerPage={5} analitycs={false}>
      <div
        className={clsx('flex px-4 pb-4', {
          'flex-col': ddiMultiDrugsEnabled,
        })}>
        {ddiMultiDrugsEnabled ? (
          <MultiDrugSearchbarListInput />
        ) : (
          <SearchbarListInput />
        )}
        {ddiMultiDrugsEnabled ? (
          <SelectedSearchResults />
        ) : isMobile || isTablet ? (
          <button
            className="ml-2 border-0 bg-transparent"
            onClick={handleClose}
            id="cancel_potencial_interactions">
            <Text variant="primary" className="hover:underline">
              Cancel
            </Text>
          </button>
        ) : null}
      </div>
      {!ddiMultiDrugsEnabled && <Results />}
    </WrapperInstantSearch>
  );
};

export default SearchbarList;
