import {useQuery} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {RecentDocumentsDto} from '@/models';
import {read} from '@/services/api';

export async function getRecentStateUniformForms(): Promise<RecentDocumentsDto | null> {
  return await read<RecentDocumentsDto>(
    'Recent',
    {
      params: {
        type: 'states',
      },
      parentName: 'Document',
    },
    {},
    'profile',
    false,
    1,
    404,
  );
}

export function useRecentStateUniformForms() {
  return useQuery<RecentDocumentsDto | null>({
    queryKey: ['recentStateUniformForms'],
    queryFn: () => getRecentStateUniformForms(),
  });
}

export function getRecentStateUniformFormsPrefetchQuery(): IPrefetchedQuery {
  return {
    queryKey: ['recentStateUniformForms'],
    getFn: async () => await getRecentStateUniformForms(),
  };
}
