import {IBreadcrumbItem} from '@prescriberpoint/ui';
import {useMemo} from 'react';
import {useUserAgentContext} from '@/context';

export function useBreadcrumbItems(
  drugName: string,
  defaultCrumbs: IBreadcrumbItem[],
) {
  const {isMobileOrTablet} = useUserAgentContext();
  const breadcrumbs: IBreadcrumbItem[] = useMemo(() => {
    const crumbs = isMobileOrTablet ? defaultCrumbs.slice(1) : defaultCrumbs;
    return drugName ? crumbs : [];
  }, [isMobileOrTablet, drugName, defaultCrumbs]);

  return breadcrumbs;
}
