import {useQuery, QueryClient} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {NEW_LABELS_SERVICE} from '@/constants/flags';
import {useFlag} from '@/context';
import {Metadata, ProductDto} from '@/models';
import {read, SERVICE_DEFAULT_VERSIONS} from '@/services/api';
import featureFlags from '@/services/featureFlags';

interface ProductDto2 extends Omit<ProductDto, 'metadata'> {
  slugId: string;
  metadata: Metadata | Metadata[];
}

export async function getLabel(
  setId: string,
  newLabelsService: boolean = false,
  bypassServer: boolean = false,
): Promise<ProductDto | null> {
  let data = null;

  if (!setId) return data;

  try {
    data = newLabelsService
      ? await read<ProductDto2>('labels', {resourceId: setId}, {}, 'nextApi')
      : await read<ProductDto>(
          `${setId}/api.json`,
          {},
          {},
          'drugLabelsStorage',
          false,
          SERVICE_DEFAULT_VERSIONS.drugLabelsStorage,
          undefined,
          bypassServer,
        );

    // if the data type returns as an array, we need to convert it to an object to match the contract for a product
    // this is temporary until the new labels container is fully updated.
    if (newLabelsService && data && Array.isArray(data.metadata)) {
      data = {...data, metadata: (data.metadata as Metadata[])[0]};
    }
  } catch (e) {
    console.error(e);
  }

  return data as ProductDto;
}

export function useLabel(setId: string, options = {}) {
  const newLabelsService = useFlag(NEW_LABELS_SERVICE);
  return useQuery<ProductDto | null>({
    queryKey: ['label', setId],
    queryFn: () => getLabel(setId, newLabelsService),
    ...options,
  });
}

export async function getLabelPrefetchQuery(
  setId: string,
  bypassServer: boolean = false,
): Promise<IPrefetchedQuery> {
  const flag = await featureFlags.getFlag(NEW_LABELS_SERVICE);

  return {
    queryKey: ['label', setId, bypassServer],
    getFn: async () => await getLabel(setId, flag === 'on', bypassServer),
  };
}

export async function fetchQueryLabel(setId: string, queryClient: QueryClient) {
  const flag = await featureFlags.getFlag(NEW_LABELS_SERVICE);

  return await queryClient.fetchQuery({
    queryKey: ['label', setId],
    queryFn: () => getLabel(setId, flag === 'on'),
  });
}
