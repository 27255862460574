import {Icon, Text} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {FC} from 'react';
import {DDI_BUTTON_TEXT_EXPERIMENT} from '@/constants/flags';
import {useFlag, useUserAgentContext} from '@/context';
import {useCurrentDrug} from '@/hooks';

export interface IDrugInteractionsCardButtonProps {
  id: string;
  testId?: string;
  quantity?: number;
  className?: ClassValue;
  onClick?: () => void;
  knownDrugs?: boolean;
}

const DrugInteractionsCardButton: FC<IDrugInteractionsCardButtonProps> = ({
  id,
  testId,
  quantity,
  className,
  onClick = () => {},
  knownDrugs = true,
}) => {
  const {isMobileOrTablet} = useUserAgentContext();
  const {drugName} = useCurrentDrug();
  const showButtonTextExperiment = useFlag(DDI_BUTTON_TEXT_EXPERIMENT);

  return (
    <button
      data-testid={testId}
      onClick={onClick}
      id={id}
      className={clsx([
        className,
        'flex w-full flex-col justify-between space-y-3.5 rounded-2xl bg-theme-tertiary p-4 hover:bg-theme-primary focus:bg-theme-primary md:space-y-[25px] md:p-5',
      ])}>
      {showButtonTextExperiment ? (
        <div className="flex flex-1 text-left items-center">
          <Text
            variant="white"
            className="text-left"
            as={isMobileOrTablet ? 'body-md' : 'title-lg'}
            weight="bold">
            See All Drug Interactions by Severity
          </Text>
        </div>
      ) : (
        <div className="flex flex-col space-y-1">
          <Text
            className="text-left"
            variant="white"
            as={isMobileOrTablet ? 'headline-md' : 'headline-lg'}>
            {`See ${quantity && quantity >= 50 ? '50+ ' : quantity}`}
          </Text>
          <Text
            variant="white"
            className="text-left"
            as={isMobileOrTablet ? 'body-md' : 'title-lg'}
            weight="bold">
            known drug interactions <br className="block" />
            for {` ${drugName}`}
          </Text>
        </div>
      )}

      <div className="flex h-10 w-full items-center justify-center space-x-2 rounded-lg bg-white/20 px-4 py-2">
        <Text
          weight="semibold"
          variant="white"
          as="body-sm"
          casing="title"
          className="tracking-wider">
          {knownDrugs ? 'See Known Interactions' : 'Find Interactions Now'}
        </Text>
        <Icon className="mt-0.5 w-3.5 text-white" name="OpenLink" />
      </div>
    </button>
  );
};

export default DrugInteractionsCardButton;
