import {ViewVariant} from '@prescriberpoint/ui';
import {useMemo, useState} from 'react';

function useReducedList<T>(
  list: T[],
  FEW_QUANTITY: number,
  MANY_QUANTITY: number,
) {
  const [view, setView] = useState<ViewVariant>('few');

  const items = useMemo(() => {
    if (view === 'all') {
      return list;
    }
    return list?.slice(0, view === 'many' ? MANY_QUANTITY : FEW_QUANTITY);
  }, [FEW_QUANTITY, MANY_QUANTITY, list, view]);

  return {items, view, setView};
}

export default useReducedList;
