import {Text, Button} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, useRef, useCallback} from 'react';
import {useSearchBox} from 'react-instantsearch';
import {useEffectOnce} from 'usehooks-ts';
import {useUserAgentContext} from '@/context';

interface SearcherHeaderProps {
  onBackClick?: () => void;
}

const SearcherHeader: FC<SearcherHeaderProps> = ({onBackClick}) => {
  const {refine, query: currentRefinement} = useSearchBox();
  const inputRef = useRef<HTMLInputElement>(null);
  const {isMobileOrTablet} = useUserAgentContext();

  useEffectOnce(() => {
    inputRef.current?.focus();
  });

  const handleBack = useCallback(() => {
    refine('');
    if (onBackClick) {
      onBackClick();
    }
  }, [refine, onBackClick]);

  return (
    <div className="relative flex flex-col items-center space-y-4">
      <div className="flex w-full items-center justify-between">
        <Button
          id="search_brands_back"
          variant="text"
          className="text-theme-primary hover:text-theme-dark hover:underline"
          onClick={handleBack}
          iconLeftName="ChevronLeft"
          showIconLeft={isMobileOrTablet}>
          Close
        </Button>
        <div>
          <Text as="title-md">Select drug</Text>
        </div>
        <div className="w-9" />
      </div>
      <input
        data-clarity-unmask="true"
        className={clsx(
          'placeholder:text-grey-neutral-secondary flex w-full bg-white px-2 py-3.5 text-sm font-normal text-neutral-dark outline-none md:px-2.5',
          'rounded-sm border border-solid border-neutral-primary focus:border-theme-primary',
        )}
        ref={inputRef}
        placeholder="Enter drug name"
        type="search"
        value={currentRefinement}
        onChange={(event) => refine(event.currentTarget.value)}
        id="search_single_brand_input"
        data-hj-allow
        autoComplete="off"
      />
    </div>
  );
};

export default SearcherHeader;
