import {IPageSection} from '@/interfaces';

export const REQUEST_SAMPLES_DATA: IPageSection = {
  title: 'Request Samples', // Request {brandName} samples',
  description: {
    standard:
      'Samples are often requested while awaiting commerical insurance approval.',
    enhanced:
      'Samples are often requested while awaiting commerical insurance approval.',
  },

  sections: [
    {
      sectionId: '',
      title: '',
      standard: true,
      enhanced: true,
      description: {},
      answer: [
        {
          title: 'Is my patient eligible for {brandName} samples?',
          items: [
            'Your rep will communicate with you how to receive samples, when you can receive samples, the amount and more.',
          ],
        },
        {
          title: 'How do I find out who my {brandName} rep is?',
          items: [
            'Not sure who your local {brandName} pharma rep is? Reach out to {labelerName} and they can help you identify your rep.',
          ],
        },
      ],
    },
  ],
};
