import {Text} from '@prescriberpoint/ui';
import {FC} from 'react';

const OTC: FC = () => (
  <div className="flex px-3 py-2 items-center justify-center rounded-[64px] border border-solid border-blue-50 bg-blue-50">
    <Text as="body-sm" weight="bold" className="text-blue-800-2 tracking-wider">
      OTC
    </Text>
  </div>
);

export default OTC;
