import {Button} from '@prescriberpoint/ui';
import {FC} from 'react';

export interface RedirectLinkProps {
  id: string;
  href: string;
  text: string;
  name?: string;
  primary?: boolean;
}

const RedirectLink: FC<RedirectLinkProps> = ({
  id,
  href,
  text,
  name,
  primary = false,
}) => (
  <Button
    id={id}
    href={href}
    name={name ?? text}
    variant={primary ? 'primary' : 'secondary'}
    asLink>
    {text}
  </Button>
);

export default RedirectLink;
