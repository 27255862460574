import {CardButtonPdpCta} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, useMemo} from 'react';
import {
  CEVENT_DDI_CHECK,
  DlvDDICheck,
  CEVENT_SIDEPANEL_CTA,
  DlvSidePanelCTA,
  CEVENT_SAMPLES_ELIGIBILITY_CTA,
} from '@/constants';
import {
  COVERAGE_RESTRICTIONS_TOOL_ID,
  CTA_SECTIONS_V2,
  CONTACT_MANUFACTURER_ID,
  SAMPLES_AVAILABLE_ID,
} from '@/constants/ctaServices';
import * as FLAGS from '@/constants/flags';
import {PROTOCOL_HOSTNAME} from '@/constants/global';
import {FULL_SECTION_IDS} from '@/constants/sectionIDs';
import {
  useFlag,
  useDrugInteractionsContext,
  useUserAgentContext,
} from '@/context';
import {
  useCurrentDrug,
  useDlvDrugInfo,
  useCurrentSlug,
  useEnhanced,
  useIsOtc,
  useIsCoverageToolAvailable,
} from '@/hooks';
import DDIModal from '@/modules/ProductDetailPage/DrugInteractions/DDIModal';
import {customEvent} from '@/utils/gtm';

interface ICTASection {
  sectionId: string;
  id: string;
  title: string;
}

interface ICTAMenuV2Props {
  mobileFixedVersion?: boolean;
}

const CTAMenuV2: FC<ICTAMenuV2Props> = ({mobileFixedVersion = false}) => {
  const {isTablet} = useUserAgentContext();
  const {setId, slug} = useCurrentSlug();
  const {genericName} = useCurrentDrug();
  const {setIsOpen} = useDrugInteractionsContext();
  const {samplesAvailable} = useEnhanced(setId);
  const {drugName, drugSpecialties, pharmaCompany} = useDlvDrugInfo();
  const {showCoverageOnPdp} = useIsCoverageToolAvailable();
  const isOtc = useIsOtc();

  const showDDI = useFlag(FLAGS.DDI_TOOL);
  const showButtonCMOrRS = useFlag(FLAGS.SHOW_CM_OR_RS_BUTTON);
  const samplesURL = PROTOCOL_HOSTNAME + '/therapies/' + slug + '/samples';

  const handleClickCheckDrugInteraction = () => {
    setIsOpen(true);
    customEvent<DlvDDICheck>(CEVENT_DDI_CHECK, {
      drugName,
      pharmaCompany,
      drugSpecialties,
    });
  };

  const handleCTAClick = (ctaSection: ICTASection) => {
    customEvent<DlvSidePanelCTA>(CEVENT_SIDEPANEL_CTA, {
      buttonName: ctaSection.title,
    });

    if (ctaSection.id === COVERAGE_RESTRICTIONS_TOOL_ID && showCoverageOnPdp) {
      document.getElementById(`ss_pivot_coverage`)?.click();
    }

    if (ctaSection.id === SAMPLES_AVAILABLE_ID && samplesAvailable) {
      customEvent(CEVENT_SAMPLES_ELIGIBILITY_CTA);
      window.location.href = samplesURL;
    }

    if (mobileFixedVersion) {
      document.getElementById(`${ctaSection.id}`)?.click();
    }
  };

  const ctaSections = useMemo(
    () =>
      CTA_SECTIONS_V2.filter(({sectionId}) => {
        switch (sectionId) {
          case FULL_SECTION_IDS.requestSamples:
            return false;
          case FULL_SECTION_IDS.drugLabel:
            return showButtonCMOrRS;
          case FULL_SECTION_IDS.dosingAndAdmiministration:
            return showCoverageOnPdp;
          default:
            return true;
        }
      }).map((section) => {
        if (
          showCoverageOnPdp &&
          section.sectionId === FULL_SECTION_IDS.dosingAndAdmiministration
        ) {
          return {
            ...section,
            id: COVERAGE_RESTRICTIONS_TOOL_ID,
          };
        }

        if (section.id === CONTACT_MANUFACTURER_ID && samplesAvailable) {
          return {
            ...section,
            id: SAMPLES_AVAILABLE_ID,
            title: 'Request Samples',
          };
        }

        return section;
      }),
    [showCoverageOnPdp, samplesAvailable, showButtonCMOrRS],
  );

  const cardButtonClasses = mobileFixedVersion
    ? 'mb-0 h-[46px]'
    : 'sm-only:mb-3 md:mb-6 h-[90px] sm:h-auto';
  return (
    <div
      className={clsx(
        'flex flex-row sm:space-x-3',
        [isOtc ? 'xs-only:grid-cols-1' : 'xs-only:grid-cols-2'],
        [
          mobileFixedVersion
            ? 'xs-only:space-x-2'
            : 'xs-only:grid-flow-cols xs-only:grid xs-only:gap-3',
        ],
      )}>
      {!isOtc &&
        ctaSections.map((cta, idx) => (
          <CardButtonPdpCta
            id={mobileFixedVersion ? 'fixed-' + cta.id : cta.id}
            key={`cta-v2-${cta.sectionId}-${idx}`}
            text={cta.title}
            iconName={cta.iconName}
            onClick={() => handleCTAClick(cta)}
            vertical={isTablet}
            className={cardButtonClasses}
            mobileFixedVersion={mobileFixedVersion}
          />
        ))}

      {showDDI ? (
        <>
          <CardButtonPdpCta
            id='check-drug-interactions'
            key={`cta-v2-drug-interactions`}
            text='Check Drug Interactions'
            iconName='MagnifyingGlass'
            onClick={handleClickCheckDrugInteraction}
            vertical={isTablet}
            className={cardButtonClasses}
            mobileFixedVersion={mobileFixedVersion}
          />
          <DDIModal
            setId={setId}
            drugName={drugName}
            genericName={genericName}
          />
        </>
      ) : null}
    </div>
  );
};

export default CTAMenuV2;
