import {LABEL_TITLES} from '@/constants/labelTitles';
import {LabelTitles} from '@/models';

export const getTitleFromLabel = (label: keyof LabelTitles) =>
  LABEL_TITLES?.[label] ?? label;

export const getSectionTitleFromId = (id: string) => {
  let label = id.replaceAll('_', ' ');
  return `${label[0].toUpperCase()}${label.slice(1)}`;
};

export const isOTC = (type: string) =>
  type === 'HUMAN OTC DRUG LABEL' || type === 'HUMAN OTC DRUG';
