import {Icon, Text} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {capitalize} from '@/utils';

interface ChipProps {
  label: string;
  className?: ClassValue;
  iconName: string;
  id?: string;
  labelClassName?: ClassValue;
  iconClassName?: ClassValue;
  onClick?: () => void;
}

export default function Chip({
  className,
  iconName,
  label,
  iconClassName,
  onClick,
}: ChipProps) {
  return (
    <button
      className={clsx(
        'flex h-fit min-h-8 w-fit items-center justify-center gap-x-2 rounded-lg border border-solid border-neutral-lighter bg-neutral-lighter pl-3 pr-2 outline-none',
        className,
      )}
      onClick={onClick}
      id={`${label}-chip`}>
      <Text as='body-sm' weight='semibold'>
        {capitalize(label)}
      </Text>
      <Icon
        name={iconName}
        className={clsx(
          'inline-flex w-3 items-center text-neutral-primary',
          iconClassName,
        )}
      />
    </button>
  );
}
