import {Icon, Link, Text} from '@prescriberpoint/ui';
import {FC} from 'react';
import {IResult} from '@/interfaces';
import {MultiInteraction} from '@/models';

interface InteractionsProps {
  drugs: IResult[];
  interactions: MultiInteraction[];
}

const Interactions: FC<InteractionsProps> = ({drugs, interactions}) => (
  <div className="px-6 mb-4">
    {interactions && interactions.length > 0 ? (
      interactions.map(
        ({
          product_concept_id,
          affected_product_concept_id,
          description,
          extended_description,
          product_ingredient: {name: product_ingredient_name},
          affected_product_ingredient: {name: affected_product_ingredient_name},
          product_concept_setId,
          affected_product_concept_setId,
        }) => {
          const productDrugResult = drugs.find(
            ({set_id}) => set_id === product_concept_setId,
          );

          const affectedDrugResultConcept = drugs.find(
            ({set_id}) => set_id === affected_product_concept_setId,
          );

          return (
            <div key={`${product_concept_id}-${affected_product_concept_id}`}>
              <div className="rounded-xl p-4 bg-blue-50 mb-3">
                <div className="flex space-x-2">
                  <Text
                    variant="base"
                    className="capitalize text-theme-primary font-bold">
                    {product_ingredient_name}
                  </Text>
                  <Link
                    href={`/therapies/${productDrugResult?.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    label={productDrugResult?.label_title ?? ''}
                  />
                </div>
                <div className="box-content h-5 w-4 rotate-90 py-3">
                  <Icon
                    name="ArrowSwap"
                    className="h-full w-full text-blue-600"
                  />
                </div>
                <div className="flex space-x-2">
                  <Text
                    variant="base"
                    className="capitalize text-theme-primary font-bold">
                    {affected_product_ingredient_name}
                  </Text>
                  <Link
                    href={`/therapies/${affectedDrugResultConcept?.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    label={affectedDrugResultConcept?.label_title ?? ''}
                  />
                </div>
                <div className="flex flex-col space-y-2 mt-1">
                  <ul className="space-y-2 text-neutral-primary p-0">
                    <li className="flex flex-col space-y-1">
                      <Text weight="regular" size="xs">
                        {description}
                      </Text>
                      <Text weight="regular" size="xs">
                        {extended_description}
                      </Text>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          );
        },
      )
    ) : (
      <div className="mb-3">
        <Text weight="regular" size="md">
          No interactions are found between the drugs
        </Text>
      </div>
    )}
    <DDIDisclaimer />
  </div>
);

const DDIDisclaimer: FC = () => (
  <div className="rounded-xl p-1 px-3 py-2 bg-error-fill flex items-center justify-center">
    <Icon name="Warning" className="h-5 w-5 mr-2 text-error" />
    <Text weight="medium" size="xs" className="flex-1">
      If no interactions are found between drugs, it does not necessarily mean
      that no interactions exist.
    </Text>
  </div>
);
export default Interactions;
