import {useQuery} from '@tanstack/react-query';
import useUser from '@/hooks/useUser';
import {PersonDto} from '@/models/personDto';
import {read} from '@/services/api';

export async function getCurrentPerson(): Promise<PersonDto | null> {
  let data: PersonDto | null = null;

  try {
    data = await read<PersonDto>(
      'CurrentPerson',
      {
        parentName: 'User',
      },
      {},
      'profile',
      true,
    );
  } catch (error) {
    console.error(error);
  }

  return data;
}

export function useCurrentPerson(options = {}) {
  const {logged: isLogged} = useUser();
  return useQuery<PersonDto | null>({
    queryKey: ['currentUser'],
    queryFn: async () => await getCurrentPerson(),
    ...options,
    enabled: isLogged,
    staleTime: 0,
  });
}
