import {
  Button,
  Text,
  BottomSheet,
  customEvent,
  IconButton,
  Icon,
} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, useMemo, useRef} from 'react';
import {useToggle, useOnClickOutside} from 'usehooks-ts';
import {CEVENT_CHECK_DOSAGES_AND_STRENGTHS} from '@/constants';
import {useUserAgentContext} from '@/context';
import {useCurrentSlug} from '@/hooks';
import {useLabel} from '@/queries';
import {extractNumber} from '@/utils';

const FILTER_DOSAGES_SBD = 'SBD';

const Dosages: FC = () => {
  const [toggledDosages, toggleDosages] = useToggle(false);
  const {setId} = useCurrentSlug();
  const {data: product} = useLabel(setId);
  const containerRef = useRef(null);

  const clickOutsideDesktopHandler = () => {
    if (!isMobile && toggledDosages) {
      toggleDosages();
    }
  };

  useOnClickOutside(containerRef, clickOutsideDesktopHandler);

  const dosages = useMemo(() => {
    if (product?.prescribableNames?.length) {
      return product.prescribableNames?.sort(function (a, b) {
        const numA = extractNumber(a);
        const numB = extractNumber(b);
        return numA !== numB ? numA - numB : a.localeCompare(b);
      });
    }

    // Fallback to product.names if prescribableNames is not available or is empty
    return (
      product?.names
        ?.filter((name) => name.tty === FILTER_DOSAGES_SBD)
        .map((name) => name.name) ?? []
    );
  }, [product?.names, product?.prescribableNames]);

  const firstDosage = useMemo(() => dosages?.[0], [dosages]);
  const existMoreThanOneDosage = useMemo(() => dosages.length > 1, [dosages]);
  const {isMobile} = useUserAgentContext();
  const handleOnClickMoreDosages = () => {
    customEvent(CEVENT_CHECK_DOSAGES_AND_STRENGTHS);
    toggleDosages();
  };

  if (dosages.length === 0) {
    return;
  }

  return (
    <div
      className="flex flex-wrap items-center mt-0.5 xs-only:w-full"
      ref={containerRef}>
      {firstDosage ? (
        <Text as="body-sm" weight="bold" className="mr-3 truncate">
          {firstDosage}
        </Text>
      ) : null}
      {existMoreThanOneDosage ? (
        <div className="relative shrink-0">
          <Button
            id="dosage-forms-strengths"
            testId="dosage-toggle"
            className="rounded-full"
            onClick={handleOnClickMoreDosages}
            variant="secondary">
            DOSAGE FORMS & STRENGTHS
          </Button>

          {toggledDosages ? (
            <>
              {isMobile ? (
                <BottomSheet
                  isOpen={toggledDosages}
                  detent="content-height"
                  onClose={toggleDosages}
                  containerClassName="pb-6 px-0 pt-0"
                  headerClassName="pb-1"
                  header={
                    <IconButton
                      id="close_button"
                      onClick={toggleDosages}
                      className="h-93 justify-start align-middle hover:border-0 hover:!bg-transparent"
                      icon={
                        <Icon
                          name="Cancel"
                          className="flex h-5 w-5  text-neutral-primary"
                        />
                      }
                    />
                  }>
                  {dosages.map((dosage: string, idx: number) => (
                    <Text
                      key={`dosage-list-${dosage}`}
                      className={clsx('block py-3.5 px-6 w-full', {
                        'border-x-0 border-y-0 !border-b border-solid border-neutral-quaternary-alt':
                          idx < dosages.length - 1,
                      })}
                      as="body-sm"
                      weight="bold"
                      casing="title">
                      {dosage}
                    </Text>
                  ))}
                </BottomSheet>
              ) : (
                <div className="shadow-dosage-select absolute right-0 top-[42px] bg-white z-40 border border-solid border-neutral-quaternary-alt rounded-lg w-max">
                  {dosages.slice(1).map((dosage: string) => (
                    <Text
                      key={'dosage-list-' + dosage}
                      className="block py-3.5 px-6 w-full border-x-0 border-y-0 border-b border-solid border-neutral-quaternary-alt"
                      as="body-sm"
                      weight="bold"
                      casing="title">
                      {dosage}
                    </Text>
                  ))}
                </div>
              )}
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Dosages;
