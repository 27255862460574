import {useMutation} from '@tanstack/react-query';
import {create} from '@/services/api';

interface IAssignAndShareResourceRequest {
  resourceName: string;
  setId: string;
  drugName: string;
  fromFirstName: string;
  fromLastName: string;
  fromEmail: string;
  toFirstName: string;
  toLastName: string;
  toEmail: string;
  redirectUrl: string;
  hubspotCookie: string;
}

export async function createAssignAndShareResource(
  params: IAssignAndShareResourceRequest,
) {
  const data = await create(
    'Relay',
    {
      resourceId: 'AssignAndShare',
      params,
    },
    {},
    'profile',
  );
  return data;
}

export function useAssignAndShareResource(options = {}) {
  return useMutation({mutationFn: createAssignAndShareResource, ...options});
}
