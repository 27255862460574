import {createContext, FC, ReactNode, useContext, useState} from 'react';

interface DrugInteractionsProviderProps {
  children: ReactNode;
}

interface IDrugInteractionContext {
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
}

const defaultState: IDrugInteractionContext = {
  isOpen: false,
  setIsOpen: () => {},
};

const DrugInteractionsContext = createContext(defaultState);

export const useDrugInteractionsContext = () =>
  useContext(DrugInteractionsContext);

export const DrugInteractionsProvider: FC<DrugInteractionsProviderProps> = (
  props,
) => {
  const [isOpen, setIsOpen] = useState(false);

  const values = {
    isOpen,
    setIsOpen,
  };

  return <DrugInteractionsContext.Provider value={values} {...props} />;
};
