import {useMutation} from '@tanstack/react-query';
import {LoginDto} from './useLogin';
import {update} from '@/services/api';

export interface IResetPassword {
  email: string;
  password: string;
  confirmPassword: string;
  token: string;
}

async function resetPassword(params: IResetPassword) {
  return await update<LoginDto>(
    'UpdatePassword',
    {
      parentName: 'Auth',
      params,
    },
    {method: 'PUT'},
    'profile',
  );
}

export function useResetPassword() {
  return useMutation({mutationFn: resetPassword});
}
