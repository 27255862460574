import {useState} from 'react';
import SearcherHeader from './SearcherHeader';
import SearcherResults from './SearcherResults';
import {useUserAgentContext} from '@/context';
import {useWebkitOverScrollFix} from '@/hooks';
import {WrapperInstantSearch} from '@/services/WrapperInstantSearch';

interface SearcherBrandsProps {
  filters?: string;
  onContinue: () => void;
}

const SearcherBrands = ({filters, onContinue}: SearcherBrandsProps) => {
  const {isMobileOrTablet} = useUserAgentContext();
  const [query, setQuery] = useState('');

  useWebkitOverScrollFix(isMobileOrTablet);

  return (
    <div className="absolute inset-0 z-20 flex w-full flex-col md:h-auto bg-white md:relative">
      <WrapperInstantSearch
        hitsPerPage={500}
        analitycs={false}
        filters={filters}>
        <SearcherHeader
          query={query}
          setQuery={setQuery}
          onContinue={onContinue}
        />
        <SearcherResults setQuery={setQuery} />
      </WrapperInstantSearch>
    </div>
  );
};

export default SearcherBrands;
