export const sponsors = [
  {
    src: 'https://cdn.prescriberpoint.com/assets/images/logo.svg',
    subtitle: 'ixekizumab',
    title: 'Taltz',
    slug: 'taltz-ac96658',
  },
  {
    src: 'https://cdn.prescriberpoint.com/assets/images/logo.svg',
    subtitle: 'galcanezumab',
    title: 'Emgality',
    slug: 'emgality-33a147b',
  },
  {
    src: 'https://cdn.prescriberpoint.com/assets/images/logo.svg',
    subtitle: 'eptinezumab',
    title: 'Vyepti',
    slug: 'vyepti-7906586',
  },
];
