import {useInputFocus, Icon} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, useRef} from 'react';
import {useSearchBox} from 'react-instantsearch';
import {useOnClickOutside} from 'usehooks-ts';
import {CustomHits} from './CustomHits';

interface SearchBoxProps {
  placeholder: string;
  handleSearch: () => void;
}

const SearchBox: FC<SearchBoxProps> = ({placeholder, handleSearch}) => {
  const {refine, query: currentRefinement} = useSearchBox();
  const ref = useRef<HTMLDivElement>(null);
  const {isFocused, setIsFocused} = useInputFocus(ref);
  useOnClickOutside(ref, () => setIsFocused(false));

  return (
    <div
      ref={ref}
      className={clsx([
        'relative flex h-10 items-center justify-between space-x-2 rounded-sm border border-solid px-4',
        isFocused ? 'border-theme-primary' : 'border-neutral-quaternary',
      ])}>
      {!currentRefinement ? (
        <Icon name="Search" className="flex w-4 text-theme-primary" />
      ) : null}
      <input
        className="flex flex-1 border-none bg-white text-sm text-neutral-dark outline-none placeholder:text-neutral-dark"
        placeholder={placeholder}
        type="search"
        value={currentRefinement}
        onChange={(event) => refine(event.currentTarget.value)}
        data-clarity-unmask="true"
      />
      {isFocused ? (
        <div className="cursor-pointer" onClick={() => refine('')}>
          <Icon name="Cancel" className="flex w-3 text-neutral-dark" />
        </div>
      ) : null}
      {isFocused &&
        (currentRefinement ? (
          <div className="absolute -left-[9px] top-12 z-max min-h-[300px] w-full bg-white">
            <CustomHits onHitClick={handleSearch} />
          </div>
        ) : null)}
    </div>
  );
};

export const CustomSearchBox = SearchBox;
