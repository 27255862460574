import {useMutation} from '@tanstack/react-query';
import {HUBSPOT_SIGNUP_DRUG_UPDATE_FORM_ID} from '@/constants/global';
import {create} from '@/services/api';

export interface ISignUpForUpdates {
  email: string;
  hubspotCookie: string;
}

async function addSignUpForUpdates(params: ISignUpForUpdates) {
  return await create(
    'Relay',
    {
      params: {
        formId: HUBSPOT_SIGNUP_DRUG_UPDATE_FORM_ID,
        ...params,
      },
    },
    {},
    'profile',
  );
}

export function useSignUpForUpdates() {
  return useMutation({mutationFn: addSignUpForUpdates});
}
