export const RESOURCE_TO_COMPLETE = [
  'Coverage Authorization request',
  'Coverage Authorization appeals',
  'Letter of Medical Necessity',
  'Taltz Together Enrollment Form - Adult Dermatology',
  'Taltz Together Enrollment Form - Pediatric Dermatology',
  'Taltz Together Enrollment Form - Adult Rheumatology',
  'Online sample form',
];

export const RESOURCE_TO_INFO = ['Reimbursement Information'];
