import {useQuery} from '@tanstack/react-query';
import {MultiInteraction} from '@/models';
import {read} from '@/services/api';

export async function getMultiDrugsInteractions(
  drugs: string[],
): Promise<MultiInteraction[] | null> {
  let data = null;

  if (!drugs || drugs?.length === 0) return data;

  try {
    return await read<MultiInteraction[]>(
      'multiInteractions',
      {
        parentName: 'ddi',
        params: {
          drugs: drugs.join(','),
        },
      },
      {},
      'nextApi',
    );
  } catch (e) {
    console.error(e);
  }

  return data;
}

export function useMultiDrugsInteractions(drugs: string[], options = {}) {
  return useQuery<MultiInteraction[] | null>({
    queryKey: ['multi-drugs-interaction', {drugs}],
    queryFn: () => getMultiDrugsInteractions(drugs),
    ...options,
  });
}
