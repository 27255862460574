import {Insurance} from '@/interfaces/financialAssistance';

export const FINANCIAL_ASSISTANCE_ELIGIBILITY = {
  detail:
    '{drugName} has financial assistance programs to help patients meeting certain requirements afford their treatment. To determine what options are available for your patient, please select all that are true below.',
  ageError:
    'Only residents of the US who are 18 and older or are caretakers are eligible for financial assistance for {drugName}"',
  age: 'Patient is 18 or older and a US resident',
  insurance: 'Please tell us about any insurance your patient has.',
  financial: {
    income: `What's your patient's annual income? (*)`,
    household: `What's the size of your patient's household? (*)`,
  },
  benefit:
    'Your patient is eligible for financial assistance, we recommend to enroll in all of the programs for your best options',
  error:
    'Your patient is not eligible for financial assistance for {drugName}.',
};

export const INSURANCE_LIST: Insurance[] = [
  {
    name: 'commercial',
    icon: 'Commercial',
    selected: false,
    enabled: true,
  },
  {
    name: 'medicare',
    icon: 'Medicare',
    selected: false,
    enabled: true,
  },
  {
    name: 'medicaid',
    icon: 'MedicaidDisabled',
    selected: false,
    enabled: false,
  },
  {
    name: 'tricare/VA',
    icon: 'TricareVaDisabled',
    selected: false,
    enabled: false,
  },
  {
    name: 'uninsured',
    icon: 'Unsured',
    selected: false,
    enabled: true,
  },
];

export const HOUSEHOLD_OPTIONS = ['1', '2', '3', '4', '5', '6', '7+'];

export const US_STATE_OPTIONS = ['Continental US', 'Alaska', 'Hawaii'];

export const COPAY_PROGRAM = {
  title: 'You are eligible for the Copay Card Program',
  name: 'Copay Card Program',
  indication: 'Here are the forms you need to enroll in this program.',
  price: '${price} Copay',
  details: {
    programDosageCost: '${unitRetailPrice} for {unitType}',
    programExpiration: 'Program Expires {expirationDate}',
    programAnnualCap: '${annualCap} Annual Cap',
  },
  programs: [
    {
      name: 'commercial',
      icon: 'Commercial',
    },
  ],
};

export const FOUNDATION_PROGRAM = {
  title: 'Your patient is eligible for the Foundation Program',
  name: 'Foundation Program',
  indication:
    'Here are the forms you need to enroll in this program. The links below will take you to a 3rd party website',
  price: 'First dose for $0',
  requirements: ['Eligibility determined by household size and income limits'],
  programs: {
    medicare: {
      name: 'medicare',
      icon: 'Medicare',
    },
    uninsured: {
      icon: 'Unsured',
    },
  },
};

export const FINANCIAL_ASSISTANCE_DETAILS = {
  section: 'Financial Assistance',
  title: 'Financial Assistance Programs',
  drugDetail:
    '{drugName} retails for $876.54 per dose without insurance or financial assistance.',
  eligibilityDetail: `Depending on your patient's insurance situation and other eligibility criteria, they may be able to get {drugName} for significantly less. Review the program information below to determine what program can offer your patient the most benefits.`,
};

export const PATIENT_ASSISTANCE_PROGRAM = {
  title: 'Patient Assistance Program',
  price: '{supply} {supplyLength} supply for as low as ${price}',
  programs: [
    {
      name: 'commercial',
      icon: 'Commercial',
    },
  ],
  details: ['Program Expires {expirationDate}', '${annualCap} Annual Cap'],
};
