import {read} from './api';
import {FEATURE_FLAGS_ENVIRONMENT} from '@/constants/global';
import {FlagsType} from '@/context';
import {getDataFromLocalPath} from '@/utils/files';

class FeatureFlags {
  public async fetchFlags(): Promise<FlagsType> {
    // If we are on the server, we fetch the flag directly from the file system
    if (typeof window === 'undefined') {
      const data = await getDataFromLocalPath<FlagsType>(
        `feature-flags/${FEATURE_FLAGS_ENVIRONMENT}.json`,
      );
      if (data.data) {
        return data.data;
      }
    }
    // Fetch from the proxy endpoint if code gets called on the client or if the local file is not found
    try {
      return await read<FlagsType>('flags', {}, {}, 'nextApi');
    } catch (proxyError) {
      // This case will be mostly hit by preview deployments as the proxy endpoint is not available for the first build
      try {
        return await read<FlagsType>(
          `feature-flags/${FEATURE_FLAGS_ENVIRONMENT}.json?cacheBust=${new Date().getTime()}`,
          {},
          {},
          'cdn',
        );
      } catch (e) {
        console.error(
          'Failed to fetch feature flags from proxy endpoint:',
          proxyError,
        );
        throw new Error('Failed to fetch feature flags');
      }
    }
  }

  public async getFlag(name: string): Promise<'on' | 'off'> {
    const flags = await this.fetchFlags();

    const flagValue = flags[name];
    return flagValue;
  }

  public async getAllFlags(): Promise<FlagsType> {
    return await this.fetchFlags();
  }
}

const featureFlags = new FeatureFlags();
export default featureFlags;
