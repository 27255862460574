import {Icon, Text} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {FC, ReactNode} from 'react';

export interface IDrugResultItemProps {
  onClick?: () => void;
  manufacturer: string;
  drugName: string | React.ReactNode;
  selected?: boolean;
  selectedContent?: ReactNode;
  className?: ClassValue;
}

const DrugResultItem: FC<IDrugResultItemProps> = ({
  manufacturer,
  drugName,
  onClick = () => {},
  selected = false,
  selectedContent,
  className,
}) =>
  selected ? (
    <div
      className={clsx(
        'flex cursor-pointer items-center justify-start p-2 shadow-low-bottom hover:cursor-not-allowed active:!bg-neutral-light md:shadow-none devicehover:hover:bg-neutral-lighter transition-colors',
        className,
      )}>
      <div className="flex flex-1 flex-col">
        <Text variant="tertiary">{manufacturer}</Text>
        <Text as="body-md" variant="tertiary" weight="medium">
          {drugName}
        </Text>
      </div>
      {selectedContent ?? (
        <div className="flex space-x-1">
          <Text
            size="sm"
            weight="regular"
            variant="error"
            className="cursor-pointer px-1 text-error">
            Remove
          </Text>
          <Icon
            className="h-5 w-5 cursor-pointer text-error"
            name="CheckmarkCircleFilled"
          />
        </div>
      )}
    </div>
  ) : (
    <button
      onClick={onClick}
      className={clsx(
        'cursor-pointer px-4 flex items-center py-2 shadow-low-bottom hover:bg-neutral-light md:shadow-none transition-colors',
        className,
      )}>
      <div className="flex-1 text-left">
        <Text
          tag="div"
          className="!text-grey-neutral-secondary"
          size="sm"
          weight="regular">
          {manufacturer}
        </Text>
        <Text as="body-md">{drugName}</Text>
      </div>
      <Icon name="AddCircleFilled" className="w-5 text-theme-primary" />
    </button>
  );

export default DrugResultItem;
