import {useMutation} from '@tanstack/react-query';
import {IFormsSearchResult} from '@/interfaces';
import {read} from '@/services/api';

interface IPriorAuthSearchRequest {
  query: string;
  baseUrl: string;
}

export async function createPriorAuthSearch({
  query,
  baseUrl,
}: IPriorAuthSearchRequest) {
  return await read<IFormsSearchResult>(
    'Search',
    {params: {query, baseUrl}},
    {},
    'search',
  );
}

export function usePriorAuthSearch(options = {}) {
  return useMutation({mutationFn: createPriorAuthSearch, ...options});
}
