import {useMemo} from 'react';
import {PriorAuthorizationFormsDto} from '@/models';
import {getMostRecentDate} from '@/utils/date';

export const usePABrandsList = (brands: PriorAuthorizationFormsDto[]) => {
  const lastUpdated = useMemo(() => {
    const documents = brands
      .map((brand) => brand.carriers.map((carrier) => carrier.documents).flat())
      .flat();
    const dates = documents.map((doc) => doc.lastUpdatedDate);
    const mostRecentDate = getMostRecentDate('n/a', dates);
    return mostRecentDate;
  }, [brands]);

  return {lastUpdated};
};
