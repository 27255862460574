import {Icon, Text} from '@prescriberpoint/ui';
import clsx from 'clsx';

interface IRequestSamplesCardButtonProps {
  onClick: () => void;
}

const RequestSamplesCardButton = ({
  onClick,
}: IRequestSamplesCardButtonProps) => (
  <button
    onClick={onClick}
    id="request-samples"
    className="group flex flex-col gap-y-3 bg-blue-50 rounded-2xl p-5 hover:bg-blue-100 ">
    <div className="flex md:flex-col gap-2">
      <div className="bg-blue-300 rounded-full w-fit h-fit p-2">
        <Icon
          className="block h-6 w-6 md:!w-8 md:!h-8  text-blue-500"
          name="Samples"
        />
      </div>
      <div className="text-left">
        <Text as="body-md" weight="bold">
          Get samples delivered{' '}
        </Text>
        <Text as="body-md" weight="semibold">
          to your practice door
        </Text>
      </div>
    </div>
    <div
      className={clsx(
        'flex flex-row space-x-1 justify-center items-center bg-blue-100',
        'py-2.5 px-4 w-full rounded mt-auto group-hover:bg-blue-200',
      )}>
      <Text as="body-sm" weight="bold" className="text-blue-500">
        Request Samples
      </Text>
      <Icon className="block h-4 w-4 text-blue-500" name="OpenLink" />
    </div>
  </button>
);

export default RequestSamplesCardButton;
