import {useDisabledSections} from '@/queries';

export function useIsSectionDisabled(setId: string, sectionId: string) {
  const {data: disabledSections} = useDisabledSections();

  if (disabledSections?.[setId]) {
    return disabledSections[setId].sections.includes(sectionId);
  }
  return false;
}
