import {useState, useEffect, useMemo, useCallback} from 'react';
import {toast} from 'react-toastify';
import {useLocalStorage} from 'usehooks-ts';
import {NotificationContent} from '@/components/Bookmark/NotificationContent';
import {CEVENT_DISLIKE_DRUG, CEVENT_LIKE_DRUG, DlvLikeDrug} from '@/constants';
import {useUser, useDlvDrugInfo, ICurrentDrug} from '@/hooks';
import useUserData from '@/hooks/useUserData';
import {DrugsSavedByUserResult} from '@/interfaces';
import {
  addDrugsSavedByUserCookie,
  deleteDrugsSavedByUserCookie,
  convertToResults,
} from '@/utils';
import {customEvent} from '@/utils/gtm';

export function useBookmarkPDP(currentDrug: ICurrentDrug) {
  const {
    userData: {bookmarks},
    setUserData,
  } = useUserData();
  const [paywallSubmitted] = useLocalStorage('user_submitted_paywall', false);
  const [isFilled, setIsFilled] = useState(false);
  const [renderModal, setRenderModal] = useState(false);
  const {logged} = useUser();
  const dlvData = useDlvDrugInfo();

  const renderToast = useCallback(() => {
    if (!logged && !isFilled) {
      toast(NotificationContent, {
        position: toast.POSITION.BOTTOM_CENTER,
        className: '!bg-neutral-primary !rounded-sm !shadow',
        closeButton: false,
        hideProgressBar: true,
      });
    }
  }, [isFilled, logged]);

  const eventData: DlvLikeDrug = useMemo(
    () => ({
      ...dlvData,
    }),
    [dlvData],
  );

  const addBookmark = useCallback(() => {
    customEvent<DlvLikeDrug>(CEVENT_LIKE_DRUG, eventData);

    const newBookmark = {
      label_title: currentDrug.drugName,
      slug: currentDrug.slug,
      objectID: currentDrug.setId,
      labeler: currentDrug.labelerName,
    } as DrugsSavedByUserResult;

    setUserData((prevValue) => ({
      ...prevValue,
      bookmarks: addDrugsSavedByUserCookie(bookmarks, newBookmark, false),
    }));

    setIsFilled(true);
    renderToast();
  }, [eventData, bookmarks, currentDrug, renderToast, setUserData]);

  const removeBookmark = useCallback(() => {
    customEvent<DlvLikeDrug>(CEVENT_DISLIKE_DRUG, eventData);

    const bookmarkToDelete = {
      label_title: currentDrug.drugName,
      slug: currentDrug.slug,
      objectID: currentDrug.setId,
      labeler: currentDrug.labelerName,
    } as DrugsSavedByUserResult;

    setUserData((prevValue) => ({
      ...prevValue,
      bookmarks: deleteDrugsSavedByUserCookie(bookmarks, bookmarkToDelete),
    }));

    setIsFilled(false);
  }, [eventData, setUserData, currentDrug, bookmarks]);

  const toggleFavorite = () => {
    if (isFilled) {
      removeBookmark();
    } else {
      addBookmark();
    }
  };

  const handleClick = () => {
    if (logged || paywallSubmitted) {
      toggleFavorite();
    } else {
      setRenderModal(true);
    }
  };

  const closeModal = () => {
    setRenderModal(false);
    if (paywallSubmitted) toggleFavorite();
  };

  const bookmarksArr = useMemo<DrugsSavedByUserResult[]>(
    () => (bookmarks ? convertToResults(bookmarks) : []),
    [bookmarks],
  );

  useEffect(() => {
    const isIncluded: boolean = bookmarksArr.reduce(
      (status, bookmark) =>
        bookmark.label_title === currentDrug.drugName || status,
      false,
    );
    setIsFilled(isIncluded);
  }, [currentDrug.drugName, bookmarksArr]);

  return {
    isFilled,
    toggleFavorite,
    handleClick,
    closeModal,
    renderModal,
  };
}
