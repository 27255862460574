import {useQuery} from '@tanstack/react-query';
import {AffordabilityDetailsDto} from '@/models';
import {read} from '@/services/api';

export async function getAffordabilityDetails(
  setId: string,
): Promise<AffordabilityDetailsDto[]> {
  const data = await read<AffordabilityDetailsDto[]>(
    'affordability',
    {resourceId: setId},
    {},
    'nextApi',
  );
  return data;
}

export function useAffordabilityDetails(setId: string, options = {}) {
  return useQuery<AffordabilityDetailsDto[]>({
    queryKey: ['affordability-details', setId],
    queryFn: async () => await getAffordabilityDetails(setId),
    ...options,
  });
}
