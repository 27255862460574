import {capitalizeFirstLetter} from '@prescriberpoint/ui';
import {useMemo} from 'react';
import {useCurrentDrug} from '@/hooks';
import {IndicationOption} from '@/interfaces/patientIndication';
import {useDrugIndications} from '@/queries';

export default function useIndications(selectedIndication?: IndicationOption) {
  const {setId} = useCurrentDrug();
  const {data: indicationsData = [], ...rest} = useDrugIndications(setId, {
    enabled: !!setId,
  });

  const drugIndications = useMemo<IndicationOption[]>(() => {
    if (indicationsData.length === 0) return [];

    return indicationsData.map((indication, idx) => ({
      key: String.fromCharCode(97 + idx),
      text: indication.Name,
      id: indication.IndicationId,
    }));
  }, [indicationsData]);

  const drugDosages = useMemo<IndicationOption[]>(() => {
    if (indicationsData.length === 0) return [];

    const indication = indicationsData.find(
      ({IndicationId}) => selectedIndication?.id === IndicationId,
    );

    if (indication) {
      return indication.dosages.map((dosage, idx) => ({
        key: String.fromCharCode(97 + idx),
        text: `${capitalizeFirstLetter(dosage.route)} - ${dosage.dose} ${
          dosage.form
        }`,
        id: dosage.rxcui,
      }));
    } else {
      return indicationsData[0].dosages.map((dosage, idx) => ({
        key: String.fromCharCode(97 + idx),
        text: `${capitalizeFirstLetter(dosage.route)} - ${dosage.dose} ${
          dosage.form
        }`,
        id: dosage.rxcui,
      }));
    }
  }, [indicationsData, selectedIndication]);

  return {
    drugIndications,
    drugDosages,
    ...rest,
  };
}
