import {useQuery} from '@tanstack/react-query';
import {FormsDto} from '@/models';
import {read} from '@/services/api';

export async function getForms(searchId: string): Promise<FormsDto | null> {
  let data = null;

  if (!searchId) return data;

  try {
    data = await read<FormsDto>(
      'History',
      {resourceId: searchId, parentName: 'Search'},
      {},
      'search',
    );
  } catch (e) {
    console.error(e);
  }

  return data;
}

export function useForms(id: string, options = {}) {
  return useQuery<FormsDto | null>({
    queryKey: ['forms', id],
    queryFn: () => getForms(id),
    ...options,
  });
}
