const SPECIALTY_PHARMACIES_BY_BRAND = {
  metaTitle: 'Specialty Pharmacies for {brandName}',
  metaDescription:
    'Specialty pharmacies play an important part in getting patients on {brandName} because they offer specialized services and support that are crucial for patients with complex medical conditions. Specialty pharmacies have expertise in managing complex medication regimens, navigating insurance and reimbursement issues, and providing clinical support to patients and healthcare providers.',
  title: 'Specialty Pharmacies for {brandName}',
  description:
    'Specialty pharmacies play an important part in getting patients on specialty drugs because they offer specialized services and support that are crucial for patients with complex medical conditions. Specialty pharmacies have expertise in managing complex medication regimens, navigating insurance and reimbursement issues, and providing clinical support to patients and healthcare providers. They can also offer patient education and counseling to help ensure that patients understand how to take their medication, manage side effects, and improve medication adherence. By working closely with healthcare providers and pharmaceutical manufacturers, specialty pharmacies can help ensure that patients receive the right medication at the right time and in the most effective manner.',
};

const SPECIALTY_PHARMACIES_DISTRIBUTION_DESCRIPTIONS = {
  unknown:
    '{specialtyPharmacyName} for {brandName}. Benefit from prior authorization support and specialized handling. View phone, fax numbers and other contact information.',
  limited:
    '{specialtyPharmacyName} with limited distribution for {brandName}. Benefit from prior authorization support and specialized handling. View phone, fax numbers and other contact information.',
  exclusive:
    '{specialtyPharmacyName} with exclusive distribution for {brandName}. Benefit from prior authorization support and specialized handling. View phone, fax numbers and other contact information.',
};

export {
  SPECIALTY_PHARMACIES_BY_BRAND,
  SPECIALTY_PHARMACIES_DISTRIBUTION_DESCRIPTIONS,
};
