import {useMutation} from '@tanstack/react-query';
import {create} from '@/services/api';

export interface IRequestMagicLinkToLoginOnlyEmail {
  email: string;
  redirectUrl: string;
}

async function requestMagicLinkToLoginOnlyEmail(
  params: IRequestMagicLinkToLoginOnlyEmail,
) {
  await create(
    'VerifyAccount',
    {
      parentName: 'Auth',
      params,
    },
    {},
    'profile',
  );
}

export function useRequestMagicLinkToLoginOnlyEmail() {
  return useMutation({mutationFn: requestMagicLinkToLoginOnlyEmail});
}
