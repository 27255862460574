import {Button} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {useCallback} from 'react';
import Chip from '@/components/Chip';
import {useSearchBrandsContext} from '@/context';

interface SelectedBrandsProps {
  readonly onContinue: () => void;
  readonly className?: ClassValue;
}

export default function SelectedBrands({
  className,
  onContinue,
}: SelectedBrandsProps) {
  const {selectedResults, setSelectedResults, saveBrandsSelected, openSearch} =
    useSearchBrandsContext();

  const handleContinue = useCallback(() => {
    if (selectedResults?.length > 0) {
      saveBrandsSelected();
      onContinue();
    }
  }, [selectedResults, saveBrandsSelected, onContinue]);

  const removeBrand = useCallback(
    (labelTitle: string) =>
      setSelectedResults(
        selectedResults.filter(({label_title}) => label_title !== labelTitle),
      ),
    [selectedResults, setSelectedResults],
  );

  return (
    <div
      className={clsx(
        'flex w-full flex-col gap-y-4 px-4 pb-4 md:px-0 md:pt-4',
        {
          'pt-4': openSearch,
        },
        className,
      )}>
      <div className='flex flex-wrap gap-2'>
        {selectedResults.map(({label_title, slug}) => (
          <Chip
            key={slug}
            onClick={() => removeBrand(label_title)}
            label={label_title}
            iconName='Cancel'
          />
        ))}
      </div>
      <Button
        id='continue-rep-sigin'
        stretched
        onClick={handleContinue}
        className='h-12 rounded-lg font-bold'>
        Continue
      </Button>
    </div>
  );
}
