import {useCurrentSlug} from './useCurrentSlug';
import * as FLAGS from '@/constants/flags';
import {useFlag} from '@/context';
import {useDrugIndications} from '@/queries';

export function useIsCoverageToolAvailable() {
  const {setId, isConstellationDrug} = useCurrentSlug();

  const showCoverageOnPdp = useFlag(FLAGS.COVERAGE_ON_PDP);
  const showCoverageLandPage = useFlag(FLAGS.COVERAGE_PAGE);

  const enableCoverageForConstellationOnly = useFlag(
    FLAGS.COVERAGE_FOR_CONSTELLATION,
  );

  const showCoveragePerTypeOfDrug = enableCoverageForConstellationOnly
    ? isConstellationDrug
    : true;

  const {data: indications = []} = useDrugIndications(setId, {
    enabled: !!setId,
  });

  const hasIndications = indications.length > 0;

  return {
    showCoverageOnPdp:
      showCoveragePerTypeOfDrug && hasIndications && showCoverageOnPdp,
    showCoverageLandPage:
      showCoveragePerTypeOfDrug && showCoverageLandPage && hasIndications,
    showCoverage:
      showCoveragePerTypeOfDrug &&
      hasIndications &&
      (showCoverageOnPdp || showCoverageLandPage),
  };
}
