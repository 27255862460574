import {useMemo} from 'react';
import {ICompareDrugOption} from '@/components';
import {useIndicationDrugs} from '@/queries';

export function useCompareIndicationDrugOptions(
  indicationSlug: string,
  drugSlug1: string,
  drugSlug2: string,
) {
  const {data: drugs} = useIndicationDrugs(indicationSlug);

  const options = useMemo(
    (): ICompareDrugOption[] =>
      drugs?.length
        ? drugs
            .map((drug) => ({slug: drug.slug, label: `${drug.name}®`}))
            .filter((option) => ![drugSlug1, drugSlug2].includes(option.slug))
        : [],
    [drugSlug1, drugSlug2, drugs],
  );

  const options1 = useMemo(
    (): ICompareDrugOption[] =>
      options.map((option) => ({
        slug: option.slug,
        label: option.label,
        href: `/compare/${indicationSlug}/${option.slug}-vs-${drugSlug2}`,
      })),
    [indicationSlug, drugSlug2, options],
  );

  const options2 = useMemo(
    (): ICompareDrugOption[] =>
      options.map((option) => ({
        slug: option.slug,
        label: option.label,
        href: `/compare/${indicationSlug}/${drugSlug1}-vs-${option.slug}`,
      })),
    [indicationSlug, drugSlug1, options],
  );

  return {options, options1, options2};
}
