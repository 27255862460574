import {DropdownOption} from '@prescriberpoint/ui';
import {CTA_SERVICES} from '@/constants/ctaServices';
export const ACTIONS: DropdownOption[] = [
  {
    key: '',
    text: 'Find drug and usage info',
  },
  {
    key: CTA_SERVICES.requestSamples,
    text: 'Request samples',
  },
  {
    key: CTA_SERVICES.getAuthAssistance,
    text: 'Get prior auth & financial assistance support',
  },
  {
    key: CTA_SERVICES.getFinancialAssistance,
    text: 'Get financial assistance support',
  },
  {
    key: CTA_SERVICES.requestPatientEducation,
    text: 'Request patient education material',
  },
  {
    key: CTA_SERVICES.getManufacturerSupport,
    text: 'Contact manufacturer',
  },
];
