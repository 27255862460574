import {useEffect} from 'react';
import {useCookies} from 'react-cookie';
import useUser from './useUser';
import {
  INTERNAL_TRAFFIC_COOKIE,
  IS_PRODUCTION,
  NPI_COOKIE,
} from '@/constants/global';

export const useClarity = () => {
  const {logged, email} = useUser();
  const [cookies] = useCookies([INTERNAL_TRAFFIC_COOKIE, NPI_COOKIE]);

  // send identify event when user is logged in
  useEffect(() => {
    //@ts-ignore
    if (IS_PRODUCTION && logged && window.clarity) {
      //@ts-ignore
      window?.clarity('identify', email);
    }
  }, [logged, email]);

  // set internal_traffic and npi_verified values
  useEffect(() => {
    if (!IS_PRODUCTION) return;

    if (cookies[INTERNAL_TRAFFIC_COOKIE] === 'true') {
      //@ts-ignore
      window?.clarity('set', 'internal_traffic', 'true');
    } else {
      //@ts-ignore
      window?.clarity('set', 'internal_traffic', 'false');
    }

    if (cookies[NPI_COOKIE]) {
      //@ts-ignore
      window?.clarity('set', 'npi_verified', 'true');
    } else {
      //@ts-ignore
      window?.clarity('set', 'npi_verified', 'false');
    }
  }, [cookies]);
};
