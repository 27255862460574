import clsx from 'clsx';
import debounce from 'lodash.debounce';
import {forwardRef, useCallback} from 'react';
import ResourceActions from './ResourceActions';
import ResourceDetails from './ResourceDetails';
import {
  CEVENT_BRAND_RSC_CONTINUE_TO_WEBSITE,
  DlvBrandRscContinueToWebsite,
} from '@/constants';
import {useUserAgentContext} from '@/context';
import {useFirePaywall} from '@/hooks';
import {customEvent} from '@/utils/gtm';

export type ResourceButtonType =
  | 'share'
  | 'download'
  | 'redirect'
  | 'qr'
  | 'video'
  | 'image'
  | 'recent'
  | 'copy'
  | 'unknown';

export const PUBMED_RESOURCE_TYPES = [
  'Journal Article',
  'Classical Article',
  'Review',
  'English Abstract',
  'Meta-Analysis',
];

export type NewLinkType =
  // Keys from contentful
  | 'resourceTypePdf'
  | 'resourceTypeOther'
  | 'resourceTypeVideo'
  | 'resourceTypeBrandPage'
  | 'resourceTypeImage'
  | 'resourceTypeMedicationGuide'
  | 'resourceTypePrescribingInformation'
  | 'resourceTypeSampleRequestForm'
  | 'resourceTypeCopayCard'
  | 'resourceTypeLetterOfMedicalNeccesity'
  | 'resourceTypeApp'
  | 'resourceTypemedicalNecessityLetter'
  // Keys from design
  | 'resourceTypeNotProvidedByBrand'
  | 'resourceTypeOnlyForm'
  | 'Journal Article'
  | 'Classical Article'
  | 'Review'
  | 'English Abstract'
  | 'Meta-Analysis';

interface ResourceCardProps {
  link: any;
  disabled?: boolean;
  buttonType?: ResourceButtonType;
  id: string;
  linkType?: NewLinkType;
  drugName: string;
  handleActionClick: () => void;
  handleShareClick: () => void;
  isQr?: boolean;
  date?: string;
}

const ResourceButton = forwardRef<HTMLDivElement, ResourceCardProps>(
  (
    {
      drugName,
      link,
      disabled,
      buttonType = 'redirect',
      linkType,
      id,
      isQr,
      date,
      handleShareClick,
      handleActionClick,
    },
    ref,
  ) => {
    const hasHref = link?.href ? link?.href?.length > 0 : false;
    const disabledStyle = disabled || !link || !hasHref;
    const type: NewLinkType = disabledStyle
      ? 'resourceTypeNotProvidedByBrand'
      : linkType!;

    const {isMobileOrTablet} = useUserAgentContext();

    const hasQr = isQr && !isMobileOrTablet && !disabledStyle;
    const firePaywall = useFirePaywall();

    const debounced = (cb: any) => {
      debounce(() => cb(), firePaywall ? 400 : 0);
    };

    const handleLinkClick = useCallback(() => {
      customEvent<DlvBrandRscContinueToWebsite>(
        CEVENT_BRAND_RSC_CONTINUE_TO_WEBSITE,
        {
          drugName,
          resourceName: link?.title ?? '',
          link: link.href,
        },
      );

      if (!firePaywall) {
        window.open(link.href, '_blank');
      }
    }, [firePaywall, link, drugName]);

    return (
      <div
        id={`${id}_button`}
        ref={ref}
        className={clsx(
          'my-0 flex w-full items-center justify-between rounded-lg px-4 py-2 text-left text-neutral-primary hover:bg-neutral-lighter',
          {
            'py-2.5': PUBMED_RESOURCE_TYPES.includes(type),
            'cursor-not-allowed': disabledStyle,
            // Label default style
            '[&>*>#labelContainer]:-translate-x-[3.25rem]': hasQr,
            // Label animation style
            '[&>*>#labelContainer]:hover:-translate-x-1 [&>*>*>.qr-title]:duration-600 [&>*>*>.qr-title]:hover:text-[22px] [&>*>*]:duration-600 [&>*>*]:ease-in-out':
              hasQr,
            // QR default style
            '[&>*>#code]:h-[80px] [&>*>#code]:w-[80px] [&>*>#code]:flex-none [&>*>#code]:-translate-x-6 [&>*>#code]:scale-[0.33]':
              hasQr,
            // QR animition style
            '[&>*>#code]:hover:translate-x-0 [&>*>#code]:hover:scale-100':
              hasQr,
            '[&>#instructions]:opacity-0': hasQr,
            //#instructions animation style
            '[&>#instructions]:duration-600 [&>#instructions]:ease-in-out [&>#instructions]:hover:opacity-100':
              hasQr,
          },
        )}>
        <ResourceDetails
          date={date}
          debounced={debounced}
          disabledStyle={disabledStyle}
          handleLinkClick={handleLinkClick}
          hasQr={hasQr}
          isQr={isQr}
          link={link}
          type={type}
        />
        {!disabledStyle ? (
          <ResourceActions
            id={id}
            debounced={debounced}
            buttonType={buttonType}
            handleShareClick={handleShareClick}
            handleActionClick={handleActionClick}
            handleLinkClick={handleLinkClick}
            disabledStyle={disabledStyle}
            type={type}
            disabled={disabled}
          />
        ) : null}
      </div>
    );
  },
);

ResourceButton.displayName = 'ResourceButton';

export default ResourceButton;
