import {Button, Icon, Loader, Text} from '@prescriberpoint/ui';
import {FC, useEffect, useState} from 'react';

interface DownloadLetterLinkProps {
  fileUrl: string;
  fileName: string;
  fileExtension: string;
}

type statusType = 'loading' | 'creating_draft' | 'ready_for_downloading';

const DownloadLetterLink: FC<DownloadLetterLinkProps> = ({
  fileUrl,
  fileName,
  fileExtension,
}) => {
  const [downloadUrl, setDownloadUrl] = useState('');
  const [status, setStatus] = useState<statusType>('loading');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setStatus('creating_draft');
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setStatus('ready_for_downloading');
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    fetch(fileUrl).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        setDownloadUrl(fileURL);
      });
    });
  }, [fileUrl]);

  const onDonwload = () => {
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = `${fileName}.${fileExtension}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="flex justify-between pl-[18px]">
      <div className="flex items-center space-x-1">
        {status === 'ready_for_downloading' ? (
          <Icon
            name="FileWarning"
            className="mr-1 flex h-5 w-5 items-center justify-center text-error"
          />
        ) : (
          <Loader spinnerSize="xxs" className="mr-1" />
        )}

        {status === 'creating_draft' ? (
          <Text as="body-md" weight="bold" variant="black">
            Creating Draft
          </Text>
        ) : (
          <Text as="body-md" weight="bold" variant="black">
            {fileName}
          </Text>
        )}
      </div>
      {status === 'ready_for_downloading' && (
        <Button id="download_resource" variant="text" onClick={onDonwload}>
          Download
        </Button>
      )}
    </div>
  );
};

export default DownloadLetterLink;
