import {useQuery} from '@tanstack/react-query';
import {TokenDto} from '@/models';
import {read} from '@/services/api';

export async function getTokenValidation(
  token: string,
): Promise<TokenDto | null> {
  let data = null;

  try {
    data = await read<TokenDto>(
      'Validate',
      {
        parentName: 'Auth',
        resourceId: token,
      },
      {},
      'profile',
    );
  } catch (e) {
    console.error(e);
    throw e;
  }

  return data;
}

export function useValidateToken(token: string, options = {}) {
  return useQuery<TokenDto | null>({
    queryKey: ['token', token],
    queryFn: () => getTokenValidation(token),
    ...options,
  });
}
