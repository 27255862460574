export const DRUGS_WITH_PAYWALL = ['xeljanz'];

export const PAYWALL_IMGS: {[key: string]: {[key: string]: string}} = {
  xeljanz: {
    online_sample_form:
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-Samples-Form.jpg',
    'xelsource_enrollment_&_prescription_form':
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-Enrollment-and-Prescription-Form.jpg',
    'doctor_discussion_guide:_rheumatoid_arthritis':
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-Doctor-Discussion-Guide-RA.jpg',
    'patient_stories:_rheumatoid_arthritis,_psoriatic_arthritis_&_ulcerative_colitis':
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-Patient-Stories.jpg',
    'about_xeljanz:_ulcerative_colitis':
      'https://cdn.prescriberpoint.com/assets/images/https://cdn.prescriberpoint.com/assets/images/Xeljanz-About-UC.jpg',
    'about_xeljanz:_ankylosing_spondylitis':
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-About-AS.jpg',
    'side_effects:_ulcerative_colitis':
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-Side-Effects-UC.jpg',
    'side_effects:_rheumatoid_arthritis':
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-Side-Effects-RA.jpg',
    'about_xeljanz:_rheumatoid_arthritis':
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-About-RA.jpg',
    pfizer_patient_assistance_program_application:
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-Patient-Assistance-Program.jpg',
    'xeljanz_rebate_form_[if_co-pay_card_not_accepted]':
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-CoPay-Rebate-Form.jpg',
    'doctor_discussion_guide:_ulcerative_colitis':
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-Doctor-Discussion-Guide-UC.jpg',
    enroll_in_patient_savings_program:
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-Patient-Savings-Program-Form.jpg',
    'side_effects:_psoriatic_arthritis':
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-Side-Effects-PA.jpg',
    'about_xeljanz:_juvenile_idiopathic_arthritis':
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-About-JIA.jpg',
    'about_xeljanz:_psoriatic_arthritis':
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-About-PA.jpg',
    'doctor_discussion_guide:_psoriatic_arthritis':
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-Doctor-Discussion-Guide-PA.jpg',
    xelsource_patient_support_enrollment_form:
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-Patient-Support-Enrollment-Form.jpg',
    reimbursement_information:
      'https://cdn.prescriberpoint.com/assets/images/Xeljanz-Reimbursement-Help-Form.jpg',
  },
};
