import {useQuery} from '@tanstack/react-query';
import {InvitationDto} from '@/models';
import {read} from '@/services/api';

export async function getInvitation(token: string): Promise<InvitationDto> {
  const data = await read<InvitationDto>(
    'Invitation',
    {
      resourceId: token,
    },
    {},
    'profile',
  );
  return data;
}

export function useInvitation(token: string, options = {}) {
  return useQuery<InvitationDto>({
    queryKey: ['invitation', token],
    queryFn: () => getInvitation(token),
    ...options,
  });
}
