import {Text} from '@prescriberpoint/ui';
import {FC, useEffect} from 'react';
import RedirectLink, {RedirectLinkProps} from './RedirectLink';

export interface FormErrorProps {
  primaryLink: RedirectLinkProps;
  secondaryLink?: RedirectLinkProps;
  fileUrl: string;
  fileId?: string;
  errorName: string;
  errorMessage?: string;
}

const FormError: FC<FormErrorProps> = ({
  primaryLink,
  secondaryLink,
  fileId,
  fileUrl,
  errorName,
  errorMessage = 'It looks like we are having an issue loading the PDF',
}) => {
  useEffect(() => {
    let sendError = true;
    if (sendError) {
      //@ts-ignore
      window.newrelic?.noticeError(new Error(errorName), {
        fileUrl,
        fileId,
        errorSource: 'FormViewer',
      });
    }
    return () => {
      sendError = false;
    };
  }, [fileId, fileUrl, errorName]);

  return (
    <div className="flex w-full animate-fadeIn flex-col items-center justify-center space-y-4 rounded-lg border border-solid border-neutral-light bg-neutral-light-translucent-26 px-4 py-16 text-center">
      <Text className="md:[3.5rem] z-10 my-0 font-houschka-rounded text-3xl font-semibold uppercase tracking-tighter sm:text-6xl">
        Sorry
      </Text>
      <Text className="z-10 mb-2 font-houschka-rounded text-xl font-bold sm:text-3xl">
        {errorMessage}
      </Text>
      <div className="flex w-fit flex-col space-y-4">
        <RedirectLink {...primaryLink} primary />
        {secondaryLink ? (
          <RedirectLink {...secondaryLink} primary={false} />
        ) : null}
      </div>
    </div>
  );
};

export default FormError;
