import {useMutation, useQueryClient} from '@tanstack/react-query';
import {create} from '@/services/api';

export interface IInviteRep {
  email: string;
  locationId: string;
  inviterId: string;
  source: string;
  practiceId: string;
}

async function inviteRep(params: IInviteRep) {
  return await create(
    'invite',
    {
      parentName: 'Collaboration',
      params,
    },
    {},
    'profile',
    true,
  );
}

export function useInviteRepAsHcp() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: inviteRep,
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['reps']});
    },
  });
}
