import {useQuery} from '@tanstack/react-query';
import {FormsDto} from '@/models';
import {read} from '@/services/api';

export async function getPAForms(searchId: string): Promise<FormsDto | null> {
  let data = null;

  if (!searchId) return data;

  try {
    data = await read<FormsDto>(
      'History',
      {
        resourceId: searchId,
        parentName: 'Search', // TODO: maybe other endpoint
      },
      {},
      'search',
    );

    // Sort the results array alphabetically by title
    if (data && data.results) {
      data.results.sort((a, b) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();

        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
      data.results = data.results.map((e, i) => ({
        ...e,
        resourceId: i,
      }));
    }

    return data;
  } catch (e) {
    console.error(e);
  }
  return data;
}

export function usePAForms(id: string, options = {}) {
  return useQuery<FormsDto | null>({
    queryKey: ['pa_forms', id],
    queryFn: () => getPAForms(id),
    ...options,
  });
}
