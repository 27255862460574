import {useMemo} from 'react';
import {PROTOCOL_HOSTNAME} from '@/constants/global';

type UrlQueries = {
  [key: string]: number | boolean | string;
};

export function useRedirectUrl(url: string, queries?: UrlQueries) {
  let queryString = '';
  if (queries) {
    // Transform all items from the queries object to string
    Object.keys(queries).forEach((key) => {
      queryString += `&${key}=${queries[key].toString()}`;
    });
  }

  // url redirected by the application (for sharing through email or sms, if the resource is hosted by us, we don't need to use the redirect page)
  const redirectUrl = useMemo(
    () =>
      `${PROTOCOL_HOSTNAME}/redirect?url=${url?.split('?')[0]}${queryString}`,
    [url, queryString],
  );

  // source url with queries (to be redirected from the link itself in the pdp page)
  const shareUrl = useMemo(() => {
    const urlArr = url?.split('?') ?? [];
    // if there are query params in the url, put them first

    if (urlArr?.length > 1) {
      return `${urlArr[0]}?${urlArr[1]}${queryString}`;
    }
    return `${urlArr[0]}${!!queryString ? '?' + queryString.slice(1) : ''}`;
  }, [url, queryString]);

  return {redirectUrl, shareUrl};
}
