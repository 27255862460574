import {FC, useState, createContext, useContext, ReactNode} from 'react';

export type FlagsType = Record<string, 'off' | 'on'>;

interface IFeatureFlags {
  flags: FlagsType;
  setFlags: (flags: FlagsType) => void;
}

const defaultState: IFeatureFlags = {
  flags: {},
  setFlags: () => {},
};

export const FlagsContext = createContext<IFeatureFlags>(defaultState);

export const useFlags = () => useContext(FlagsContext);

interface FlagsProviderProps {
  flags: FlagsType;
  children: ReactNode;
}

export const FlagsProvider: FC<FlagsProviderProps> = (props) => {
  const [flags, setFlags] = useState<FlagsType>(props.flags);

  const values = {
    flags,
    setFlags,
  };

  return <FlagsContext.Provider value={values} {...props} />;
};

export const useFlag = (name: string, value: string = 'on') => {
  const {flags} = useFlags();
  return flags?.[name] === value;
};
