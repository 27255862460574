import {useMutation} from '@tanstack/react-query';
import {create} from '@/services/api';

interface ICreatePracticeUserRequest {
  fromId: string;
  toId: string;
}

export async function createPracticeUser(params: ICreatePracticeUserRequest) {
  const data = await create('WorkAt', {params}, {}, 'graph', true);
  return data;
}

export function useCreatePracticeUser(options = {}) {
  return useMutation({mutationFn: createPracticeUser, ...options});
}
