import clsx, {ClassValue} from 'clsx';
import {FC} from 'react';
import {useToggle} from 'usehooks-ts';
import FrictionlessModalForm from '../FrictionlessModalForm';
import CookieConsent from './CookieConsent';
import {FOOTER_LINKS, COOKIE_TITLE} from '@/constants';
import {
  SHOW_AI_CHATBOT_LINK,
  SHOW_COOKIE_POLICY_IN_FOOTER,
} from '@/constants/flags';
import {
  AI_CHATBOT_LINK_TITLE,
  FRICTION_LIST_NON_DISABLED_LINK,
} from '@/constants/footerLinks';
import {useFlag, useUserAgentContext} from '@/context';

interface FooterProps {
  className?: ClassValue;
}

const Footer: FC<FooterProps> = ({className}) => {
  const [isModalActive, toggleModal] = useToggle();
  const [isModalFrictionlessOpen, toggleModalFrictionlessOpen] = useToggle();
  const showCookiePolicyInFooter = useFlag(SHOW_COOKIE_POLICY_IN_FOOTER);
  const showAIChatbotLink = useFlag(SHOW_AI_CHATBOT_LINK);

  const {isClient} = useUserAgentContext();

  const linkStyle =
    'text-sm font-normal text-theme-primary no-underline hover:text-theme-dark hover:underline';
  return (
    <footer
      className={clsx(
        'neutral-lighter-alt w-full bg-white md:mx-auto',
        className,
      )}>
      {isModalFrictionlessOpen ? (
        <FrictionlessModalForm
          isOpen={isModalFrictionlessOpen}
          onClose={toggleModalFrictionlessOpen}
        />
      ) : null}
      <div
        className={clsx(
          'grid grid-cols-1 gap-x-48 px-6 pb-8 pt-8 sm:grid-cols-2 sm:pb-0 md:grid-cols-4',
        )}>
        {FOOTER_LINKS.map(({title: sectionTitle, links}) => (
          <div key={sectionTitle}>
            <h3 className='text-lg font-semibold text-neutral-primary'>
              {sectionTitle}
            </h3>
            <ul className='list-none pl-0 text-gray-500 dark:text-gray-400'>
              {links
                .filter((e) => {
                  if (e.title === AI_CHATBOT_LINK_TITLE) {
                    return showAIChatbotLink;
                  }

                  return true;
                })
                .map(({title, href, path}) => (
                  <li key={title} className='my-2'>
                    {title === COOKIE_TITLE ? (
                      showCookiePolicyInFooter ? (
                        <button className={linkStyle} onClick={toggleModal}>
                          {title}
                        </button>
                      ) : null
                    ) : title === FRICTION_LIST_NON_DISABLED_LINK ? (
                      <button
                        className={linkStyle}
                        onClick={toggleModalFrictionlessOpen}>
                        {title}
                      </button>
                    ) : (
                      <>
                        {isClient ? (
                          <a
                            id={title}
                            href={href ?? `https://prescriberpoint.com/${path}`}
                            className={linkStyle}>
                            {title}
                          </a>
                        ) : null}
                      </>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>
      <div className='flex justify-center px-4 pb-6 pt-6 sm:pt-3'>
        <span className='text-sm text-black'>
          © PrescriberPoint. Inc. {new Date().getFullYear()}
        </span>
      </div>
      <CookieConsent isModalActive={isModalActive} toggleModal={toggleModal} />
    </footer>
  );
};

export default Footer;
