import {Button, Loader, customEvent} from '@prescriberpoint/ui';
import {FC, useMemo, useState} from 'react';
import {useUpdateEffect} from 'usehooks-ts';
import Interactions from '@/components/DrugInteractions/Interactions';
import InteractionsV2 from '@/components/DrugInteractions/InteractionsV2';
import SearchbarList from '@/components/SearchbarList';
import {DDI_MULTI_DRUGS} from '@/constants/flags';
import {
  CEVENT_DDI_INTERACTIONS,
  DlvDDIPotentialInteractions,
} from '@/constants/gtm';
import {useFlag} from '@/context';
import {useDrugInteractionsContext} from '@/context/DrugInteractionsContext';
import {useSearchListContext} from '@/context/SearchListContext';
import {useUserAgentContext} from '@/context/UserAgentContext';
import {useDrugsInteraction} from '@/queries/useDrugsInteraction';
import {useMultiDrugsInteractions} from '@/queries/useMultiDrugsInteractions';
import {capitalize} from '@/utils/string';
const DrugInteractionsContent: FC = () => {
  const {
    isSearchDisabled,
    showSelectedResults,
    setShowSelectedResults,
    selectedResults,
  } = useSearchListContext();

  const ddiMultiDrugsEnabled = useFlag(DDI_MULTI_DRUGS);

  const [searchForInteractions, setSearchForInteractions] = useState(false);

  const {isMobile, isTablet} = useUserAgentContext();

  const {setIsOpen} = useDrugInteractionsContext();
  const canFetchDrugsInteraction = useMemo(
    () => searchForInteractions && selectedResults.length >= 2,
    [searchForInteractions, selectedResults],
  );

  const {data: multiDrugInteractions, isFetching: isFetchingMulti} =
    useMultiDrugsInteractions(selectedResults?.map(({set_id}) => set_id), {
      enabled: canFetchDrugsInteraction && ddiMultiDrugsEnabled,
    });

  const {data: drugInteractions, isFetching} = useDrugsInteraction(
    selectedResults?.[0]?.set_id,
    selectedResults?.[1]?.set_id,
    {enabled: canFetchDrugsInteraction && !ddiMultiDrugsEnabled},
  );

  const isFetchingInteractions = isFetchingMulti || isFetching;

  const handleViewInteractionsClick = () => {
    setSearchForInteractions(true);
  };

  useUpdateEffect(() => {
    if (searchForInteractions && drugInteractions) {
      customEvent<DlvDDIPotentialInteractions>(CEVENT_DDI_INTERACTIONS, {
        checkDrugs: `${capitalize(
          selectedResults[0].label_title,
        )}, ${capitalize(selectedResults[1].label_title)}`,
        hasInteraction: drugInteractions?.totalResults > 0,
      });
      setShowSelectedResults(false);
      setSearchForInteractions(false);
    }
  }, [searchForInteractions]);

  return (
    <div className="flex flex-1 flex-col justify-between h-full">
      <div className="flex-1 overflow-y-auto">
        <SearchbarList />
        {!showSelectedResults &&
          (isFetchingInteractions ? (
            <Loader />
          ) : drugInteractions ? (
            ddiMultiDrugsEnabled && multiDrugInteractions ? (
              <InteractionsV2
                drugs={selectedResults}
                interactions={multiDrugInteractions}
              />
            ) : (
              <div className={ddiMultiDrugsEnabled ? 'px-0' : 'px-0 md:px-3'}>
                <Interactions
                  drug1={selectedResults[0]}
                  drug2={selectedResults[1]}
                  interactions={drugInteractions.interactions}
                  totalInteractions={drugInteractions.totalResults}
                />
              </div>
            )
          ) : null)}
      </div>
      <div className="flex flex-col px-4 space-y-2">
        {showSelectedResults && isSearchDisabled && (
          <Button
            size="xl"
            stretched
            id="view_potencial_interactions"
            onClick={handleViewInteractionsClick}>
            View Potential Interactions
          </Button>
        )}
        {(ddiMultiDrugsEnabled || (!isMobile && !isTablet)) && (
          <Button
            id="cancel_potencial_interactions"
            variant="secondary"
            stretched
            size="xl"
            onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
};

export default DrugInteractionsContent;
