import {FC} from 'react';
import CTAMenuV2 from './CTAMenuV2';

const PdpHeaderV1Fixed: FC = () => (
  <div
    id='pdp-header-v1-fixed'
    className='stickyHeader h-fit w-full max-w-[1440px] overflow-y-hidden border-x-0 border-y-0 border-b border-solid border-neutral-quaternary bg-white px-3 py-2'>
    <CTAMenuV2 mobileFixedVersion />
  </div>
);

export default PdpHeaderV1Fixed;
