import {useMutation} from '@tanstack/react-query';
import {USE_EMAIL_FOR_REP_REGISTER_OTP_VALIDATION} from '@/constants/flags';
import {useFlag} from '@/context';
import {update} from '@/services/api';

export interface IValidateOtp {
  phoneNumber?: string;
  token?: string;
  code: string;
  email: string;
}

async function validateOtp(
  params: IValidateOtp,
  method: 'RepConfirmEmail' | 'RepConfirmPhoneNumber',
) {
  return await update<{
    token: string;
  }>(
    method,
    {
      parentName: 'Auth',
      params,
    },
    {method: 'PUT'},
    'profile',
  );
}

export function useValidateOtp() {
  const useEmailForOtpValidation = useFlag(
    USE_EMAIL_FOR_REP_REGISTER_OTP_VALIDATION,
  );

  return useMutation({
    mutationFn: (params: IValidateOtp) =>
      validateOtp(
        params,
        useEmailForOtpValidation ? 'RepConfirmEmail' : 'RepConfirmPhoneNumber',
      ),
  });
}
