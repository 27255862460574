import {useQuery} from '@tanstack/react-query';
import {RepDto, IBrandReps} from '@/models';
import {read} from '@/services/api';

const DEFAULT_REPS: RepDto[] = [
  {
    brands: [],
    id: '~',
    firstName: 'Kyle',
    lastName: 'McCaig',
    email: 'kyle.mccaig@prescriberpoint.com',
    picture: 'https://cdn.prescriberpoint.com/assets/images/KyleMcCaig.svg',
    locationName: null,
    phoneNumber: '(646) 904-5245',
    lastCheckIn: null,
    role: 'PrescriberPoint, Customer Success',
  },
];

export async function getRepList(setId?: string): Promise<IBrandReps | null> {
  let data = null;

  try {
    data = await read<RepDto[]>(
      'Reps',
      {
        parentName: 'Practice',
      },
      {},
      'profile',
      true,
    );
    data = data.filter((rep) => rep.role);

    if (setId) {
      data = data.filter((rep) =>
        rep.brands.some((brand) => brand.setId === setId),
      );
    }
  } catch (e) {
    console.error(e);
  }

  return data?.length
    ? {reps: data, isDefaultReps: false}
    : {reps: DEFAULT_REPS, isDefaultReps: true};
}

export function useRepList(setId?: string) {
  return useQuery<IBrandReps | null>({
    queryKey: ['rep-list', setId],
    queryFn: () => getRepList(setId),
  });
}
