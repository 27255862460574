import {useQuery} from '@tanstack/react-query';
import {DrugInteractionsDto} from '@/models';
import {read} from '@/services/api';

export async function getDrugsInteraction(
  drug1?: string,
  drug2?: string,
): Promise<DrugInteractionsDto | null> {
  let data = null;

  if (!drug1 || !drug2 || drug1?.length === 0 || drug2?.length === 0)
    return data;

  try {
    return await read<DrugInteractionsDto>(
      'interactions',
      {
        parentName: 'ddi',
        params: {
          drug1,
          drug2,
        },
      },
      {},
      'nextApi',
    );
  } catch (e) {
    console.error(e);
  }

  return data;
}

export function useDrugsInteraction(
  drug1Name: string,
  drug2Name: string,
  options = {},
) {
  return useQuery<DrugInteractionsDto | null>({
    queryKey: ['drugs-interaction', {drug1Name, drug2Name}],
    queryFn: () => getDrugsInteraction(drug1Name, drug2Name),
    ...options,
  });
}
