import {Icon, Link, Text} from '@prescriberpoint/ui';
import {FC} from 'react';
import {IResult} from '@/interfaces';
import {Interaction} from '@/models';

interface InteractionsProps {
  drug1: IResult;
  drug2: IResult;
  interactions: Interaction[];
  totalInteractions: number;
}
const Interactions: FC<InteractionsProps> = ({
  drug1,
  drug2,
  interactions,
  totalInteractions,
}) => (
  <div className="rounded-2xl bg-gradient-to-b from-neutral-light px-4 py-2">
    <div className="flex flex-col">
      <Text variant="secondary" casing="lower">
        {drug1.generic_name}
      </Text>
      <Link
        href={`/therapies/${drug1.slug}`}
        target="_blank"
        rel="noopener noreferrer"
        className="capitalize"
        linkSize="xl"
        label={drug1.label_title}
      />
    </div>
    <div className="box-content h-5 w-4 rotate-90 py-3">
      <Icon name="ArrowSwap" className="h-full w-full text-blue-600" />
    </div>
    <div className="flex flex-col">
      <Text variant="secondary" casing="lower">
        {drug2.generic_name}
      </Text>
      <Link
        href={`/therapies/${drug2.slug}`}
        target="_blank"
        rel="noopener noreferrer"
        className="capitalize"
        linkSize="xl"
        label={drug2.label_title}
      />
    </div>
    <div className="mb-6 mt-2 border-0 border-t border-solid border-neutral-light" />
    <div className="mb-6 flex flex-col space-y-2">
      <Text size="md">Interaction{totalInteractions > 1 && 's'}</Text>
      {totalInteractions > 0 ? (
        <ul className="space-y-2 text-neutral-primary">
          {interactions?.map((interaction) => (
            <li
              className="flex flex-col space-y-4"
              key={'interactions-' + interaction.product_concept_id}>
              <Text weight="regular" size="md">
                {interaction.description}
              </Text>
              <Text weight="regular" size="md">
                {interaction.extended_description}
              </Text>
            </li>
          ))}
        </ul>
      ) : (
        <Text weight="regular" size="md">
          No interactions are found between the drugs
        </Text>
      )}
    </div>
    <div className="rounded-2xl border-2 border-solid border-error p-1 pb-1.5 pl-1.5 md:p-2">
      <Text weight="bold" size="xs" className="text-error">
        error:
      </Text>{' '}
      <Text weight="regular" size="xs" className="text-error">
        If no interactions are found between two drugs, it does not necessarily
        mean that no interactions exist.
      </Text>
    </div>
  </div>
);

export default Interactions;
