import {NewLinkType} from '@/components/ResourceLink/ResourceButton';
import {
  RESOURCE_TO_COMPLETE,
  RESOURCE_TO_INFO,
} from '@/constants/resourceLabeling';
import {Resource} from '@/models';

function convertResourceType(resourceType: NewLinkType) {
  // Use a regular expression to extract the resource type name
  const matches = resourceType.match(/resourceType(.+)/);
  if (!matches) {
    return '';
  }
  let result = matches[1];
  // Convert the resource type name to the desired format
  result = result.replace(/([a-z])([A-Z])/g, '$1 $2');
  result = result.toUpperCase();
  result = result.replace('NECCESITY', 'NECESSITY'); // The reason for doing this is that there is a typo in the resource type name resourceTypeLetterOfMedicalNeccesity, where "necessity" is misspelled as "neccesity".
  return result;
}

const checkNaming = (link: Resource) => {
  if (link.title.trim().toLowerCase().startsWith('taking')) {
    return (
      'View How to Take ' + link.title.substring(link.title.indexOf(' ') + 1)
    );
  } else if (RESOURCE_TO_INFO.find((r) => r === link.title.trim())) {
    return 'Receive Assistance from an FRM Regarding ' + link.title.trim();
  } else if (RESOURCE_TO_COMPLETE.find((r) => r === link.title.trim())) {
    return 'Complete ' + link.title.trim();
  } else {
    return link.title.trim();
  }
};

export {convertResourceType, checkNaming};
