import {
  getHighlightedText,
  Icon,
  Text,
  useIsMobileOrTablet,
} from '@prescriberpoint/ui';
import {useMemo, useCallback} from 'react';
import {useInstantSearch} from 'react-instantsearch';
import DrugResultItem from './DrugResultItem';
import {useSearchBrandsContext} from '@/context';
import {DrugSearchResult} from '@/interfaces';
import {capitalize} from '@/utils/string';

interface SearcherResultsProps {
  setQuery: (query: string) => void;
}

const SearcherResults = ({setQuery}: SearcherResultsProps) => {
  const {
    addToSelectedResults,
    selectedResults,
    isSearchDisabled,
    setOpenSearch,
    inputRef,
  } = useSearchBrandsContext();

  const {results: searchResults} = useInstantSearch();
  const isMobileOrTablet = useIsMobileOrTablet();

  const isResultSelected = useCallback(
    (set_id: string) => selectedResults.find((r) => r.set_id === set_id),
    [selectedResults],
  );

  const isResultToAdd = useCallback(
    (set_id: string) => selectedResults.find((r) => r.set_id === set_id),
    [selectedResults],
  );

  const filteredSearchResults = useMemo(() => {
    const resultsWithLabeler = searchResults?.hits
      ?.filter(
        (hit) =>
          hit.labeler &&
          new RegExp(`(${searchResults?.query})+`, 'gi').test(hit.label_title),
      ) //select the drug brands that match and have a manufacturer,
      .slice(0, 10);
    return resultsWithLabeler?.filter(
      (hit) => !isResultToAdd(hit.objectID) && !isResultSelected(hit.objectID),
    );
  }, [searchResults, isResultToAdd, isResultSelected]);

  const showSearchResults = useMemo(
    () =>
      !!searchResults?.query &&
      !isSearchDisabled &&
      selectedResults.length + filteredSearchResults.length > 0,
    [
      isSearchDisabled,
      searchResults?.query,
      selectedResults,
      filteredSearchResults,
    ],
  );

  const selectDrug = useCallback(
    (result: DrugSearchResult) => {
      if (!selectedResults.find(({set_id}) => set_id === result.objectID)) {
        addToSelectedResults([
          {
            set_id: result.objectID,
            label_title: result.label_title,
            slug: result.slug,
            generic_name: result.names[1],
            labeler: result.labeler,
          },
        ]);
        setQuery('');
        if (!isMobileOrTablet) {
          setOpenSearch(false);
        }
        inputRef?.current?.focus();
      }
    },
    [
      selectedResults,
      addToSelectedResults,
      setQuery,
      setOpenSearch,
      isMobileOrTablet,
      inputRef,
    ],
  );

  return (
    <div className="flex-1 w-full h-fit md:absolute md:rounded-lg md:shadow-md bg-white md:top-[68px] overflow-y-auto">
      {!showSearchResults ? (
        <div className="flex h-full w-full items-center justify-center flex-col gap-y-[26px] md:hidden">
          <Icon
            name="SearchInfo"
            className="flex w-[70px] text-neutral-tertiary"
          />
          <Text as="title-md" className="text-center" variant="tertiary">
            Search and select the drug <br /> brands you represent.
          </Text>
        </div>
      ) : null}

      {showSearchResults ? (
        <div className="flex flex-col md:max-h-[580px] overflow-y-auto md:px-0">
          <div className="w-full py-2 md:hidden px-4">
            <Text as="body-md" weight="extrabold" size="md">
              Select your brands
            </Text>
          </div>
          {selectedResults.map((result) => (
            <DrugResultItem
              key={result.set_id}
              manufacturer={result.labeler}
              drugName={getHighlightedText(
                capitalize(result.label_title),
                searchResults?.query,
                'font-bold',
              )}
              selected
              selectedContent={
                <div className="flex items-center">
                  <Icon
                    className="h-5 w-5 cursor-pointer text-neutral-tertiary"
                    name="CheckmarkCircleFilled"
                  />
                </div>
              }
              className="px-4"
            />
          ))}
          {filteredSearchResults?.map((result) => (
            <DrugResultItem
              key={result.objectID}
              onClick={() => selectDrug(result)}
              manufacturer={result.labeler}
              drugName={getHighlightedText(
                capitalize(result.label_title),
                searchResults?.query,
                'font-bold',
              )}
              className="px-4"
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

const CustomResults = SearcherResults;

export default CustomResults;
