import {useCurrentSlug} from './useCurrentSlug';
import {useDrugNameBySetId} from './useDrugNameBySetId';

export function useCurrentDrug(): ICurrentDrug {
  const {setId, slug} = useCurrentSlug();
  const drugNameBySetIdData = useDrugNameBySetId(setId);
  const drugNameWithRegisteredSymbol = drugNameBySetIdData.drugName
    ? `${drugNameBySetIdData.drugName}®`
    : '';
  return {...drugNameBySetIdData, drugNameWithRegisteredSymbol, slug};
}

export interface ICurrentDrug {
  slug: string | string[] | undefined;
  drugName: string;
  drugNameWithRegisteredSymbol: string;
  names: string;
  genericName: string;
  drugClassName: string;
  labelerName: string;
  isLoading: boolean;
  setId: string;
}
