import {Link, Text} from '@prescriberpoint/ui';
import {clsx, ClassValue} from 'clsx';
import {useUserAgentContext} from '@/context/UserAgentContext';

interface IAgreeTermsAndCondtitionsAndPrivacyPolicyProps {
  readonly alternativeTextColor?: boolean;
  readonly className?: ClassValue;
  readonly text?: string;
}
export default function AgreeTermsAndCondtitionsAndPrivacyPolicy({
  alternativeTextColor = false,
  className,
  text,
}: IAgreeTermsAndCondtitionsAndPrivacyPolicyProps) {
  const {isMobileOrTablet} = useUserAgentContext();
  return (
    <div>
      <Text
        as={isMobileOrTablet ? 'body-sm' : 'body-md'}
        className={clsx({'text-white': alternativeTextColor}, className)}>
        {text ?? 'By signing up, I agree to PrescriberPoint’s '}
        <Link
          id="terms_and_conditions"
          target="_blank"
          rel="noopener noreferrer"
          href="https://prescriberpoint.com/terms"
          label="Terms & Conditions"
          linkSize={isMobileOrTablet ? 'lg' : 'xl'}
          type="secondary"
          className="inline"
        />
        {' and '}
        <Link
          id="privacy"
          target="_blank"
          rel="noopener noreferrer"
          href="https://prescriberpoint.com/privacy"
          label="Privacy Policy"
          linkSize={isMobileOrTablet ? 'lg' : 'xl'}
          type="secondary"
          className="inline"
        />
      </Text>
    </div>
  );
}
