import {Button} from '@prescriberpoint/ui';
import {FC} from 'react';
import {animateScroll} from 'react-scroll';

interface IOverviewLinkProps {
  activeItem: string;
}

const OverviewLink: FC<IOverviewLinkProps> = ({activeItem}) => (
  <Button
    id='sidebar-overview-button'
    onClick={() => animateScroll.scrollToTop()}
    variant={activeItem === '' ? 'alternative' : 'text'}
    stretched
    className='justify-start'
    classNameText={activeItem === '' ? '' : '!text-neutral-primary'}>
    Overview
  </Button>
);

export default OverviewLink;
