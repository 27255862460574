import {SECTION_IDS} from '@/constants/sectionIDs';
import {IEnhancedSections} from '@/interfaces';

// Keys should match the ones in SECTION_IDS
export const ENHANCED_SECTIONS_RESOURCES: IEnhancedSections = {
  [SECTION_IDS.priorAuthorization]: {
    benefits_investigation: [
      {
        name: 'Get benefits investigation support',
        type: 'resource',
      },
    ],
    prior_authorization_forms: [
      {
        name: 'Letter of medical necessity',
        type: 'download',
      },
      {
        name: 'Coverage authorization request',
        type: 'download',
      },
      {
        name: 'Coverage authorization appeals',
        type: 'download',
      },
    ],
    preferred_specialty_pharmacy: [
      {
        name: 'View pharmacy list',
        type: 'resource',
      },
    ],
    field_reimbursement_manager: [
      {
        name: 'Contact my FRM',
        type: 'resource',
      },
    ],
  },
  [SECTION_IDS.financialAssistance]: {
    copay_savings_program: [
      {
        name: 'Enroll in copay program',
        type: 'share',
      },
      {
        name: 'Get copay card',
        type: 'download',
      },
      {
        name: 'Adult Dermatology',
        type: 'download',
        enrrollment_and_application_forms: true,
      },
      {
        name: 'Pediatric Dermatology',
        type: 'download',
        enrrollment_and_application_forms: true,
      },
      {
        name: 'Adult Rheumatology',
        type: 'download',
        enrrollment_and_application_forms: true,
      },
    ],
    bridge_program: [
      {
        name: 'View enrollment link',
        type: 'share',
      },

      {
        name: 'Adult Dermatology',
        type: 'download',
        enrrollment_and_application_forms: true,
      },
      {
        name: 'Pediatric Dermatology',
        type: 'download',
        enrrollment_and_application_forms: true,
      },
      {
        name: 'Adult Rheumatology',
        type: 'download',
        enrrollment_and_application_forms: true,
      },
    ],
    foundation_program: [
      {
        name: 'View enrollment details',
        type: 'share',
      },

      {
        name: 'English application',
        type: 'download',
        enrrollment_and_application_forms: true,
      },
      {
        name: 'Spanish application',
        type: 'download',
        enrrollment_and_application_forms: true,
      },
    ],
    direct_purchase_program: [
      {
        name: 'Enroll in purchase program',
        type: 'share',
      },
    ],
  },
  samples: {
    items: [
      {
        name: 'View online sample form',
        type: 'share',
      },
      {
        name: 'Request rep contact me',
        type: 'share',
      },
    ],
  },
  patient_education: {
    how_to_get_started: [
      {
        name: 'See brand start guide',
        type: 'share',
      },
    ],
    instructions_for_use: [
      {name: 'Adult Plaque Psoriasis', type: 'share'},
      {name: 'Kid and Teen Plaque Psoriasis', type: 'share'},
      {name: 'Psoriatic Arthritis', type: 'share'},
      {name: 'Ankylosing Spondylitis', type: 'share'},
      {name: 'Non-Radiographic Axial Spondyloarthritis', type: 'share'},
    ],
    other_resources: [{name: 'Watch how to inject Taltz video', type: 'share'}],
    ongoing_support: [
      {name: 'Share patient iOS app', type: 'share'},
      {name: 'Share patint Android app', type: 'share'},
      {name: 'Get care team support', type: 'share'},
    ],
    patient_toolkit: [
      {name: 'Adult Plaque Psoriasis', type: 'share'},
      {name: 'Kid and Teen Plaque Psoriasis', type: 'share'},
      {name: 'Psoriatic Arthritis', type: 'share'},
      {name: 'Ankylosing Spondylitis', type: 'share'},
      {name: 'Non-Radiographic Axial Spondyloarthritis', type: 'share'},
    ],
  },
};
