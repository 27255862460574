import {Text, TextType} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';

export interface ListProps {
  title: string;
  items: string[];
  as?: TextType;
  titleType?: TextType;
  titleClassName?: ClassValue;
  listClassName?: ClassValue;
  textClassName?: ClassValue;
}

const List = ({
  title,
  items,
  as = 'body-md',
  titleType = 'body-md',
  listClassName,
  textClassName,
  titleClassName,
}: ListProps) =>
  items.length > 0 ? (
    <div className="flex flex-col space-y-2">
      <Text as={titleType} weight="bold" className={clsx(titleClassName)}>
        {' '}
        {title}{' '}
      </Text>
      <ul className="m-0 p-0 pl-5">
        {items.map((item, idx) => (
          <li
            className={clsx(
              'm-0 list-disc p-0 marker:text-neutral-darker/72',
              listClassName,
            )}
            key={`${title}item:${idx}`}>
            <Text
              as={as}
              className={clsx(textClassName)}
              variant="base"
              weight="semibold">
              {item}
            </Text>
          </li>
        ))}
      </ul>
    </div>
  ) : null;

export default List;
