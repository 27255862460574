import {useMutation, useQueryClient} from '@tanstack/react-query';
import {DLVUser} from '@/constants';
import {fetchHCPUser} from '@/queries/useHCPUser';
import {create} from '@/services/api';

export interface ILogin {
  email: string;
  password: string;
}

export interface LoginDto {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  accessToken: string;
  expiresIn: number;
  redirectTo: string | null;
  picture: string;
}

async function login(params: ILogin) {
  return await create<LoginDto>(
    'SignIn',
    {
      parentName: 'Auth',
      params,
    },
    {},
    'profile',
  );
}

export function useLogin(onSuccessCallback?: (data: DLVUser) => void) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: login,
    onSuccess: async (res: LoginDto) => {
      const data = await fetchHCPUser(queryClient, res.accessToken);
      onSuccessCallback?.({
        user: {
          npi: data?.npi ?? '',
          email: res.email,
          firstName: res.firstName,
          lastName: res.lastName,
        },
      });
    },
  });
}
