import {useInputFocus, Icon} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, useEffect, useRef} from 'react';
import {useSearchBox} from 'react-instantsearch';
import {useOnClickOutside} from 'usehooks-ts';
import MultiDrugsResults from '../MultiDrugResults';
import {useSearchListContext} from '@/context/SearchListContext';

const MultiDrugSearchbarListInput: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const {refine, query: currentRefinement} = useSearchBox();
  const {isFocused, setIsFocused} = useInputFocus(ref);
  useOnClickOutside(ref, () => {
    setIsFocused(false);
  });

  const {setIsTyping, showSelectedResults, setShowSelectedResults} =
    useSearchListContext();

  useEffect(() => {
    setIsTyping(isFocused);
  }, [isFocused, setIsTyping, showSelectedResults]);

  const handleKeyPress = (event: {key: string}) => {
    if (event.key === 'Enter') {
      setIsFocused(false);
    }
  };

  return (
    <div
      className="flex-1 relative"
      onClick={() => !showSelectedResults && setShowSelectedResults(true)}>
      <div
        className={clsx(
          'flex h-11 w-full justify-between rounded-sm border border-solid border-neutral-primary',
          {
            'border-theme-primary': isFocused,
          },
        )}>
        <div className="flex w-full">
          <div className={clsx('flex w-full max-w-full items-center')}>
            <div
              ref={ref}
              className={clsx(
                'relative flex w-full items-center justify-between px-3',
                !isFocused && currentRefinement?.length > 0 && 'space-x-1.5',
              )}>
              {!isFocused ? (
                <Icon name="Search" className="flex w-4 text-theme-primary" />
              ) : null}
              <input
                data-clarity-unmask="true"
                className={clsx(
                  'flex flex-1 border-none text-sm font-normal outline-none bg-transparent text-neutral-dark placeholder:text-neutral-dark',
                  isFocused && '-ml-1.5',
                )}
                onKeyPress={handleKeyPress}
                placeholder={'  Add another drug'}
                type="search"
                value={!showSelectedResults ? '' : currentRefinement}
                onChange={(event) => refine(event.currentTarget.value)}
                id="search_drug_input"
                data-hj-allow
                autoComplete="off"
              />
              {isFocused && currentRefinement?.length > 0 ? (
                <>
                  <button onClick={() => refine('')} id="cancel_search_button">
                    <Icon
                      name="Cancel"
                      className="flex w-3 text-neutral-dark"
                    />
                  </button>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <MultiDrugsResults />
    </div>
  );
};

export default MultiDrugSearchbarListInput;
