import {useMutation} from '@tanstack/react-query';
import {create} from '@/services/api';

export interface ICreateTaskEdgesRequest {
  taskId: string;
  caseId: string;
  assignedTo: string;
  createdBy: string;
}

const EDGES: Array<{
  resourceName: string;
  paramName: keyof ICreateTaskEdgesRequest;
  isFrom?: boolean;
}> = [
  {
    resourceName: 'HasStepsThatAre',
    paramName: 'caseId',
    isFrom: true,
  },
  {
    resourceName: 'IsAssignedTo',
    paramName: 'assignedTo',
  },
  {
    resourceName: 'IsCreatedBy',
    paramName: 'createdBy',
  },
];

export async function createTaskEdges(
  params: ICreateTaskEdgesRequest,
): Promise<void> {
  const promises: Array<Promise<unknown>> = [];

  EDGES.forEach((e) => {
    if (params[e.paramName] && typeof params[e.paramName] === 'string') {
      promises.push(
        create(
          e.resourceName,
          {
            params: {
              fromId: e.isFrom ? params[e.paramName] : params.taskId,
              toId: e.isFrom ? params.taskId : params[e.paramName],
            },
          },
          {},
          'graph',
          true,
        ),
      );
    }
  });

  await Promise.all(promises);
}

export function useCreateTaskEdges(options = {}) {
  return useMutation({mutationFn: createTaskEdges, ...options});
}
