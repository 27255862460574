import {useEffect} from 'react';
import {useCookies} from 'react-cookie';
import {INTERNAL_TRAFFIC_COOKIE, SPLIT_USER_COOKIE} from '@/constants/global';
import {useHandleSetCookies} from '@/hooks';

function useClientJSFingerprint() {
  const [cookies] = useCookies([SPLIT_USER_COOKIE, INTERNAL_TRAFFIC_COOKIE]);
  const {handleSetCookie} = useHandleSetCookies();
  useEffect(() => {
    const generateFingerprint = async () => {
      if (
        typeof window !== 'undefined' &&
        !cookies[SPLIT_USER_COOKIE] &&
        !cookies[INTERNAL_TRAFFIC_COOKIE]
      ) {
        try {
          const clientjs = await import('clientjs');
          const client = new clientjs.ClientJS();
          const fingerprint = client.getFingerprint();
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 60); // Set expiration to 60 days from now
          handleSetCookie(SPLIT_USER_COOKIE, fingerprint, {
            path: '/',
            expires: expirationDate,
          });
        } catch (error) {
          console.error(error);
        }
      }
    };
    generateFingerprint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useClientJSFingerprint;
