import {useQuery} from '@tanstack/react-query';
import {PriorAuthorizationFormsDto} from '@/models';
import {read} from '@/services/api';

export function createPAFormsByBrandQueryKey(
  setId: string,
  carrierId?: string,
) {
  return carrierId
    ? ['paFormsByBrand', setId, carrierId]
    : ['paFormsByBrand', setId];
}
export async function getPAFormsByBrand(
  setId: string,
  carrierId?: string,
): Promise<PriorAuthorizationFormsDto | null> {
  let data = null;

  if (!setId) return data;

  try {
    data = await read<PriorAuthorizationFormsDto>(
      setId,
      {
        resourceId: 'Documents',
        parentName: 'Brand',
        params: carrierId
          ? {
              carrierId: carrierId,
            }
          : {},
      },
      {},
      'profile',
    );

    if (data && data.carriers) {
      data.carriers.forEach((e) => {
        if (e.documents) {
          e.documents.forEach((d, i) => (d.resourceId = i));
          e.documents.sort((a, b) => {
            const dateA = new Date(a.lastUpdatedDate).getTime();
            const dateB = new Date(b.lastUpdatedDate).getTime();
            return dateB - dateA;
          });
        }
      });
    }

    return data;
  } catch (e) {
    console.error(e);
  }
  return data;
}

export function usePAFormsByBrand(
  setId: string,
  carrierId?: string,
  options = {},
) {
  return useQuery<PriorAuthorizationFormsDto | null>({
    queryKey: createPAFormsByBrandQueryKey(setId, carrierId),
    queryFn: async () => await getPAFormsByBrand(setId, carrierId),
    ...options,
  });
}

export function getPAFormsByBrandPrefetchQuery(
  setId: string,
  carrierId?: string,
  options = {},
) {
  return {
    queryKey: createPAFormsByBrandQueryKey(setId, carrierId),
    getFn: async () => await getPAFormsByBrand(setId, carrierId),
  };
}
