import {useQuery} from '@tanstack/react-query';
import {read} from '@/services/api';

export interface IBrand {
  id: string;
  setId: string;
  name: string;
}

export async function getBrandBySetId(setId: string): Promise<IBrand | null> {
  let data = null;

  if (!setId) return data;

  return await read(
    'Brand',
    {
      resourceId: setId,
    },
    {},
    'graph',
    true,
  );
}

export function useBrandBySetId(setId: string, options = {}) {
  return useQuery<IBrand | null>({
    queryKey: ['brand-by-set-id', setId],
    queryFn: () => getBrandBySetId(setId),
    ...options,
  });
}
