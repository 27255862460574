import {getHighlightedText, Icon, Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, useMemo} from 'react';
import {useInstantSearch} from 'react-instantsearch';
import {useSearchListContext} from '@/context/SearchListContext';
import {capitalize} from '@/utils/string';

const Results: FC = () => {
  const {
    selectedResults,
    removeFromSelectedResults,
    addToSelectedResults,
    isSearchDisabled,
    isTyping,
    wasModified,
    showSelectedResults,
  } = useSearchListContext();

  const {results: searchResults} = useInstantSearch();

  const isResultSelected = (set_id: string) =>
    selectedResults.find((r) => r.set_id === set_id);

  const canRemove = useMemo(
    () => !isTyping && wasModified,
    [isTyping, wasModified],
  );

  const showSearchResults = useMemo(
    () => !!searchResults?.query && !isSearchDisabled,
    [isSearchDisabled, searchResults?.query],
  );

  return (
    <div>
      {!!isSearchDisabled && showSelectedResults && (
        <div className="my-1.5 bg-neutral-lighter-alt px-3 py-2">
          <Text size="sm">
            You’ve reached the maximum drug limit. Remove a drug to add a new
            one.
          </Text>
        </div>
      )}
      {!selectedResults?.length && (
        <div className="my-1.5 bg-neutral-lighter-alt px-3 py-2">
          <Text size="sm">
            Add two drugs to compare potential drug interactions.
          </Text>
        </div>
      )}
      {showSelectedResults &&
        selectedResults?.map((result) => (
          <div
            onClick={() =>
              canRemove && removeFromSelectedResults(result.set_id)
            }
            key={result.set_id}
            className={clsx(
              'flex items-center px-4 py-2 shadow-low-bottom',
              canRemove &&
                'cursor-pointer active:!bg-neutral-light devicehover:hover:bg-neutral-lighter',
            )}>
            <div className="flex-1">
              <Text
                tag="div"
                size="sm"
                variant={canRemove ? 'secondary' : 'tertiary'}>
                {result?.generic_name?.toLocaleLowerCase()}
              </Text>
              <span
                className={clsx(
                  canRemove ? 'text-neutral-primary' : 'text-neutral-tertiary',
                )}>
                {isTyping
                  ? getHighlightedText(
                      capitalize(result.label_title),
                      searchResults?.query,
                    )
                  : capitalize(result.label_title)}
              </span>
            </div>
            {!canRemove && (
              <Text size="sm" variant="tertiary" className="px-1">
                Added
              </Text>
            )}
            <Icon
              className={clsx(
                'h-5 w-5',
                canRemove ? 'text-error' : 'text-neutral-tertiary',
              )}
              name={canRemove ? 'SubstractFilled' : 'Yes'}
            />
          </div>
        ))}
      {showSearchResults &&
        searchResults?.hits?.map(
          (result) =>
            !isResultSelected(result.objectID) && (
              <div
                onClick={() =>
                  addToSelectedResults({
                    set_id: result.objectID,
                    label_title: result.label_title,
                    slug: result.slug,
                    generic_name: result.names[1],
                    labeler: result.labeler,
                  })
                }
                key={result.objectID}
                className="group flex cursor-pointer items-center px-4 py-2 shadow-low-bottom active:!bg-neutral-light devicehover:hover:bg-neutral-lighter">
                <div className="flex-1">
                  <Text tag="div" variant="secondary" size="sm">
                    {result?.names[1]?.toLocaleLowerCase()}
                  </Text>
                  <span className="text-neutral-primary">
                    {getHighlightedText(
                      capitalize(result.label_title),
                      searchResults?.query,
                    )}
                  </span>
                </div>
                <Icon
                  name="AddCircleFilled"
                  className="w-5 text-theme-primary"
                />
              </div>
            ),
        )}
    </div>
  );
};

const CustomResults = Results;

export default CustomResults;
