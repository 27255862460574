import {useMutation} from '@tanstack/react-query';
import {create} from '@/services/api';

export interface ICreatePersonRequest {
  email: string;
  name?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  role?: string;
  primaryWorkZipCode?: string;
  inviteAccepted?: boolean;
}

export interface ICreatePersonDto extends ICreatePersonRequest {
  id: string;
}

export async function createPerson(
  params: ICreatePersonRequest,
): Promise<ICreatePersonDto> {
  return await create(
    'Person',
    {
      params,
    },
    {},
    'graph',
    true,
  );
}

export function useCreatePerson(options = {}) {
  return useMutation({mutationFn: createPerson, ...options});
}
