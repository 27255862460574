// Detection if an element is added to DOM
function detectIfElementAddedtoDOM(
  container: string,
  elementClass: string,
  callback: () => void,
) {
  const observer = new MutationObserver(function (mutations_list) {
    mutations_list.forEach(function (mutation) {
      mutation.addedNodes.forEach(function (added_node) {
        // @ts-ignore
        if (added_node.classList?.contains(elementClass)) {
          callback();
          observer.disconnect();
        }
      });
    });
  });

  observer.observe(document.querySelector(container)!, {
    subtree: false,
    childList: true,
  });
}

export default detectIfElementAddedtoDOM;
