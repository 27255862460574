import {IconButton, Icon, customEvent} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {useRouter} from 'next/router';
import {FC, useCallback, useState} from 'react';
import {Bookmark, Share} from '@/components';
import {CEVENT_SHARE_BRAND_PAGE} from '@/constants';
import {PROTOCOL_HOSTNAME} from '@/constants/global';

interface HeroIconsProps {
  drugName: string;
  shareClassName?: string;
}

const HeroIcons: FC<HeroIconsProps> = ({shareClassName, drugName}) => {
  const [isShareOpen, setIsShareOpen] = useState(false);
  const router = useRouter();

  const handleShareClick = useCallback(() => {
    customEvent(CEVENT_SHARE_BRAND_PAGE, {
      brand: drugName,
    });
    setIsShareOpen(true);
  }, [drugName]);

  return (
    <div className="relative flex px-4 text-neutral-primary xs:px-2">
      <Bookmark title={drugName} id="summary_like" />
      <IconButton
        id="summary_share"
        icon={<Icon name="ShareOutline" className="h-5" />}
        onClick={handleShareClick}
        tooltip="Share"
      />
      <div
        className={clsx('absolute right-0 top-8 sm:w-[320px]', shareClassName)}>
        <Share
          id="summary_share_info"
          isShareOpen={isShareOpen}
          handleClose={() => setIsShareOpen(false)}
          renderUnderButton
          shareLink={`${PROTOCOL_HOSTNAME}${router.asPath}`}
          resource={drugName}
          drugName={drugName}
          section="share"
        />
      </div>
    </div>
  );
};

export default HeroIcons;
