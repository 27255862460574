import {FC} from 'react';
import {useHits} from 'react-instantsearch';
import {CustomHighlight} from './CustomHighlight';

interface HitsProps {
  onHitClick: (slug: string) => void;
}

const Hits: FC<HitsProps> = ({onHitClick}) => {
  const {hits} = useHits();

  return (
    <>
      {hits?.length > 0 ? (
        hits.map((hit: any, idx: number) => (
          <div
            className="flex h-9 items-center p-2 text-neutral-primary hover:bg-neutral-lighter"
            key={`hit-${idx}`}
            onClick={() => onHitClick(hit.slug)}>
            <CustomHighlight attribute="label_title" hit={hit} />
          </div>
        ))
      ) : (
        <div className="flex h-9 items-center p-2 text-neutral-primary">
          No results...
        </div>
      )}
    </>
  );
};

export const CustomHits = Hits;
