export interface HighlightResult {
  names: HighlightResultName[];
}

export interface HighlightResultName {
  value: string;
  matchLevel: string;
  matchedWords: string[];
  fullyHighlighted?: boolean;
}

export interface DrugSearchResult {
  indications: string[];
  names: string[];
  label_title: string;
  generic: string;
  label_type: string;
  classes: string[];
  ingredients: string[];
  slug: string;
  page_class: string;
  labeler: string;
  objectID: string;
  _highlightResult: HighlightResult;
  __position: number;
}

export interface DrugsSavedByUserCookie {
  [key: string]: {
    label_title: string;
    objectID: string;
    labeler: string;
  };
}

export interface DrugsSavedByUserResult
  extends Pick<
    DrugSearchResult,
    'label_title' | 'slug' | 'objectID' | 'labeler'
  > {}

export interface DrugsSavedByUserResults {
  recentSearch: DrugsSavedByUserResult[];
}
export const addDrugsSavedByUserCookie = (
  currentCookies: DrugsSavedByUserCookie,
  recentSearch: DrugsSavedByUserResult,
  withLimit: boolean = true,
) => {
  const {slug, label_title, labeler, objectID} = recentSearch;
  let newCookies: DrugsSavedByUserCookie = {};
  newCookies[slug] = {label_title, objectID, labeler};

  if (currentCookies) {
    const keys = Object.keys(currentCookies);

    let keysFiltered = keys.filter((key) => key !== slug);

    if (withLimit) {
      keysFiltered = keysFiltered.slice(0, 4);
    }

    keysFiltered.forEach((key) => {
      if (currentCookies[key]) {
        newCookies[key] = currentCookies[key];
      }
    });
  }
  return newCookies;
};

export const deleteDrugsSavedByUserCookie = (
  currentCookies: DrugsSavedByUserCookie,
  drugToDelete: DrugsSavedByUserResult,
) => {
  const {slug} = drugToDelete;
  const keys = Object.keys(currentCookies);
  let newCookies: DrugsSavedByUserCookie = {};

  keys
    .filter((key) => key !== slug)
    .forEach((key) => {
      if (currentCookies[key]) {
        newCookies[key] = currentCookies[key];
      }
    });

  return newCookies;
};

export function convertToRecentSearchCookie(
  recentSearches: DrugsSavedByUserResult[],
): DrugsSavedByUserCookie {
  const cookies: DrugsSavedByUserCookie = {};
  recentSearches.forEach((search) => {
    cookies[search.objectID] = {
      label_title: search.label_title,
      objectID: search.objectID,
      labeler: search.labeler,
    };
  });
  return cookies;
}

export function convertToResults(
  cookies: DrugsSavedByUserCookie,
): DrugsSavedByUserResult[] {
  const keys = Object.keys(cookies);
  return keys.map((key) => ({
    slug: key,
    label_title: cookies[key].label_title,
    objectID: cookies[key].objectID,
    labeler: cookies[key].labeler,
  }));
}
