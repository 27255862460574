import {Icon, IconButton, Text, SmallTooltip} from '@prescriberpoint/ui';
import {FC} from 'react';

interface HowQrWorksHelperBoxProps {
  show: boolean;
  onDismiss: () => void;
}
const HowQrWorksHelperBox: FC<HowQrWorksHelperBoxProps> = ({
  show = true,
  onDismiss,
}) =>
  show ? (
    <div className="min-w-[672px] rounded-lg bg-neutral-dark p-4 sm:w-full">
      <div className="mb-6 flex justify-between">
        <Text as="title-md" className="!text-white">
          To share resource; ask patient to:
        </Text>
        <SmallTooltip label="Dismiss forever" openOnHover>
          <IconButton
            className="!h-7 !w-7 [&>span]:hover:text-theme-primary"
            size="sm"
            onClick={onDismiss}
            id="close_disclaimer_button"
            icon={<Icon name="Cancel" className="flex h-3  w-3 text-white" />}
          />
        </SmallTooltip>
      </div>

      <div className="grid grid-cols-6 gap-3 py-1">
        <div className="col-span-1 flex  flex-col rounded-md bg-white px-2 py-4">
          <Text variant="secondary">1.</Text>
          <Text as="body-sm" weight="bold">
            Pull out phone
          </Text>
        </div>
        <div className="col-span-1 flex  flex-col rounded-md bg-white px-2 py-4">
          <Text variant="secondary">2.</Text>
          <Text as="body-sm" weight="bold">
            Open camera
          </Text>
        </div>
        <div className="col-span-2 flex justify-between rounded-md bg-white px-2 py-4">
          <div className="flex flex-col">
            <Text variant="secondary">3.</Text>
            <Text as="body-sm" weight="bold">
              Scan QR code with camera
            </Text>
          </div>
          <div>
            <Icon
              name="Qr"
              className="flex h-12 w-12 items-center justify-center rounded border-dashed border-gold-500"
            />
          </div>
        </div>
        <div className="col-span-2 flex justify-between rounded-md bg-white px-2 py-4">
          <div className="flex flex-1 flex-col justify-center">
            <Text variant="secondary">4.</Text>
            <Text as="body-sm" weight="bold">
              Tap link
            </Text>
          </div>
          <div className="flex flex-1 flex-col items-end justify-end">
            <Icon
              name="Qr"
              className="mr-3 flex h-9 w-9 items-center justify-center rounded border-dashed border-gold-500"
            />
            <Icon
              name="View"
              className="-mt-2 flex items-center justify-center"
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;

export default HowQrWorksHelperBox;
