import {useMutation} from '@tanstack/react-query';
// import {create} from '@/services/api';

interface IAcceptInvitationRequest {
  email: string;
  password: string;
}

export async function createAcceptInvitation({
  email,
  password,
}: IAcceptInvitationRequest) {
  const data = await new Promise((resolve) =>
    setTimeout(
      () => resolve(`Invitation acceptance sent with ${email} and ${password}`),
      1000,
    ),
  );
  // const data = await create('resource', {});
  return data;
}

export function useCreateAcceptInvitation(options = {}) {
  return useMutation({mutationFn: createAcceptInvitation, ...options});
}
