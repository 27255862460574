import {Text, Link, TextType} from '@prescriberpoint/ui';
import {ReactNode} from 'react';
import {useUserAgentContext} from '@/context/UserAgentContext';
import {toSnakeCase} from '@/utils';

export interface FooterCTA {
  label: string;
  callback?: () => void;
  button: string;
  href?: string;
  rightIcon?: () => ReactNode;
  target?: string;
}

interface AuthFooterProps {
  callToActions: FooterCTA[];
  as?: TextType;
}

export default function AuthFooter({callToActions, as}: AuthFooterProps) {
  const {isMobileOrTablet} = useUserAgentContext();

  return (
    <div className="pb-2 pt-2">
      <div className="flex w-full flex-col space-y-2">
        {callToActions.map((cta, idx) => (
          <div className="inline items-center" key={idx}>
            {cta.label ? (
              <Text
                as={as || isMobileOrTablet ? 'body-sm' : 'body-md'}
                className="mr-1">
                {cta.label}
              </Text>
            ) : null}
            <Link
              id={toSnakeCase(cta.button)}
              href={cta?.href ?? ''}
              onClick={cta.callback}
              label={cta.button}
              target={cta?.target}
              linkSize={isMobileOrTablet ? 'lg' : 'xl'}
              type="secondary"
              className="inline"
            />
            {cta.rightIcon && cta.rightIcon()}
          </div>
        ))}
      </div>
    </div>
  );
}
