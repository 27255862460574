import debounce from 'lodash.debounce';
import {useState, useEffect, useCallback} from 'react';
import {useToggle} from 'usehooks-ts';

const hasClamping = (el: HTMLDivElement) => {
  const {clientHeight, scrollHeight} = el;
  return clientHeight ? clientHeight !== scrollHeight : true;
};

/**
 * Checks if clamping is required for the container element.
 *
 * Due to a potential delay in the availability of the `scrollHeight` property in some cases, such as in Safari,
 * a `setTimeout` is used as a fallback mechanism for accurate clamping detection.
 * If `forceHasClampingValidation` is not enabled, the initial check for clamping is performed without delay.
 * However, if `forceHasClampingValidation` is enabled, the initial check is followed by a delayed recheck
 * to ensure accurate clamping detection in cases where the `scrollHeight` property is not immediately available.
 * This fallback mechanism helps in correctly determining the need for clamping on the first render in Safari.
 *
 * @param containerRef - Reference to the container element.
 * @param clampClass - CSS class applied for clamping.
 * @param forceHasClampingValidation - (Optional) If true, forces a delayed recheck for clamping.
 * @returns Object containing clamping state and toggle functions.
 */

// Code adapted from https://javascript.plainenglish.io/creating-a-read-more-collapsible-component-in-reactjs-6d55fbc4ff0f
export function useClamp(
  containerRef: React.RefObject<HTMLDivElement>,
  clampClass = 'line-clamp-2',
  forceHasClampingValidation?: boolean,
) {
  const [textClamped, toggleClamp] = useToggle();
  const [showClampButton, setShowClampButton] = useState(false);

  const checkButtonAvailability = useCallback(() => {
    if (containerRef.current) {
      // Save current state to reapply later if necessary.
      const hadClampClass = containerRef.current.classList.contains(clampClass);
      // Make sure that CSS clamping is applied if aplicable.
      if (!hadClampClass) containerRef.current.classList.add(clampClass);
      // Check for clamping and show or hide button accordingly.

      let clamping = hasClamping(containerRef.current);
      if (forceHasClampingValidation && !clamping) {
        setTimeout(() => {
          if (containerRef.current) {
            setShowClampButton(hasClamping(containerRef.current));
          }
        }, 500);
      }
      setShowClampButton(clamping);
      // Sync clamping with local state.
      if (!hadClampClass) containerRef.current.classList.remove(clampClass);
    }
  }, [clampClass, containerRef, forceHasClampingValidation]);

  useEffect(() => {
    const debouncedCheck = debounce(checkButtonAvailability, 50);
    checkButtonAvailability();
    window.addEventListener('resize', debouncedCheck);
    return () => {
      window.removeEventListener('resize', debouncedCheck);
    };
  }, [containerRef, clampClass, checkButtonAvailability]);

  return {textClamped, toggleClamp, showClampButton};
}
