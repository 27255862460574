import {QueryClient, useQuery} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {useCurrentDrug} from '@/hooks/useCurrentDrug';
import {PharmacyDetailsDto} from '@/models';
import {PharmacyDto} from '@/models/pharmacyDtoV2';
import {read} from '@/services/api';

export async function getPharmacyV2(
  pharmacyId: string | undefined,
  drugName: string,
): Promise<PharmacyDetailsDto | null> {
  let data: PharmacyDto | null = null;

  if (!pharmacyId) return data;

  try {
    data = await read<PharmacyDto>(
      'pharmacies/locations',
      {
        resourceId: pharmacyId,
      },
      {},
      'nextApi',
    );
  } catch (e) {
    console.error(e);
  }

  function getDistribution() {
    if (
      data?.pharmacy_exclusive_drugs?.findIndex(
        (e) => e.product_name === drugName,
      ) !== -1
    )
      return 'exclusive';
    if (
      data?.limited_distribution_drugs?.findIndex(
        (e) => e.product_name === drugName,
      ) !== -1
    )
      return 'limited';
    return 'unknown';
  }

  if (!data) return null;

  return {
    name: data.company_information.consolidatedname || '',
    patient_services:
      data.patient_services?.map((e) => e.patient_service) || [],
    pharma_services:
      data.pharmaceutical_services?.map((e) => e.pharmaceutical_service) || [],
    contracted_plans: data.plan_contracts?.map((e) => e.Contract) || [],
    objectID: data.id || '',
    // TODO: description is missing
    description: '',
    phone: data.contact_information?.phone || '',
    email: data.contact_information.email,
    payer_services: data.payer_services?.map((e) => e.payer_service) || [],
    distribution: getDistribution(),
    address: data.company_information?.fulladdress!,
    logo: '', // TODO: it's not coming
    limited_brands:
      data.limited_distribution_drugs.map((drug) => ({
        set_id: drug.set_id,
        name: drug.product_name,
        href: '',
      })) || [],
    exclusive_brands:
      data.pharmacy_exclusive_drugs.map((drug) => ({
        set_id: drug.set_id,
        name: drug.product_name,
        href: '',
      })) || [],
    websiteUrl: data.company_information.url || '',
  };
}

export function usePharmacyV2(pharmacyId: string | undefined, options = {}) {
  const {drugName} = useCurrentDrug();
  return useQuery<PharmacyDetailsDto | null>({
    queryKey: ['pharmacyV2', pharmacyId],
    queryFn: () => getPharmacyV2(pharmacyId, drugName),
    refetchOnMount: false,
    ...options,
  });
}

export function getPharmacyPrefetchQueryV2(
  pharmacyId: string | undefined,
  drugName: string,
): IPrefetchedQuery {
  return {
    queryKey: ['pharmacyV2', pharmacyId],
    getFn: async () => await getPharmacyV2(pharmacyId, drugName),
  };
}

export async function fetchQueryPharmacyV2(
  pharmacyId: string | undefined,
  drugName: string,
  queryClient: QueryClient,
): Promise<PharmacyDetailsDto | null> {
  return await queryClient.fetchQuery({
    queryKey: ['pharmacyV2', pharmacyId],
    queryFn: async () => await getPharmacyV2(pharmacyId, drugName),
  });
}
