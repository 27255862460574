import {useMutation} from '@tanstack/react-query';
import {create} from '@/services/api';

export interface IForgotPassword {
  email: string;
}

async function forgotPassword(params: IForgotPassword) {
  return await create<IForgotPassword>(
    'ResetPassword',
    {
      parentName: 'Auth',
      params,
    },
    {},
    'profile',
  );
}

export function useForgotPassword() {
  return useMutation({mutationFn: forgotPassword});
}
