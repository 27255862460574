import {useMemo} from 'react';
import {useCurrentSlug} from './useCurrentSlug';
import {useEnhanced} from './useEnhanced';

export function useIsOncology() {
  const {setId} = useCurrentSlug();
  const {enhancedContent} = useEnhanced(setId);

  const isOncologyDrug = useMemo(
    () => !!enhancedContent?.therapeuticAreas.find((ta) => ta === 'Oncology'),
    [enhancedContent],
  );
  return {isOncologyDrug};
}
