function parseJwt(token: string): Record<string, any> {
  if (!token) return {};
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeBase64(base64);
  return JSON.parse(jsonPayload);
}

function decodeBase64(base64: string): string {
  let decodedString = '';

  if (typeof atob === 'function') {
    decodedString = atob(base64);
  } else if (typeof Buffer === 'function') {
    const buffer = Buffer.from(base64, 'base64');
    decodedString = buffer.toString('utf-8');
  } else {
    throw new Error('No compatible base64 decoding function available.');
  }

  return decodeURIComponent(
    Array.prototype.map
      .call(
        decodedString,
        (c: string) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2),
      )
      .join(''),
  );
}

export default parseJwt;
