import {Button, Icon, Text, useIsMobileOrTablet} from '@prescriberpoint/ui';
import {useCallback} from 'react';
import SearcherBrands from './components';
import SelectedBrands from './components/SelectedBrands';
import {useSearchBrandsContext} from '@/context';
import {EMGALITY_DATA} from '@/data/store';

interface SearchbarBrandsProps {
  onContinue: () => void;
}

const NON_OTC_DRUGS =
  'NOT label_type:"HUMAN OTC DRUG LABEL" AND NOT label_type:"HUMAN OTC DRUG"';

const NOT_EMGALITY = `NOT objectID:"${EMGALITY_DATA.setId}"`;

const FILTER = `${NON_OTC_DRUGS} AND ${NOT_EMGALITY}`;

const SelectBrandsSearch = ({onContinue}: SearchbarBrandsProps) => {
  const {openSearch, setOpenSearch, selectedResults} = useSearchBrandsContext();
  const isMobileOrTablet = useIsMobileOrTablet();

  const handleClick = () => {
    setOpenSearch(true);
  };

  const renderSelectedBrands = useCallback(
    () =>
      selectedResults.length > 0 ? (
        <SelectedBrands className='!px-0' onContinue={onContinue} />
      ) : (
        <div className='flex w-full flex-col gap-4'>
          <div className='flex flex-col rounded-lg border border-dashed border-neutral-tertiary p-6 text-center'>
            <Text as='body-md' weight='extrabold' variant='secondary' size='md'>
              Select brands to continue
            </Text>
            <Text as='body-sm' weight='semibold' variant='secondary'>
              You can choose more than one brand
            </Text>
          </div>
          <Button
            id='continue'
            size='lg'
            onClick={onContinue}
            stretched
            disabled={!selectedResults.length}>
            Continue
          </Button>
        </div>
      ),
    [selectedResults, onContinue],
  );

  return (
    <div className='flex flex-col gap-y-6 p-6 md:px-12 md:py-10'>
      <Text as='headline-lg' className='!text-3xl md:!text-4xl'>
        Which brands do you represent?
      </Text>
      <div className='flex flex-col gap-y-4 md:relative md:gap-y-0'>
        <div className='flex w-full flex-col gap-y-1 md:hidden'>
          <Text as='body-sm' weight='bold' size='xs'>
            Brands
          </Text>
          <button
            id='search_by_brand_name'
            onClick={handleClick}
            className='flex items-center gap-2 rounded-lg border border-solid border-neutral-tertiary bg-white p-3'>
            <Icon
              name='SearchInfo'
              className='inline-flex w-5 text-theme-primary'
            />
            <Text as='body-md' weight='semibold' variant='tertiary'>
              Search by Brand Name
            </Text>
          </button>
        </div>
        {openSearch || !isMobileOrTablet ? (
          <SearcherBrands filters={FILTER} onContinue={onContinue} />
        ) : (
          renderSelectedBrands()
        )}
      </div>
    </div>
  );
};

export default SelectBrandsSearch;
