import {IContentSection, IPageSection} from '@/interfaces';

export const FINANCIAL_ASSISTANCE_SECTION_IDS = {
  copay: 'sectionCopaySavingsProgram',
  bridge: 'sectionBridgeProgram',
  foundation: 'sectionFoundationalPrograms',
};

export const FINANCIAL_ASSISTANCE_SUPPORT_SECTIONS: IContentSection[] = [
  {
    title: 'Copay savings program',
    sectionId: FINANCIAL_ASSISTANCE_SECTION_IDS.copay,
    description: {
      standard:
        'For eligible patients with commercial insurance, to help save on prescription costs.',
      enhanced:
        'For eligible patients with commercial insurance, to help save on prescription costs.',
    },
    question: 'How do copay programs help save patients money?',
    answer: [
      {
        title: 'Overview',
        items: [
          'Reduce patient OOP costs for drug (and occasionally for drug administration/infusion costs or drug-related test costs)',
        ],
      },
      {
        title: 'Patient benefit',
        items: [
          'A portion (or all) of patient OOP (deductible, copay), typically up to monthly and/or annual max',
        ],
      },
      {
        title: 'Patient eligibility',
        items: [
          'Patient must enroll or activate (may permit HCPs to enroll on patient’s behalf for HCP-administered drugs)',
          'Generally, must have commercial insurance (rarely, may permit uninsured patients to use)',
          'May never be used with government insurance',
        ],
      },
      {
        title: 'How to sign up',
        items: [
          'Cards may be downloadable digital cards or hard copies',
          'Some pharmacos offer debit cards with pre-loaded copay benefit',
          'Typically, available through multiple channels (e.g., rep to HCP to patient; pharmacy to patient; patient via website, Hub live agent, or copay vendor (live agent or IVR); patient and HCP via Hub enrollment form)',
          'Some HCP-administered product programs permit HCPs to enroll on a patient’s behalf through via Hub form',
        ],
      },
    ],
    standard: true,
    enhanced: true,
    tags: [
      {
        title: 'commercial only',
      },
    ],
    // fixedResources: ['Enroll patient', 'Get co-pay card'],
  },
  {
    title: 'Bridge program',
    sectionId: FINANCIAL_ASSISTANCE_SECTION_IDS.bridge,
    description: {
      standard:
        'For commercially insured patients to get started on therapy while awaiting insurance approval. ',
      enhanced:
        'For commercially insured patients to get started on therapy while awaiting insurance approval. ',
    },
    question: 'How do bridge programs help patients?',
    answer: [
      {
        title: 'Overview',
        items: [
          'Provide patient immediate access to therapy during an insurance delay (typically new starts; some may cover change in insurance)',
          'Limited time/ fill (typically 7-30 days; some may offer additional fill for continued delay up to certain limit)',
        ],
      },
      {
        title: 'Patient benefit',
        items: ['100% free (outside of insurance)'],
      },
      {
        title: 'Patient eligibility',
        items: [
          'HCP must enroll patient',
          'May be limited to commercially insured patients (i.e., no government beneficiaries); some programs may allow government beneficiaries',
        ],
      },
      {
        title: 'How to sign up',
        items: ['Typically HCP assisted enrollment (via form)'],
      },
    ],
    standard: true,
    enhanced: true,
    tags: [
      {
        title: 'commercial only',
      },
    ],
  },
  {
    title: 'Foundation programs',
    sectionId: FINANCIAL_ASSISTANCE_SECTION_IDS.foundation,
    description: {
      standard:
        'For uninsured and underinsured patients when affordability options are limited.',
      enhanced:
        'For uninsured and underinsured patients when affordability options are limited.',
    },
    question: 'How do foundations help patients?',
    answer: [
      {
        title: 'Overview',
        items: [
          'Charitable 501(c)(3) organizations provide direct cost-sharing and other support (e.g., travel, counseling) through disease-state funds to indigent patients on first-come first-served basis',
          'These organizations may receive financial contributions from drug manaufacturers for particular disease-state funds that cannot provide funds directly to patients - the foundation must be independent/unaligned',
        ],
      },
      {
        title: 'Patient benefit',
        items: [
          'Patients apply for grants that cover a portion (or all) of their out-of-pocket costs (deductibles and copays) until the grant is exhausted',
        ],
      },
      {
        title: 'Patient eligibility',
        items: [
          'Patients must apply and meet eligibility criteria including income level (typically a multiple of federal poverty line), specific diagnosis, insurance status, etc.',
        ],
      },
      {
        title: 'How to sign up',
        items: [
          'Patients submit proof of out-of-pocket drug costs to charities for reimbursement',
        ],
      },
    ],
    standard: true,
    enhanced: true,
    tags: [
      {
        title: 'under insured',
        disabled: true,
      },
      {
        title: 'no insurance',
        disabled: true,
      },
      {
        title: 'goverment insurance',
        disabled: true,
      },
      {
        title: '65+',
        disabled: true,
      },
    ],
    // fixedResources: [
    //   'Enrollment Details',
    //   'English Application',
    //   'Spanish Application',
    // ],
  },
  /*{
    title: 'Direct purchase program',
    sectionId: 'direct_purchase_program',
    description: {
      standard:
        'Allows patients to purchase {brandName} from manufacture, regardless of insurance status.',
      enhanced:
        'Allows patients to purchase {brandName} directly from manufacture, regardless of insurance status.',
    },
    question: 'How do direct purchase programs help patients?',
    answer: [
      {
        title: 'Overview',
        items: [
          'Allows patients to purchase the drug for a fixed cash price or buy directly from the manufacturer outside of any insurance benefit',
        ],
      },
      {
        title: 'Patient benefit',
        items: [
          'The patient receieves a discount off of the product’s list price',
        ],
      },
      {
        title: 'Eligibility',
        items: [
          'Typically the cash price is made available to any patient regardless of income or insurance status, but some may exclude government patients (rarely means-tested)',
          'Most of the time, patients must agree to purchase the product outside of the insurance benefit for the calendar year',
        ],
      },
      {
        title: 'How to sign up',
        items: [
          'Patients typically enroll through website, Hub or other program vendor',
          'Some programs provide a digital downloadable card or hard copy card for patients to use at retail pharmacies',
          'Some programs ship product directly to patient from a non-commercial pharmacy vendor (rarely a specialty pharmacy)',
          'If Medicare patients participate, manufacturers will typically send letters notifying Part D plans of purchase program enrollment',
        ],
      },
    ],
    standard: true,
    enhanced: true,
  },*/
];

const FINANCIAL_ASSISTANCE_SUPPORT: IPageSection = {
  title: '{brandName} Financial Assistance Options',
  description: {
    standard: 'Learn how savings programs can help your patients.',
    enhanced:
      'Save your patients money and help them get started on {brandName} faster.',
  },
  sections: FINANCIAL_ASSISTANCE_SUPPORT_SECTIONS,
};

export {FINANCIAL_ASSISTANCE_SUPPORT};
