import {
  useState,
  createContext,
  FC,
  useContext,
  useRef,
  RefObject,
} from 'react';
import {useToggle} from 'usehooks-ts';
import {useUserAgentContext} from './UserAgentContext';
import {UserPanelType} from '@/interfaces/userMenu';

export const MobileContentContext = createContext({
  showSearchBar: false,
  isMenuOpen: false,
  toggleMenuOpen: () => {},
  setShowSearchBar: (_bool: boolean) => {},
  inputRef: null as RefObject<HTMLInputElement> | null,
  focusSearchbar: () => {},
  showCTAMenu: true,
  setShowCTAMenu: (_bool: boolean) => {},
});

interface MobileContentProviderProps {
  children: React.ReactNode;
  userPanel?: UserPanelType;
}

export const MobileContentProvider: FC<MobileContentProviderProps> = ({
  children,
  userPanel,
}) => {
  const [isMenuOpen, toggleMenuOpen] = useToggle(userPanel ? true : false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const {isMobileOrTablet} = useUserAgentContext();
  const [showCTAMenu, setShowCTAMenu] = useState(true);

  const focusSearchbar = () => {
    inputRef?.current?.focus({preventScroll: true});
    setTimeout(() => {
      isMobileOrTablet && window.scrollTo({top: 0});
    }, 200);
  };

  const value = {
    showSearchBar,
    setShowSearchBar,
    isMenuOpen,
    toggleMenuOpen,
    inputRef,
    focusSearchbar,
    showCTAMenu,
    setShowCTAMenu,
  };
  return (
    <MobileContentContext.Provider value={value}>
      {children}
    </MobileContentContext.Provider>
  );
};

export const useMobileContentContext = () => useContext(MobileContentContext);
