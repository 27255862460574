export const REPORT_ADVERSE_EVENT_SECTION = {
  title: 'Report adverse event', //'Need to report a {brandName}  issue?',
  description:
    "You can report a {brandName} adverse event on MedWatch, the FDA's medical product safety reporting program for health professionals, patients and consumers.",
  link: {
    title: 'Report adverse event',
    href: 'https://www.accessdata.fda.gov/scripts/medwatch/index.cfm?action=professional.reporting1',
  },
  confirmDialog: {
    title: 'Report {brandName} Adverse Event',
    description:
      'View the FDA adverse reporting online form for healthcare professionals (HCP’s). You are leaving PrescriberPoint.',
  },
};
