import {useQuery} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {getDataFromLocalOrCdn} from '@/utils/files';

export interface IDisabledSections {
  [setId: string]: {
    sections: string[];
  };
}

export async function getDisabledSections(): Promise<IDisabledSections> {
  const data = await getDataFromLocalOrCdn<IDisabledSections>(
    'disabled_sections.json',
  );

  if (data.data) {
    return data?.data;
  }

  return {};
}

export function useDisabledSections(options = {}) {
  return useQuery<IDisabledSections>({
    queryKey: ['disabled-sections'],
    queryFn: async () => await getDisabledSections(),
    placeholderData: {},
    ...options,
  });
}

export function getDisabledSectionsPrefetchQuery(): IPrefetchedQuery {
  return {
    queryKey: ['disabled-sections'],
    getFn: () => getDisabledSections(),
  };
}
