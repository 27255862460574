import {Icon, Modal, Logo, Text, CTAForm} from '@prescriberpoint/ui';
import {FC, useState} from 'react';
import AgreeTermsAndCondtitionsAndPrivacyPolicy from '../Auth/AddEmail/AgreeTermsAndCondtitionsAndPrivacyPolicy';
import FrictionlessErrorModal from './FrictionlessErrorModal';
import FrictionlessSuccessModal from './FrictionlessSuccessModal';
import {useRequestMagicLinkToLogin} from '@/mutations/useRequestMagicLinkToLogin';
import {useRequestMagicLinkToLoginOnlyEmail} from '@/mutations/useRequestMagicLinkToLoginOnlyEmail';
import {validateEmail, validateNpi} from '@/utils/validations';

interface RenderModalProps {
  isEmailSent: boolean;
  email: string;
  isOpen: boolean;
  isFirstTime: boolean;
  singUpIsLoading: boolean;
  logInIsLoading: boolean;
  handleCloseModal: () => void;
  handleSubmit: (formData: any) => Promise<void>;
  handleLogIn: (formData: any) => Promise<void>;
}

const RenderModal = ({
  isEmailSent,
  email,
  handleCloseModal,
  isOpen,
  isFirstTime,
  singUpIsLoading,
  logInIsLoading,
  handleLogIn,
  handleSubmit,
}: RenderModalProps) =>
  isEmailSent ? (
    <FrictionlessSuccessModal
      email={email}
      onClose={handleCloseModal}
      isOpen={isOpen}
    />
  ) : (
    <Modal
      showConfirm={false}
      showCancel={false}
      className="flex !w-[98%] !max-w-[98%] flex-col md:w-[500px] md:!max-w-[500px]"
      isOpen={isOpen}
      showCloseButton={false}
      closeModal={handleCloseModal}>
      <button
        onClick={handleCloseModal}
        className="absolute right-6 top-6 cursor-pointer bg-transparent">
        <Icon
          name="Cancel"
          className="flex h-5 w-5 items-center text-neutral-darker/56"
        />
      </button>
      <div className="mx-auto mb-5 mt-2">
        <Logo isSmall />
      </div>
      <div className="mb-8 flex flex-col space-y-2">
        <Text as="headline-md" className="block text-center">
          Get your patients on therapy faster
        </Text>
        <Text as="body-md" className="block text-center">
          {isFirstTime
            ? 'Provide your NPI number to finish creating your account'
            : 'Enter your email address to sign into your existing account or create a free account'}
        </Text>
      </div>
      <div className="mb-6 flex flex-col space-y-4">
        {isFirstTime ? (
          <CTAForm
            key="second-step-frictionless"
            form={{
              submitText: 'Create Free Account',
              isSubmittingText: 'Submitting...',
              inputs: [
                {
                  id: 'email',
                  type: 'email',
                  label: 'E-Mail',
                  required: true,
                  placeholder: email,
                  errorMessage: `Please enter a valid email`,
                  disabled: isFirstTime,
                  defaultValue: email,
                  validateFn: (v: string) => validateEmail(v),
                },
                {
                  id: 'npi',
                  type: 'text',
                  label: 'NPI #',
                  required: true,
                  placeholder: `Enter your NPI #`,
                  errorMessage: `Please enter a valid NPI`,
                  disabled: false,
                  validateFn: (v: string) => validateNpi(v),
                },
              ],
            }}
            isLoading={singUpIsLoading}
            onSubmit={handleSubmit}
          />
        ) : (
          <CTAForm
            key="first-step-frictionless"
            form={{
              submitText: 'Continue',
              isSubmittingText: 'Submitting...',
              inputs: [
                {
                  id: 'email',
                  type: 'email',
                  label: 'E-Mail',
                  required: true,
                  placeholder: `name@work-email.com`,
                  errorMessage: `Please enter a valid email`,
                  disabled: false,
                  validateFn: (v: string) => validateEmail(v),
                },
              ],
            }}
            isLoading={logInIsLoading}
            onSubmit={handleLogIn}
          />
        )}
      </div>
      <div className="text-center">
        <AgreeTermsAndCondtitionsAndPrivacyPolicy
          className="block w-full"
          text="By creating an account, I agree to PrescriberPoint’s "
        />
      </div>
    </Modal>
  );

interface IFrictionlessModalFormProps {
  isOpen: boolean;
  onClose: () => void;
}

const FrictionlessModalForm: FC<IFrictionlessModalFormProps> = ({
  isOpen = false,
  onClose,
}) => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isApiError, setIsApiError] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [logInIsLoading, setLogInIsLoading] = useState(false);
  const [singUpIsLoading, setSingUpIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const {mutateAsync: requestMagicLinkToLogin} = useRequestMagicLinkToLogin();
  const {mutateAsync: requestMagicLinkToLoginOnlyEmail} =
    useRequestMagicLinkToLoginOnlyEmail();

  const handleCloseModal = () => {
    onClose();
  };

  const handleSubmit = async (formData: any) => {
    try {
      setSingUpIsLoading(true);
      await requestMagicLinkToLogin({
        email: formData.email,
        npiNumber: formData.npi,
        redirectUrl: window.location.pathname,
      });

      setIsFirstTime(false);
      setSingUpIsLoading(false);
      setIsEmailSent(true);
    } catch (error: any) {
      setSingUpIsLoading(false);
      setIsApiError(true);
    }
  };

  const handleLogIn = async (formData: any) => {
    try {
      setLogInIsLoading(true);
      setEmail(formData.email);
      await requestMagicLinkToLoginOnlyEmail({
        email: formData.email,
        redirectUrl: window.location.pathname,
      });

      setLogInIsLoading(false);
      setIsEmailSent(true);
    } catch (error: any) {
      if (error.code === 500) {
        setLogInIsLoading(false);
        setIsApiError(true);
      } else {
        setIsFirstTime(true);
        setLogInIsLoading(false);
      }
    }
  };

  return isApiError ? (
    <FrictionlessErrorModal onClose={handleCloseModal} isOpen={isOpen} />
  ) : (
    <RenderModal
      isEmailSent={isEmailSent}
      email={email}
      isOpen={isOpen}
      isFirstTime={isFirstTime}
      singUpIsLoading={singUpIsLoading}
      logInIsLoading={logInIsLoading}
      handleCloseModal={handleCloseModal}
      handleSubmit={handleSubmit}
      handleLogIn={handleLogIn}
    />
  );
};
export default FrictionlessModalForm;
