import {useEffect, useRef} from 'react';
import useUserData from './useUserData';

const useViewportOffset = () => {
  const {userData} = useUserData();

  const viewportOffset = useRef(0); //portion of the viewport covered by #navbar

  useEffect(() => {
    const offset = document.querySelector('#pivotMenu')?.getBoundingClientRect()
      .bottom!;

    viewportOffset.current = offset;
  }, [userData?.showDisclaimer]);

  return viewportOffset;
};

export default useViewportOffset;
