import {useState} from 'react';
import {useIpData} from '@/queries';

export const useGetDataFromIp = () => {
  const {data: ipData} = useIpData();

  const [city, setCity] = useState(ipData?.city ?? '');
  const [state, setState] = useState(ipData?.state ?? '');
  const [zip, setZip] = useState(ipData?.postal ?? '');

  return {city, state, zip};
};
