import {useRouter} from 'next/compat/router';

function useIsPDP() {
  const router = useRouter();
  if (router) {
    return router.route === '/therapies/[slug]';
  } else {
    const path = window.location.pathname;
    return path.includes('/therapies/') && path.split('/')?.length === 2;
  }
}

export default useIsPDP;
