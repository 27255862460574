import {FC} from 'react';
import SponsoredCard from './SponsoredCard';
import {sponsors} from '@/data/sponsors';

export const SponsoredCards: FC = () => (
  <div className='flex w-full items-center bg-neutral-lighter md:h-[600px]'>
    <div className='mx-auto my-10 box-border flex w-full max-w-[1100px] flex-col gap-5 p-4 md:flex-row md:gap-8 md:p-0'>
      {sponsors.map((sponsor) => (
        <SponsoredCard key={sponsor.slug} {...sponsor} />
      ))}
    </div>
  </div>
);

export * from './SponsoredCard';
