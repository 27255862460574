import {getHighlightedText, Icon, Text} from '@prescriberpoint/ui';
import {FC, useMemo} from 'react';
import {useInstantSearch, useSearchBox} from 'react-instantsearch';
import {useSearchListContext} from '@/context/SearchListContext';
import {capitalize} from '@/utils/string';

const MultiDrugsResults: FC = () => {
  const {selectedResults, addToSelectedResults} = useSearchListContext();

  const {results: searchResults} = useInstantSearch();
  const {refine} = useSearchBox();

  const isResultSelected = (set_id: string) =>
    selectedResults.find((r) => r.set_id === set_id);

  const showSearchResults = useMemo(
    () => !!searchResults?.query,
    [searchResults?.query],
  );

  return (
    <div className="shadow-drug-interacrions-result absolute w-full bg-white">
      {showSearchResults &&
        searchResults?.hits?.map(
          (result) =>
            !isResultSelected(result.objectID) && (
              <div
                onClick={() => {
                  addToSelectedResults({
                    set_id: result.objectID,
                    label_title: result.label_title,
                    slug: result.slug,
                    generic_name: result.names[1],
                    labeler: result.labeler,
                  });
                  refine('');
                }}
                key={result.objectID}
                className="cursor-pointer px-4 active:!bg-neutral-light devicehover:hover:bg-neutral-lighter">
                <div className="group flex items-center py-2 shadow-low-bottom">
                  <div className="flex-1">
                    <Text tag="div" variant="secondary" size="sm" weight="bold">
                      {result?.names[1]?.toLocaleLowerCase()}
                    </Text>
                    <span className="text-neutral-primary">
                      {getHighlightedText(
                        capitalize(result.label_title),
                        searchResults?.query,
                      )}
                    </span>
                  </div>
                  <Icon
                    name="AddCircleFilled"
                    className="w-5 text-theme-primary"
                  />
                </div>
              </div>
            ),
        )}
    </div>
  );
};

export default MultiDrugsResults;
