import {Portal} from '@headlessui/react';
import clsx from 'clsx';
import {ToastContainer} from 'react-toastify';
import {useToastContext} from '@/context/ToastContext';

const ToastContainerHolder = () => {
  const {isModalInsideSidePanel} = useToastContext();

  return (
    <Portal>
      <ToastContainer
        className={clsx('!sm:p-0 !z-[10000] !p-2', {
          '!left-auto !right-0 !w-[420px] !translate-x-0':
            isModalInsideSidePanel,
        })}
      />
    </Portal>
  );
};

export default ToastContainerHolder;
