import clsx, {ClassValue} from 'clsx';
import {FC} from 'react';
import {ProductTitle, BlackBoxWarningLink} from '@/components';

interface NewBrandHeaderProps {
  className?: ClassValue;
  showBottomSection?: boolean;
}

const NewBrandHeader: FC<NewBrandHeaderProps> = ({
  className,
  showBottomSection = true,
}) => (
  <div
    className={clsx(
      'box-border flex flex-col space-y-2 text-lg leading-8 text-neutral-primary md:m-0',
      className,
    )}>
    <div className="w-full">
      <ProductTitle />
    </div>
    {showBottomSection ? (
      <div className="flex w-full justify-center border-0 border-t border-solid border-neutral-quaternary-alt pt-2">
        <BlackBoxWarningLink />
      </div>
    ) : null}
  </div>
);

export default NewBrandHeader;
