import {useMutation} from '@tanstack/react-query';
import {create} from '@/services/api';

interface ICoverageFeedbackRequest {
  npi: string;
  formularyId: number;
  brand: string;
  indication: string;
  isHelpful: boolean;
}

export async function createCoverageFeedback(params: ICoverageFeedbackRequest) {
  const data = await create(
    'CoverageFeedback',
    {parentName: 'User', params},
    {},
    'profile',
    false,
  );
  return data;
}

export function useCoverageFeedback(options = {}) {
  return useMutation({mutationFn: createCoverageFeedback, ...options});
}
