import {Text} from '@prescriberpoint/ui';
import {FC, useState} from 'react';
import {toSnakeCase, replaceVariablesInStr} from '@/utils/string';

interface QuestionCollapsableProps {
  question: string;
  answer: {title: string; items: string[]};
  brandName: string;
}
const QuestionCollapsable: FC<QuestionCollapsableProps> = ({
  question,
  answer,
  brandName,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-col bg-white py-2 px-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full text-left"
        id={`${toSnakeCase(question)}_button`}>
        <Text variant="primary">
          {replaceVariablesInStr(question, {brandName})}
        </Text>
      </button>
      {isOpen && (
        <div className="my-2 flex flex-col space-y-4">
          {answer.title ? (
            <Text as="headline-sm">
              {replaceVariablesInStr(answer.title, {brandName})}
            </Text>
          ) : null}
          {answer.items.map((paragraph) => (
            <Text key={paragraph} className="">
              {replaceVariablesInStr(paragraph, {brandName})}
            </Text>
          ))}
        </div>
      )}
    </div>
  );
};

export default QuestionCollapsable;
