import clsx from 'clsx';
import dynamic from 'next/dynamic';
import {FC} from 'react';
import {PageContentLoader} from '@/components';
import Navbar from '@/components/CustomNavbar';
import Disclaimer from '@/components/Disclaimer';
import {NAVBAR_ID} from '@/constants';
import {SHOW_V2_PPT_DESIGN} from '@/constants/flags';
import {useFlag, useMobileContentContext, useUserAgentContext} from '@/context';
import {useLoadingContext} from '@/context/LoadingContext';

const DDIModal = dynamic(
  () => import('@/modules/ProductDetailPage/DrugInteractions/DDIModal'),
);

const MobileContentProvider = dynamic(() =>
  import('@/context/MobileContentProvider').then(
    (m) => m.MobileContentProvider,
  ),
);

const Footer = dynamic(() => import('@/components/Footer'));

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({children}) => {
  const {isLoading} = useLoadingContext();
  const {isMenuOpen} = useMobileContentContext();
  const showNewDesign = useFlag(SHOW_V2_PPT_DESIGN);

  return (
    <div
      className={clsx(' relative flex h-fit min-h-screen flex-col', {
        'overflow-hidden': isMenuOpen,
      })}>
      <div
        id={NAVBAR_ID}
        className='sticky top-0 z-50 w-full bg-white xs:z-max'>
        <Disclaimer />
        <Navbar />
      </div>

      {isLoading ? (
        <PageContentLoader />
      ) : (
        <>
          <div
            className={clsx(
              'flex-1',
              showNewDesign ? 'bg-neutral-lighter-alt' : 'bg-white',
            )}>
            {children}
          </div>
          <DDIModal />
          <Footer />
        </>
      )}
    </div>
  );
};

const MainLayoutWrapper: FC<MainLayoutProps> = ({children}) => {
  const {isMobileOrTablet} = useUserAgentContext();
  return isMobileOrTablet ? (
    <MobileContentProvider>
      <MainLayout>{children}</MainLayout>
    </MobileContentProvider>
  ) : (
    <MainLayout>{children}</MainLayout>
  );
};

export default MainLayoutWrapper;
