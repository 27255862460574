import {Icon, IconButton} from '@prescriberpoint/ui';
import {RegistrationModal} from '@/components';
import {useCurrentDrug, useBookmarkPDP} from '@/hooks';
interface IBookmarkProps {
  title: string;
  id: string;
}

export default function Bookmark({id, ...props}: IBookmarkProps) {
  const currentDrug = useCurrentDrug();

  const {isFilled, renderModal, handleClick, closeModal} =
    useBookmarkPDP(currentDrug);

  return (
    <>
      {renderModal && (
        <RegistrationModal isGenericVersion onClose={closeModal} />
      )}
      <IconButton
        id={id}
        icon={
          <Icon
            test-id={isFilled ? 'bookmark-filled-icon' : 'bookmark-icon'}
            className="h-6 w-6"
            name={isFilled ? 'BookmarkFilled' : 'Bookmark'}
            {...props}
          />
        }
        onClick={handleClick}
        tooltip={isFilled ? 'Remove from bookmarks' : 'Bookmark this therapy'}
      />
    </>
  );
}
