import {type QueryClient, useQuery} from '@tanstack/react-query';
import {useUser} from '@/hooks';
import {read} from 'services/api';

export async function getCurrentHCP(token?: string): Promise<any | null> {
  let data: any | null = null;
  try {
    const hcps = await read<any>(
      'my',
      {
        parentName: 'HealthcareProvider',
      },
      {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
      },
      'samples',
      !token,
    );
    const currentHCP = hcps[0];
    data = currentHCP;
  } catch (error) {
    console.error(error);
  }

  return data;
}

export function useHCPUser(token?: string, options = {}) {
  const {logged: isLogged} = useUser();
  return useQuery<any | null>({
    queryKey: ['currentHCP'],
    queryFn: async () => await getCurrentHCP(token),
    ...options,
    enabled: isLogged,
    staleTime: 0,
  });
}

export async function fetchHCPUser(queryClient: QueryClient, token?: string) {
  return await queryClient.fetchQuery({
    queryKey: ['currentHCP'],
    queryFn: async () => await getCurrentHCP(token),
  });
}
