import {useMutation} from '@tanstack/react-query';
import {USE_EMAIL_FOR_REP_REGISTER_OTP_VALIDATION} from '@/constants/flags';
import {useFlag} from '@/context';
import {create} from '@/services/api';

export interface IRepSignup {
  email: string;
  practiceId: string;
  locationId: string;
}

async function repSignup(params: IRepSignup, version: 1 | 2) {
  return await create(
    'RepRegister',
    {
      parentName: 'Auth',
      params,
    },
    {},
    'profile',
    false,
    version,
  );
}

export function useRepSignup() {
  const useEmailForOtpValidation = useFlag(
    USE_EMAIL_FOR_REP_REGISTER_OTP_VALIDATION,
  );

  return useMutation({
    mutationFn: (params: IRepSignup) =>
      repSignup(params, useEmailForOtpValidation ? 2 : 1),
  });
}
