import {useIsMobile, useIsTablet} from '@prescriberpoint/ui';
import {
  FC,
  useState,
  createContext,
  useContext,
  SetStateAction,
  Dispatch,
  useMemo,
  useEffect,
  ReactNode,
} from 'react';
import { useIsClient } from 'usehooks-ts';
import {UserAgent} from '@/utils/userAgent';

interface IUserAgentContext {
  userAgent: UserAgentExtended | null;
  setUserAgent: Dispatch<SetStateAction<UserAgent | null>>;
  isMobile: boolean;
  isMobileDevice: boolean;
  isMobileOrTablet: boolean;
  isTablet: boolean;
  isClient: boolean;
}

interface UserAgentExtended extends UserAgent {
  isIosOrSafari?: boolean;
}

const defaultState: IUserAgentContext = {
  userAgent: null,
  setUserAgent: () => {},
  isMobile: false,
  isMobileDevice: false,
  isMobileOrTablet: false,
  isTablet: false,
  isClient: false,
};

interface UserAgentProps {
  userAgent?: UserAgent;
  children: ReactNode;
}

const UserAgentContext = createContext<IUserAgentContext>(defaultState);

export const useUserAgentContext = () => useContext(UserAgentContext);

export const UserAgentProvider: FC<UserAgentProps> = (props) => {
  const screenSizeMobile = useIsMobile();
  const screenSizeTablet = useIsTablet();
  const isClient = useIsClient();
  const [userAgent, setUserAgent] = useState<UserAgentExtended | null>(
    props.userAgent || null,
  );

  useEffect(() => {
    // fix usergent lib isSafari issue.
    const isSafari = props?.userAgent?.browser.includes('Safari') || false;
    const isIosOrSafari = props?.userAgent?.isIos || isSafari;
    const newUserAgent = {
      ...props.userAgent,
      isSafari,
      isIosOrSafari,
    } as UserAgentExtended;
    setUserAgent(newUserAgent);
  }, [props.userAgent]);

  const isMobile = useMemo(() => {
    // use media query to detect mobile in client side
    if (typeof window !== 'undefined') {
      return screenSizeMobile;
    }
    // use user agent to detect mobile in server side
    return userAgent?.isMobile ?? false;
  }, [userAgent, screenSizeMobile]);

  const isTablet = useMemo(() => {
    if (typeof window !== 'undefined') {
      return screenSizeTablet;
    }

    return userAgent?.isTablet ?? false;
  }, [userAgent, screenSizeTablet]);

  const isMobileDevice = useMemo(
    () => userAgent?.isMobile ?? false,
    [userAgent],
  );

  const values = {
    isMobileDevice,
    isMobile,
    isTablet,
    isMobileOrTablet: (isMobile || isTablet) && isClient,
    isClient,
    userAgent,
    setUserAgent,
  };

  return <UserAgentContext.Provider value={values} {...props} />;
};
