import clsx from 'clsx';
import dynamic from 'next/dynamic';
import {FC, useEffect, useState, memo} from 'react';
import {
  Footer,
  PivotMenu,
  PageContentLoader,
  CustomNavbar as Navbar,
} from '@/components';
import SidebarPivotMenu from '@/components/SidebarPivotMenu';
import {NAVBAR_ID} from '@/constants';
import {
  ONLY_HCPS_DISCLAIMER,
  TERMS_AND_CONDITIONS,
  COVERAGE_FOR_CONSTELLATION,
  COVERAGE_ON_PDP,
  SHOW_V2_PPT_DESIGN,
  NEW_SIDEBAR,
} from '@/constants/flags';
import {useFlag, CoverageRestrictionsProvider} from '@/context';
import {useLoadingContext} from '@/context/LoadingContext';
import {useUserAgentContext} from '@/context/UserAgentContext';
import {useCurrentSlug, useIsPDP, useIsOtc, useRenderInIFrame} from '@/hooks';
import useIsPDPSubpage from '@/hooks/useIsPDPSubpage';
import useUserData from '@/hooks/useUserData';
import {useCurrentPerson, useLabel} from '@/queries';

const Disclaimer = dynamic(() => import('@/components/Disclaimer'), {
  ssr: false,
});

const TermsAndConditions = dynamic(
  () => import('@/modules/HomePage/TermsAndConditions'),
  {
    ssr: false,
  },
);

interface PdpLayoutProps {
  children: React.ReactNode;
}

const PdpLayout: FC<PdpLayoutProps> = ({children}) => {
  const {isMobile, isTablet} = useUserAgentContext();
  const {userData} = useUserData();
  const isPDP = useIsPDP();
  const isPDPSubpage = useIsPDPSubpage();
  const {setId, isConstellationDrug} = useCurrentSlug();
  const {data: product} = useLabel(setId);
  const [isOpenSidebarPivotMenu, setIsOpenSidebarPivotMenu] = useState(false);

  const showPivot = (isPDP || isPDPSubpage) && setId && product;
  const isOtc = useIsOtc();
  const {isLoading} = useLoadingContext();
  const showDisclaimerFlag = useFlag(ONLY_HCPS_DISCLAIMER);
  const showDisclaimer = userData.showDisclaimer && showDisclaimerFlag;
  const enableCoverageForConstellationOnly = useFlag(
    COVERAGE_FOR_CONSTELLATION,
  );

  const showCoverageOnPdp =
    useFlag(COVERAGE_ON_PDP) &&
    (enableCoverageForConstellationOnly ? isConstellationDrug : true);
  const termsAndConditionsEnabled = useFlag(TERMS_AND_CONDITIONS);
  const {data: user} = useCurrentPerson();
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const showNewDesign = useFlag(SHOW_V2_PPT_DESIGN);
  const newPivotMenuDesign = useFlag(NEW_SIDEBAR);

  useEffect(() => {
    if (termsAndConditionsEnabled && user)
      setShowTermsAndConditions(user.shouldNeedAcceptTsCs);
  }, [user, termsAndConditionsEnabled]);

  const [stickyNavbar, setStyckyNavbar] = useState(false);

  const pdpIsEmbedded = useRenderInIFrame();

  const shouldShowPivotMenu = !pdpIsEmbedded && showPivot && !isOtc;

  useEffect(() => {
    if (isMobile || isTablet) {
      window.onscroll = function () {
        if (window.scrollY === 0) {
          setStyckyNavbar(true);
        } else {
          setStyckyNavbar(false);
        }
      };
    }
  }, [isMobile, isTablet, stickyNavbar]);

  const closeTermsAndConditions = () => {
    setShowTermsAndConditions(false);
  };

  const renderContent = () => (
    <div className='flex min-h-screen flex-col'>
      {showTermsAndConditions && (
        <TermsAndConditions handleClose={closeTermsAndConditions} />
      )}
      {!pdpIsEmbedded ? (
        <div
          id={NAVBAR_ID}
          className={clsx([
            'relative w-full bg-white',
            isMobile || isTablet ? 'z-50' : 'z-max',
            {
              'sticky top-0 z-max': stickyNavbar || newPivotMenuDesign,
            },
          ])}>
          <Disclaimer />
          <Navbar
            isSidebarOpen={isOpenSidebarPivotMenu}
            setSidebarOpen={(value: boolean) =>
              setIsOpenSidebarPivotMenu(value)
            }
          />
        </div>
      ) : null}

      {isLoading ? (
        <PageContentLoader />
      ) : (
        <div className='flex flex-1 flex-col lg:flex-row'>
          {newPivotMenuDesign && shouldShowPivotMenu && (
            <SidebarPivotMenu isOpen={isOpenSidebarPivotMenu} />
          )}
          <div
            className={clsx(
              'flex flex-1 flex-col',
              showNewDesign ? 'bg-neutral-lighter-alt' : 'bg-white',
            )}
            id='layout-content'>
            <div
              className={clsx('sticky z-10 md:mt-4', {
                'top-[112px]': stickyNavbar && showDisclaimer,
                'top-[64px]': stickyNavbar && !showDisclaimer,
                '!top-0': pdpIsEmbedded || !stickyNavbar,
              })}>
              {!newPivotMenuDesign && shouldShowPivotMenu && <PivotMenu />}
            </div>
            <div className='min-h-page-content flex-1'>{children}</div>
            {!pdpIsEmbedded && !isOtc ? (
              <div className='w-full'>
                <div className='flex'>
                  <Footer />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );

  return showCoverageOnPdp ? (
    <CoverageRestrictionsProvider>
      {renderContent()}
    </CoverageRestrictionsProvider>
  ) : (
    renderContent()
  );
};

export default memo(PdpLayout);
