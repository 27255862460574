import {useMutation} from '@tanstack/react-query';
import {create} from '@/services/api';

export interface IRequestMagicLinkToLogin {
  email: string;
  npiNumber: string;
  redirectUrl: string;
}

async function requestMagicLinkToLogin(params: IRequestMagicLinkToLogin) {
  await create(
    'SignIn',
    {
      parentName: 'Auth',
      params,
    },
    {},
    'profile',
    false,
    3,
  );
}

export function useRequestMagicLinkToLogin() {
  return useMutation({mutationFn: requestMagicLinkToLogin});
}
