import {Icon} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {ElementType, Fragment, useState} from 'react';

export interface IExpandableColumn {
  Content?: ElementType;
  cells: ElementType[];
}

export interface IExpandableTableProps {
  headers: ElementType[];
  data: IExpandableColumn[];
  headerClassName?: string;
  cellClassName?: string;
  tableName?: string;
  expandableContent?: boolean;
}

/**
 * @param headers - Array of strings to be used as table headers
 * @param data - Array of objects containing cells and optional Content to be displayed when column is expanded
 
  If no Content is provided, the expand button will not appear for that column
 
 * @param tableName - Optional string to be used as a prefix/key for table elements
 */

export default function ExpandableTable({
  headers,
  data,
  headerClassName,
  cellClassName,
  expandableContent = true,
  tableName = 'expandable-table',
}: IExpandableTableProps) {
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [shrinkingRows, setShrinkingRows] = useState<{ [key: number]: boolean }>({});

  function handleExpand(row: number) {
    if (expandedRows.includes(row)) {
      setShrinkingRows({ ...shrinkingRows, [row]: true });
      setTimeout(() => {
        setExpandedRows(expandedRows.filter(r => r !== row));
        setShrinkingRows({ ...shrinkingRows, [row]: false });
      }, 150);
    } else {
      setExpandedRows([...expandedRows, row]);
    }
  }

  const cellBorder = 'border border-solid border-neutral-light';
  const cellStyle = clsx('p-0 shadow-low-bottom', cellBorder);

  return (
    <table className="w-full border-spacing-0">
      <thead>
        <tr>
          {headers.map((THeader, idx) => (
            <th
              className={clsx(cellStyle, headerClassName)}
              key={`${tableName}-${THeader}-${idx}`}>
              <THeader />
            </th>
          ))}
          {expandableContent ? (
            <th className={clsx(cellStyle, headerClassName)} />
          ) : null}
        </tr>
      </thead>
      <tbody>
        {data.map((row, idx) => (
          <Fragment key={`${tableName}-${row}-${idx}`}>
            <tr
              className={clsx(
                'w-full',
                idx % 2 === 0 && 'relative bg-neutral-lighter',
              )}>
              {row.cells.map((Cell, jdx) => (
                <td
                  className={clsx(cellStyle, cellClassName)}
                  key={`${tableName}-cell-${jdx}-${idx}`}>
                  <Cell />
                </td>
              ))}
              {expandableContent ? (
                <td
                  className={clsx(
                    'w-10 text-center',
                    cellStyle,
                    cellClassName,
                  )}>
                  {row.Content ? (
                    <button
                      className="inline-flex h-full items-center justify-center"
                      onClick={() => handleExpand(idx)}>
                      <Icon
                        name="ChevronDown"
                        className={clsx(
                          'flex h-1.5 w-3 items-center text-theme-primary transition-transform',
                          expandedRows.includes(idx) && 'rotate-180',
                        )}
                      />
                    </button>
                  ) : null}
                </td>
              ) : null}
            </tr>
            {expandableContent && row.Content && expandedRows.includes(idx)  ? (
              <tr>
                <td
                  colSpan={headers.length + 1}
                  className={clsx(
                    cellBorder,
                    'h-fit border-t-0 p-0 [&>*]:animate-collapse-down',
                    idx === data.length - 1 && 'border-b',
                    shrinkingRows[idx] && '[&>*]:animate-collapse-up',
                  )}>
                  <row.Content />
                </td>
              </tr>
            ) : null}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
}
