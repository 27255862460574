import {QueryClient, useQuery} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {ListPharmaciesDto} from '@/models/pharmacyDtoV2';
import {IPharmacyQueryParams} from '@/pages/api/pharmacies/locations';
import {read} from '@/services/api';

export async function getPharmaciesV2(
  params: IPharmacyQueryParams,
): Promise<ListPharmaciesDto | null> {
  let data: ListPharmaciesDto | null = null;

  try {
    data = await read<ListPharmaciesDto>(
      'pharmacies/locations',
      {
        params,
      },
      {},
      'nextApi',
    );
  } catch (e) {
    console.error(e);
  }
  return data;
}

export function usePharmaciesV2(params: IPharmacyQueryParams, options = {}) {
  return useQuery<ListPharmaciesDto | null>({
    queryKey: ['pharmaciesV2', params],
    queryFn: () => getPharmaciesV2(params),
    refetchOnMount: false,
    ...options,
  });
}

export function getPharmaciesPrefetchQueryV2(
  params: IPharmacyQueryParams,
): IPrefetchedQuery {
  return {
    queryKey: ['pharmaciesV2', params],
    getFn: async () => await getPharmaciesV2(params),
  };
}

export async function fetchQueryPharmaciesV2(
  params: IPharmacyQueryParams,
  queryClient: QueryClient,
): Promise<ListPharmaciesDto> {
  return await queryClient.fetchQuery({
    queryKey: ['pharmaciesV2', params],
    queryFn: async () => await getPharmaciesV2(params),
  });
}
