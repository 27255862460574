import {DOCUMENTS_PROFILE_API_URL} from '@/constants/global';

export function getPdfUrlFromFilename(filename: string): string {
  // TODO: enable this line when apim issue with previewing pdfs is figured out
  // return `${API_BASE_URL}${PROFILE_API_URL}v1/Document/${filename}`;
  return `${DOCUMENTS_PROFILE_API_URL}v1/Document/${filename}`;
}

const PPT_HOSTS = ['rnd.ontherapy.dev', 'prescriberpoint.com'];
export function isPptDomain(url: string) {
  try {
    const targetUrl = new URL(url);
    return PPT_HOSTS.some((host) => targetUrl.hostname.includes(host));
  } catch (e) {
    return false;
  }
}

export function isUrlFormated(url: string | null) {
  if (url === undefined || url === null) {
    return false;
  }

  const regexp =
    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return regexp.test(url);
}
