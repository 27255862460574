import {Text, Icon, Button} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC} from 'react';
import {useUserAgentContext} from '@/context';
import {useAuthPath} from '@/hooks';

const FEATURES = [
  'Share the preferred local pharmacy for a specialty drug',
  'Share coverage restriction tips for common local payers',
  'Share brand contact updates',
  'Share helpful tips with coworkers',
];

interface PracticeNotesSignupPopUpProps {}
const PracticeNotesSignupPopUp: FC<PracticeNotesSignupPopUpProps> = () => {
  const {signupPath, loginPath} = useAuthPath();
  const {isMobile} = useUserAgentContext();

  return (
    <div className="!mt-0 flex items-center justify-center p-16">
      <div className="flex-1 space-y-6 ">
        <Text as="headline-lg" variant="base">
          Practice notes & tips
        </Text>
        <div className="flex flex-col items-start justify-start space-y-2">
          {FEATURES.map((feature) => (
            <div
              className="inline-flex items-center justify-start space-x-3"
              key={feature}>
              <Icon
                name="Yes"
                className="h-4 w-4 min-w-[16px] text-theme-primary"
              />
              <Text as="body-md" variant="base">
                {feature}
              </Text>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-end justify-start space-y-2">
          <Button
            className="whitespace-nowrap outline-none"
            href={`${signupPath}?process=notes`}
            rel="nofollow"
            size="md"
            id="create_account"
            asLink>
            Sign Up For Free
          </Button>
          <div
            className={clsx(
              'flex flex-wrap',
              isMobile && 'w-full items-center justify-end',
            )}>
            <Text variant="base" className="mr-1 text-right">
              Already have an account?
            </Text>
            <a
              className={clsx(
                'flex items-center whitespace-nowrap py-2 text-sm text-theme-primary no-underline hover:text-theme-dark sm:py-0',
                isMobile && 'mr-2',
              )}
              href={`${loginPath}?redirect=${window.location.pathname}`}
              rel="nofollow"
              id="sign_in">
              Log in
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PracticeNotesSignupPopUp;
