import {useMemo} from 'react';
import {useCurrentSlug} from './useCurrentSlug';
import {useEnhanced} from './useEnhanced';
import {useShowEnhancedSection} from './useShowEnhancedSection';
import * as FLAGS from '@/constants/flags';
import {FULL_SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag} from '@/context';

// TODO we need to continue this, to have in one place the status of the sections,
// I only add the sections that I need to know
export function useSectionsStatus() {
  const {setId} = useCurrentSlug();
  const {isEnhanced, enhancedContent} = useEnhanced(setId);
  const {copaySavingsCardProgram, patientAssistanceProgram} =
    enhancedContent ?? {};

  const showPA = useShowEnhancedSection(
    FULL_SECTION_IDS.priorAuthorization,
    FLAGS.PRIOR_AUTHORIZATION_SECTION,
  );
  const showPE = useFlag(FLAGS.PATIENT_EDUCATION_SECTION) && isEnhanced;

  /** Start Check FA Section */
  const showFAS = useShowEnhancedSection(
    FULL_SECTION_IDS.financialAssistance,
    FLAGS.FINANCIAL_ASSISTANCE_SUPPORT_SECTION,
  );
  const showNewFA = useFlag(FLAGS.AFFORDABILITY_ON_PDP);
  // if there are no programs available, show the generic financial assistance support section
  const programsAvailable = copaySavingsCardProgram || patientAssistanceProgram;
  const showFinancialAssistanceSection =
    (showNewFA && programsAvailable) || showFAS;
  /** End Check FA Section */

  const sections = useMemo(
    () => ({
      [FULL_SECTION_IDS.financialAssistance]: showFinancialAssistanceSection, // financial assistance
      [FULL_SECTION_IDS.priorAuthorization]: showPA, // prior authorization
      [FULL_SECTION_IDS.patientEducation]: showPE, // patient education
    }),
    [showPE, showPA, showFinancialAssistanceSection],
  );

  return sections;
}
