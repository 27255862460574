import {useQuery} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {PubMedArticle} from '@/models';
import {read} from '@/services/api';

const fetchArticleData = async (articleId: string) => {
  let data: PubMedArticle | null = null;

  try {
    data = await read<any>(
      'Article',
      {
        resourceId: articleId,
      },
      {},
      'profile',
      false,
      2,
    );

    return data;
  } catch (error) {
    console.error(error);
  }

  return data;
};

export function useArticleData(articleId: string, options = {}) {
  return useQuery({
    queryKey: ['articleData', articleId],
    queryFn: async () => await fetchArticleData(articleId),
    refetchOnMount: false,
    ...options,
  });
}

export function getArticleDataPrefetchQuery(
  articleId: string,
): IPrefetchedQuery {
  return {
    queryKey: ['articleData', articleId],
    getFn: async () => await fetchArticleData(articleId),
  };
}
