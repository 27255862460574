import {useLocalStorage} from 'usehooks-ts';
import {DrugsSavedByUserCookie} from '@/interfaces';

export interface IUserDataStorage {
  givenName: string;
  familyName: string;
  picture: string;
  showDisclaimer: boolean;
  showQrHelper: boolean;
  priorAuthFormSubmitted: boolean;
  showRepQrInstructions: boolean;
  recentSearches: DrugsSavedByUserCookie;
  bookmarks: DrugsSavedByUserCookie;
  ddiSearchCounter: number;
  termsAndConditions: boolean;
}

const useUserData = () => {
  const [userData, setUserData] = useLocalStorage<IUserDataStorage>(
    'userData',
    {
      givenName: '',
      familyName: '',
      picture: '',
      showDisclaimer: true,
      termsAndConditions: false,
      showQrHelper: true,
      priorAuthFormSubmitted: false,
      showRepQrInstructions: true,
      recentSearches: {},
      bookmarks: {},
      ddiSearchCounter: 0,
    },
  );

  const resetUserData = () => {
    setUserData((prevValue) => ({
      ...prevValue,
      familyName: '',
      givenName: '',
      picture: '',
      termsAndConditions: false,
    }));
  };

  return {userData, setUserData, resetUserData};
};

export default useUserData;
