import {useMutation} from '@tanstack/react-query';
import {create} from '@/services/api';
export interface StepTherapyMutation {
  npi: string;
  brand: string;
  dosage: string | number;
  dosageId: string | number;
  carrier: string;
  indication: string | number;
  indicationId: string | number;
  genericName: string;
  formularyIds?: number[];
  insuranceChannel: string;
  chosenFormularyId: number | null;
}

async function stepTherapyLogMutation(body: StepTherapyMutation) {
  let data: StepTherapyMutation | null = null;
  try {
    data = await create(
      'StepTherapyLogs',
      {
        params: {
          ...body,
        },
        parentName: 'User',
      },
      {method: 'POST'},
      'profile',
      false,
    );
  } catch (error) {
    console.error(error);
  }

  return data;
}

export function useStepTherapyLogMutation() {
  return useMutation({mutationFn: stepTherapyLogMutation});
}
