import {
  ResourceButtonType,
  NewLinkType,
} from '@/components/ResourceLink/ResourceButton';

export const linkTypes: Record<NewLinkType, string> = {
  resourceTypePdf: 'bg-gold-500',
  resourceTypeBrandPage: 'bg-blue-700',
  resourceTypeNotProvidedByBrand: 'bg-neutral-tertiary',
  resourceTypeImage: 'bg-error',
  resourceTypeMedicationGuide: 'bg-gold-500',
  resourceTypePrescribingInformation: 'bg-blue-700',
  resourceTypeSampleRequestForm: 'bg-theme-primary',
  resourceTypeCopayCard: 'bg-theme-primary',
  resourceTypeLetterOfMedicalNeccesity: 'bg-theme-primary',
  resourceTypeApp: 'bg-theme-primary',
  resourceTypeVideo: 'bg-secondary-sky',
  resourceTypeOther: 'bg-theme-primary',
  resourceTypemedicalNecessityLetter: 'bg-theme-primary',
  resourceTypeOnlyForm: 'bg-theme-primary',
  Review: 'bg-theme-primary',
  'Journal Article': 'bg-theme-primary',
  'Classical Article': 'bg-theme-primary',
  'English Abstract': 'bg-theme-primary',
  'Meta-Analysis': 'bg-theme-primary',
};

export const actionIconKeys: Record<
  ResourceButtonType,
  {
    name: string;
    tooltip: string;
  }
> = {
  download: {
    name: 'ArrowDownload',
    tooltip: 'Download',
  },
  video: {
    name: 'Video',
    tooltip: 'Play',
  },
  share: {
    name: 'ChatMailRegular',
    tooltip: 'Share',
  },
  image: {
    name: 'OpenRegular',
    tooltip: 'View',
  },
  redirect: {
    name: 'OpenRegular',
    tooltip: 'Open',
  },
  qr: {
    name: 'OpenRegular',
    tooltip: 'Open',
  },
  recent: {
    name: 'ArrowDownload',
    tooltip: 'Download',
  },
  unknown: {
    name: 'OpenRegular',
    tooltip: 'Open',
  },
  copy: {
    name: 'Copy',
    tooltip: 'Copy',
  },
};
