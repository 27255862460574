import {QueryClient, useQuery} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {IDrugIndication} from '@/models/coverage';
import {read} from '@/services/api';

export async function getDrugIndications(setId: string) {
  let data: IDrugIndication[] = [];

  try {
    data = await read<IDrugIndication[]>(
      'indications',
      {
        parentName: 'coverage',
        resourceId: setId,
      },
      {},
      'nextApi',
    );
  } catch (error) {
    console.error(error);
  }

  // when response is 204 it returns an empty object, causing the app to crash
  return data && Object.keys(data).length === 0 ? [] : data;
}

export function useDrugIndications(setId: string, params = {}) {
  return useQuery({
    queryKey: ['drug-indications', setId],
    queryFn: () => getDrugIndications(setId),
    initialData: [],
    ...params,
  });
}

export async function fetchDrugIndications(
  setId: string,
  queryClient: QueryClient,
) {
  return await queryClient.fetchQuery({
    queryKey: ['drug-indications', setId],
    queryFn: () => getDrugIndications(setId),
  });
}

export function getDrugIndicationsPrefetchQuery(
  setId: string,
): IPrefetchedQuery {
  return {
    queryKey: ['drug-indications', setId],
    getFn: async () => getDrugIndications(setId),
  };
}
