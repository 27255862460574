import {useMutation} from '@tanstack/react-query';
import {create} from '@/services/api';

export interface ICreateCaseRequest {
  name: string;
  details: string;
  status: string;
  dueDate?: string;
  patientDOB: string;
  patientName: string;
}

interface ICreateCaseResponse extends ICreateCaseRequest {
  id?: string;
}
export interface ICreateCase extends ICreateCaseRequest {
  setId?: string;
  createdBy: string;
  assignedTo: string;
}

export async function createCase(
  params: ICreateCaseRequest,
): Promise<ICreateCaseResponse> {
  return await create(
    'Case',
    {
      params: params,
    },
    {},
    'graph',
    true,
  );
}

export function useCreateCase(options = {}) {
  return useMutation({mutationFn: createCase, ...options});
}
