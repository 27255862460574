import { IComboBoxOption } from '@prescriberpoint/ui';
import {useQuery} from '@tanstack/react-query';
import {read} from '@/services/api';

interface ICarrierResponse {
  id: string;
  name: string;
}

interface ICarrierRequest {
  total: number;
  start: number;
  next: number;
  take: number;
  sortField: string;
  results: ICarrierResponse[];
}

const fetchCarrierOptions = async (setId: string) => {
  let data: ICarrierRequest | null = null;

  try {
    data = await read<ICarrierRequest>(
      'HasDocuments',
      {
        parentName: 'Carrier',
        params: {
          setId
        }
      },
      {},
      'profile',
      false,
      1,
    );

    return data?.results.map((carrier) => ({
      id: carrier.id,
      value: carrier.name,
    })) as unknown as IComboBoxOption[];
  } catch (error) {
    console.error(error);
  }
  return data as unknown as IComboBoxOption[];
};

export function useCarrierOptions(setId: string, options = {}) {
  return useQuery({
    queryKey: ['carrierOptions', setId],
    queryFn: () => fetchCarrierOptions(setId),
    ...options,
  });
}