import {Modal, Text} from '@prescriberpoint/ui';
import {FC} from 'react';

export interface ConfirmDialogProps {
  isOpen: boolean;
  title?: string | React.ReactNode;
  description?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  linkURL: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  isOpen,
  title,
  description,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  linkURL,
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const onHandleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }

    window.open(linkURL, '_blank');
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={onCancel}
      title={title}
      className="max-w-[98%] w-[400px]"
      showCloseButton={false}
      confirmText={confirmButtonText}
      cancelText={cancelButtonText}
      onCancel={onCancel}
      onConfirm={onHandleConfirm}>
      <div className="px-4 pb-4 pt-6">
        <Text>{description}</Text>
      </div>
    </Modal>
  );
};

export default ConfirmDialog;
