/**
 * Check if the given element is visible.
 * @returns {boolean} True is the element is visible.
 */
export const isElementVisible = (
  // eslint-disable-next-line no-undef
  elementRect: DOMRect,
  offset: number,
  percentVisibleThreshold?: number,
): boolean => {
  const percentVisibleThresholdVal = percentVisibleThreshold ?? 2;
  const visible = elementRect.bottom - Math.max(offset, elementRect.top);
  const percentVisible = (visible / elementRect.height) * 100;
  return percentVisible > percentVisibleThresholdVal;
};
